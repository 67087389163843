import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faChartLine,
  faHouse,
  faSave,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { useState, useEffect } from "react";
import {
  getEditTaskLevelMapping,
  getProjectLevelData,
  getTaskDrpDwn,
  saveTaskLevelMapping,
} from "../../../services/Endpoints";
import { getData, getDataById, postData } from "../../../services/API";
import { useAuth } from "../../../providers/AuthContext";
import { TaskLevelTree } from "./TaskLevelTree";
import successImg from "../../../assets/success.gif";
import FailImg from "../../../assets/Delete.gif";
import Select from "react-select";

export interface IEditTaskLevelMappingProps {}

interface ITaskLevelData {
  taskLevelId: number;
  projectId: number;
  taskId: number;
  projectLevelId: number;
  quantity: number;
  skilledhours: number;
  unskilledhours: number;
  remark: string;
  userId: number;
}

interface IFormErrorData {
  taskId: string;
  projectLevelId: string;
  quantity: string;
  skilledhours: string;
  unskilledhours: string;
  remark: string;
}

export function EditTaskLevelMapping() {
  // Declarations //
  const { Id } = useParams();
  const reRoute = useNavigate();
  const { userData, projectData } = useAuth();

  const [taskDrpDwnData, setTaskDrpDwnData] = useState<any[]>([]);
  const [projectLevelData, setProjectLevelData] = useState<any[]>([]);
  // const [selectedTask, setselectedTask] = useState(0);
  // const [taskLevelData, setTaskLevelData] = useState<any[]>([]);
  const [selectedPrjLvlId, setSelectedPrjLvlId] = useState(0);
  const [defaultPrjLvlId, setDefaultPrjLvlId] = useState(0);

  const [formData, setFormData] = useState<ITaskLevelData>({
    taskLevelId: 0,
    projectId: projectData.projectId,
    taskId: 0,
    projectLevelId: 0,
    quantity: 0,
    skilledhours: 0,
    unskilledhours: 0,
    remark: "",
    userId: userData.userId,
  });

  const [formError, setFormError] = useState<IFormErrorData>({
    taskId: "",
    projectLevelId: "",
    quantity: "",
    skilledhours: "",
    unskilledhours: "",
    remark: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [alertSuccessMessage, setAlertSuccessMessage] = useState("");
  const [alertFailMessage, setAlertFailMessage] = useState("");

  useEffect(() => {
    const fetchTaskDrpDwn = async () => {
      // Call getData API
      try {
        const responseData = await getData(
          getTaskDrpDwn + `ProjectId=${projectData.projectId}`
        );
        if (responseData.success) {
          setTaskDrpDwnData(responseData.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchTaskLevelMapData = async () => {
      // Call getDataById API
      try {
        const responseData = await getDataById(getEditTaskLevelMapping, Id);
        if (responseData.success) {
          var dat = responseData.data;
          setDefaultPrjLvlId(dat.taskLevel.projectLevelId);
          setSelectedPrjLvlId(dat.taskLevel.projectLevelId);
          setFormData({
            taskLevelId: dat.taskLevel.taskLevelId,
            projectId: dat.taskLevel.projectId,
            taskId: dat.taskLevel.taskId,
            projectLevelId: dat.taskLevel.projectLevelId,
            quantity: dat.taskLevel.quantity,
            skilledhours: dat.taskLevel.skilledhours,
            unskilledhours: dat.taskLevel.unskilledhours,
            remark: dat.taskLevel.remark,
            userId: userData.userId,
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchProjectLevelList = async (
      projectId: number,
      projectLevelId: number
    ) => {
      // Call getData API
      try {
        const responseData = await getData(
          getProjectLevelData +
            `ProjectId=${projectId}&ProjectLevelId=${projectLevelId}`
        );
        if (responseData.data.nestedProjectLevels.length > 0) {
          setProjectLevelData(responseData.data.nestedProjectLevels);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchTaskDrpDwn();
    fetchTaskLevelMapData();
    fetchProjectLevelList(projectData.projectId, defaultPrjLvlId);
  }, [defaultPrjLvlId, Id, projectData.projectId, userData.userId]);

  const fetchProjectLevelList = async (
    projectId: number,
    projectLevelId: number
  ) => {
    // Call getData API
    try {
      const responseData = await getData(
        getProjectLevelData +
          `ProjectId=${projectId}&ProjectLevelId=${projectLevelId}`
      );
      if (responseData.data.nestedProjectLevels.length > 0) {
        setProjectLevelData(responseData.data.nestedProjectLevels);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const handleTaskChange = (event: any) => {
  //     setselectedTask(Number(event.target.value));
  // }

  const handleProjectLevelId = (projLvlId: number) => {
    setSelectedPrjLvlId(projLvlId);
  };

  const onChangeHandler = (event: any) => {
    validatefields(event.target.name, event.target.value);
    setFormData(() => ({
      ...formData,
      [event.target.name]: event.target.value,
    }));
  };

  const validatefields = (fieldName: string, fieldValue: any) => {
    const testRegx = /^\s+/;
    const maxLimit = /^\d{0,10}$/;
    if (fieldName.includes("Code")) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      } else if (fieldValue.trim() === "" || testRegx.test(fieldValue)) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 and 20 characters, without empty spaces at the beginning.",
        });
        return true;
      } else if (fieldValue.length < 2 || fieldValue.length > 20) {
        setFormError({
          ...formError,
          [fieldName]: "Must be between 2 and 20 characters",
        });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("Name") || fieldName.includes("remark")) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      } else if (fieldValue.trim() === "" || testRegx.test(fieldValue)) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 and 50 characters, without empty spaces at the beginning.",
        });
        return true;
      } else if (fieldValue.length < 2 || fieldValue.length > 50) {
        setFormError({
          ...formError,
          [fieldName]: "Must be between 2 and 50 characters",
        });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("taskId")) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "Task is required." });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("quantity")) {
      if (fieldValue === 0) {
        setFormError({ ...formError, [fieldName]: "Quantity is required" });
        return false;
      } else if (fieldValue < 1) {
        if (fieldValue <= 0) {
          setFormError({
            ...formError,
            [fieldName]: "Value must be greater than 0.",
          });
          return true;
        } else {
          setFormError({ ...formError, [fieldName]: "" });
          return false;
        }
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("skilledhours")) {
      if (fieldValue === 0) {
        setFormError({
          ...formError,
          [fieldName]: "Value should be between 1-10 digit number.",
        });
        return true;
      } else if (!maxLimit.test(fieldValue)) {
        setFormError({
          ...formError,
          [fieldName]: "Value should be between 1-10 digit number.",
        });
        return true;
      } else if (fieldValue < 1) {
        if (fieldValue <= 0) {
          setFormError({
            ...formError,
            [fieldName]: "Value should be between 1-10 digit number.",
          });
          return true;
        } else {
          setFormError({ ...formError, [fieldName]: "" });
          return false;
        }
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("unskilledhours")) {
      if (fieldValue === 0) {
        setFormError({
          ...formError,
          [fieldName]: "Value should be between 1-10 digit number.",
        });
        return true;
      } else if (!maxLimit.test(fieldValue)) {
        setFormError({
          ...formError,
          [fieldName]: "Value should be between 1-10 digit number.",
        });
        return true;
      } else if (fieldValue > 0) {
        if (fieldValue < 0) {
          setFormError({
            ...formError,
            [fieldName]: "Value should be between 1-10 digit number.",
          });
          return true;
        } else {
          setFormError({ ...formError, [fieldName]: "" });
          return false;
        }
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    }
  };

  const validateForm = () => {
    let err: Partial<IFormErrorData> = {};

    if (formData.taskId === 0 || formData.taskId.toString().length === 0) {
      err.taskId = "Task is required.";
    }
    // if (validatefields("quantity", formData.quantity)) {
    //     err.quantity = "Value must be greater than 0.";
    // }
    if (formData.quantity === 0 || formData.quantity.toString().length === 0) {
      err.quantity = "Value must be greater than 0.";
    }
    // if (validatefields("skilledhours", formData.skilledhours)) {
    //     err.skilledhours = "Value must be greater than 0.";
    // }
    if (
      formData.skilledhours === 0 ||
      formData.skilledhours.toString().length === 0
    ) {
      err.skilledhours = "Value should be between 1-10 digit number.";
    } else if (validatefields("skilledhours", formData.skilledhours)) {
      err.skilledhours = "Value should be between 1-10 digit number.";
    }

    if (
      formData.unskilledhours === 0 ||
      formData.unskilledhours.toString().length === 0
    ) {
      err.unskilledhours = "Value should be between 1-10 digit number.";
    } else if (validatefields("unskilledhours", formData.unskilledhours)) {
      err.unskilledhours = "Value should be between 1-10 digit number.";
    }

    if (validatefields("remark", formData.remark)) {
      err.remark = "Must be 10 Digits numeric value.";
    }

    if (selectedPrjLvlId === 0) {
      err.projectLevelId = "Select Project Level.";
    }

    setFormError(err as IFormErrorData);
    return Object.keys(err).length < 1;
  };

  const onSubmitHandler = async (event: any) => {
    event.preventDefault();
    let isValidForm = validateForm();

    if (isValidForm) {
      //setting loader state
      setIsSubmitting(true);

      const pstData: ITaskLevelData = {
        taskLevelId: formData.taskLevelId,
        projectId: projectData.projectId,
        taskId: formData.taskId,
        projectLevelId: selectedPrjLvlId,
        quantity: formData.quantity,
        skilledhours: formData.skilledhours,
        unskilledhours: formData.unskilledhours,
        remark: formData.remark,
        userId: userData.userId,
      };

      // calling save api
      try {
        const responseData = await postData(saveTaskLevelMapping, pstData);
        if (responseData.success) {
          //to show the alert
          setShowSuccessAlert(true);
          setAlertSuccessMessage(responseData.message);
          onCancelHandler();
          setIsSubmitting(false);
        } else {
          setShowFailAlert(true);
          setAlertFailMessage(responseData.message);
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error("Error posting data:", error);
      }
    }
  };

  const onCancelHandler = () => {
    // setFormData({
    //     taskLevelId: 0,
    //     projectId: projectData.projectId,
    //     taskId: 0,
    //     projectLevelId: 0,
    //     quantity: 0,
    //     skilledhours: 0,
    //     unskilledhours: 0,
    //     remark: "",
    //     userId: userData.userId
    // });

    // setFormError({
    //     taskId: "",
    //     projectLevelId: "",
    //     quantity: "",
    //     skilledhours: "",
    //     unskilledhours: "",
    //     remark: ""
    // });

    // setSelectedPrjLvlId(0);
    // fetchProjectLevelList(projectData.projectId, 0);
    reRoute("/manageTaskLevelMapping");
  };

  const handleSuccessAlert = () => {
    setShowSuccessAlert(false);
    reRoute("/manageTaskLevelMapping");
  };

  const handleFailAlert = () => {
    setShowFailAlert(false);
  };

  const options = taskDrpDwnData.map((opt) => ({
    value: +opt.value,
    label: opt.text,
  }));

  const onChangeHandler1 = (selectedOption: any) => {
    //console.log(selectedOption)
    if (selectedOption) {
      validatefields("taskId", selectedOption.value);
      setFormData({
        ...formData,
        taskId: selectedOption.value,
      });
    } else {
      validatefields("taskId", ""); // or any other default value
      setFormData({
        ...formData,
        taskId: 0, // Set to an empty string or any other default value
      });
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h5>
                <Link
                  to="/manageTaskLevelMapping"
                  className="btn bg-light"
                  style={{ marginRight: "10px" }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </Link>
                Edit Task Level Mapping
              </h5>
            </div>
            <div className="text-center">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/adminDashboard">
                      <FontAwesomeIcon icon={faHouse} />
                      <span>PGDA</span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/manageTaskLevelMapping">
                      <FontAwesomeIcon icon={faChartLine} />
                      <span>Manage Task Level Mapping</span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Edit Task Level Mapping
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-body">
          <form className="g-3" onSubmit={onSubmitHandler}>
            <div className="row">
              <div className="col-md-6">
                <label className="form-label">
                  Task <span className="text-danger">*</span>
                </label>
                {/* <select name="taskId" className="form-select" onChange={onChangeHandler} value={formData.taskId}>
                                    <option value="">Select</option>
                                    {taskDrpDwnData.map((opt, indx) => (
                                        <option key={indx} value={opt.value}>{opt.text}</option>
                                    ))}
                                </select> */}
                <Select
                  name="taskId"
                  value={options.find(
                    (option) => option.value === formData.taskId
                  )}
                  onChange={onChangeHandler1}
                  options={options}
                  placeholder="Select Task"
                />
                <span className="text-danger">{formError.taskId}</span>
              </div>
            </div>
            <div className="row mt-3">
              <label className="form-label">Select Project Level</label>
            </div>
            <div
              style={{
                maxHeight: 210,
                maxWidth: 487,
                overflowX: "hidden",
                border: "1px solid hsl(0, 0%, 80%)",
                borderRadius: "4px",
                paddingLeft: "1vw",
              }}
            >
              <TaskLevelTree
                levels={projectLevelData}
                onFetchLevels={fetchProjectLevelList}
                onPrjLvlId={handleProjectLevelId}
              />
            </div>

            <div className="row mt-3">
              <div className="col-md-4">
                <label className="form-label">
                  Quantity <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="quantity"
                  min={0}
                  value={formData.quantity === 0 ? "" : formData.quantity}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.quantity}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">
                  Skilled Hours <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="skilledhours"
                  min={0}
                  value={
                    formData.skilledhours === 0 ? "" : formData.skilledhours
                  }
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.skilledhours}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">
                  UnSkilled Hours <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="unskilledhours"
                  min={0}
                  value={
                    formData.unskilledhours === 0 ? "" : formData.unskilledhours
                  }
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.unskilledhours}</span>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md">
                <label className="form-label">Remark(Optional)</label>
                <textarea
                  className="form-control"
                  name="remark"
                  value={formData.remark}
                  onChange={onChangeHandler}
                  rows={3}
                ></textarea>
                <span className="text-danger">{formError.remark}</span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <button
                type="button"
                className="btn btn-secondary text-white"
                onClick={onCancelHandler}
                disabled={isSubmitting}
                style={{ marginRight: 10 }}
              >
                <FontAwesomeIcon icon={faXmark} style={{ marginRight: 5 }} />
                <span>Cancel</span>
              </button>
              <button
                type="submit"
                className="btn custom-btn"
                hidden={isSubmitting}
              >
                <FontAwesomeIcon icon={faSave} style={{ marginRight: 5 }} />
                <span>Submit</span>
              </button>
              <button
                type="button"
                className="btn custom-btn submitting-btn"
                hidden={!isSubmitting}
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span>Submitting</span>
              </button>
            </div>
          </form>
        </div>
      </div>
      <div
        className={`modal fade ${showSuccessAlert ? "show" : ""} `}
        tabIndex={-1}
        id="alertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showSuccessAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={successImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center text-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {alertSuccessMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleSuccessAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showFailAlert ? "show" : ""} `}
        tabIndex={-1}
        id="alertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showFailAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={FailImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center text-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {alertFailMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleFailAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
