
export interface IAddProjectLevelProps {
}

export interface IProjectLevelData {
  projectLevelId: number,
  projectId: number,
  rootId: number,
  levelName: string,
  levelCode: string,
  userId: number,
  projectLevels?: any[],
  isSaved: boolean
}

// interface IFormErrorData {
//   projectId: string
// }

export function AddProjectLevel() {
  return(
    <div>
      
    </div>
  )
}
