
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faPlus, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { useState, useMemo, useEffect, useRef, } from 'react';
import { getAllCompany, actDeactCompany, deleteCompanyData, } from '../../../services/Endpoints';
import { getData, deleteData, acivateDeactivate, } from '../../../services/API';
import { StatusBtn } from '../../../components/common/StatusBtns';
import { DataGridActionBtns } from '../../../components/common/DataGridActionBtns';
import activateImg from "../../../assets/activate.gif";
import deleteImg from "../../../assets/Delete.gif";
import { useAuth } from '../../../providers/AuthContext';

export interface IManageCompanyProps {
}

interface ICompanyTabData {
    companyId: number,
    companyName: string,
    companyEmailId: string,
    companyMobileNo: string,
    companyLogo: string,
    activeFlag: number,
    deleteFlag: number,
    createdUser: number,
    createdTime: string,
    updatedUser: number,
    updatedTime: string
}

export function ManageCompany() {
    //Declarations
    const reRoute = useNavigate();
    const { userData, companyData } = useAuth();

    const [companyRowData, setCompanyRowData] = useState<ICompanyTabData[]>();
    const [selectedData, setSelectedData] = useState<any>({});

    const [showToggleConfirm, setShowToggleConfirm] = useState(false);
    const [showToggleAlert, setShowToggleAlert] = useState(false);
    const [toggleMessage, setToggleMessage] = useState("");

    const [showDeleteConfirm, setshowDeleteConfirm] = useState(false);
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState("");

    const gridRef = useRef<AgGridReact<ICompanyTabData>>(null);
    const [selectedIds, setSelectedIds] = useState('');
    const [enableDeleteAll, setEnableDeleteAll] = useState(false);
    const [showDeleteAllConfirm, setshowDeleteAllConfirm] = useState(false);
    const [showDeleteAllAlert, setShowDeleteAllAlert] = useState(false);
    const [deleteAllMessage, setDeleteAllMessage] = useState("");

    const handleAddBtn = () => {
        reRoute('/addCompany');
    }

    const onEditClickHandler = (row: any) => {
        var id = row.companyId;
        reRoute('/editCompany/' + id);
    };

    const onDeleteClickHandler = (row: any) => {
        setDeleteMessage("Are you sure to  Delete this record ?");
        setshowDeleteConfirm(true);
        setSelectedData({
            companyId: row.companyId,
            userId: 2
        });
    };

    const handleDeleteConfirm = () => {
        deleteCompany(selectedData.companyId, selectedData.userId);
        closeDeleteConfirm();
    }

    const handleDeleteAlert = () => {
        fetchAllCompanyData();
        closeDeleteAlert();
    }

    const closeDeleteConfirm = () => {
        setshowDeleteConfirm(false);
    }

    const closeDeleteAlert = () => {
        setShowDeleteAlert(false);
    }

    const handleDeleteCancel = () => {
        closeDeleteConfirm();
    }

    const onSwitchClickHandler = (row: any) => {
        setToggleMessage(`Are you sure to ${row.activeFlag === 0 ? 'Activate' : 'Deactivate'} this Company ?`)
        setShowToggleConfirm(true);
        setSelectedData({
            companyId: row.companyId,
            userId: 2
        });
    };

    const handleToggleConfirm = () => {
        activateDeactivateCompany(selectedData.companyId, selectedData.userId);
        closeToggleConfirm();
    }

    const handleToggleAlert = () => {
        fetchAllCompanyData();
        closeToggleAlert();
    }

    const closeToggleConfirm = () => {
        setShowToggleConfirm(false);
    }

    const closeToggleAlert = () => {
        setShowToggleAlert(false);
    }

    const handleToggleCancel = () => {
        closeToggleConfirm();
    }

    // Datagrid table data
    const defaultColDef = useMemo(() => {
        return {
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        };
    }, []);

    const [columnDefs] = useState<ColDef[]>([
        { headerName: "Sl.No", valueGetter: 'node.rowIndex + 1', filter:false, sortable:false, width: 100,headerCheckboxSelection: true, checkboxSelection: true, },
        { headerName: "Company Name", field: 'companyName', width: 170, tooltipField: "companyName", headerTooltip: "Company Name" },
        { headerName: "Email Id", field: 'companyEmailId', filter: true, width: 180, tooltipField: "companyEmailId", headerTooltip: "Email Id" },
        { headerName: "Mobile Number", field: 'companyMobileNo', filter: true, width: 150, tooltipField: "companyMobileNo", headerTooltip: "Mobile Number" },
        { headerName: "Created On", field: 'createdTime', filter: true, tooltipField: "createdTime", headerTooltip: "Created On" },
        { headerName: "Status", cellRenderer: StatusBtn, filter:false, sortable:false, width: 150 },
        {
            headerName: "Actions",
            cellRenderer: DataGridActionBtns,
            cellRendererParams: {
                onViewClick: onEditClickHandler,
                onDeleteClick: onDeleteClickHandler,
                onSwitchClick: onSwitchClickHandler
            },
            width: 150,
            filter: false
        }
    ]);

    useEffect(() => {
        const fetchAllCompanyData = async () => {
            // Call getData API
            try {
                const responseData = await getData(getAllCompany + `CompanyId=${companyData.companyId}&UserId=${userData.userId}`);
                if (responseData.success) {
                    setCompanyRowData(responseData.data);
                } else {
                    setCompanyRowData([]);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchAllCompanyData();
    }, [companyData.companyId, userData.userId]);

    const fetchAllCompanyData = async () => {
        // Call getData API
        try {
            const responseData = await getData(getAllCompany + `CompanyId=${2}&UserId=${2}`);
            if (responseData.success) {
                setCompanyRowData(responseData.data);
            } else {
                setCompanyRowData([]);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const onSelectionChanged = () => {
        const selectedRows: any = gridRef.current?.api.getSelectedRows();
        const selectedData = selectedRows.map((item: any) => item.companyId);
        if (selectedData.length > 1) {
            setEnableDeleteAll(true);
        } else {
            setEnableDeleteAll(false);
        }
        setSelectedIds(selectedData.join(','));
    };
    const handleDeleteAllbtn = () => {
        setDeleteAllMessage("Are you sure to delete all the records?");
        setshowDeleteAllConfirm(true);
    };
    const handleDeleteAllCancel = () => {
        // Deselect all selected rows
        gridRef.current?.api.deselectAll();
        setSelectedIds('');
    }
    const handleDeleteAllCancelled = () => {
        setshowDeleteAllConfirm(false);
    }
    const handleDeleteAllConfirm = () => {
        deleteCompany(selectedIds, userData.userId);
        setshowDeleteAllConfirm(false);
    }
    const handleDeleteAllAlert = () => {
        fetchAllCompanyData();
        setShowDeleteAllAlert(false);
    }

    const activateDeactivateCompany = async (companyId: any, UserId: any) => {
        // Call getDataById API
        try {
            const responseData = await acivateDeactivate(actDeactCompany + `CompanyId=${companyId}&UserId=${UserId}`);
            if (responseData.success) {
                setToggleMessage(responseData.message)
                setShowToggleAlert(true);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const deleteCompany = async (companyId: any, UserId: any) => {
        // Call getDataById API
        try {
            const responseData = await deleteData(deleteCompanyData + `CompanyId=${companyId}&UserId=${UserId}`);
            // if (responseData.success) {
            //     setDeleteMessage(responseData.message)
            //     setShowDeleteAlert(true);
            // }
            if (responseData.length > 0) {
                const combinedMessage: any = getCombinedMessage(responseData);
                setDeleteAllMessage(combinedMessage);
                setShowDeleteAllAlert(true);
            } else {
                if (responseData.success) {
                    setDeleteMessage(responseData.message);
                    setShowDeleteAlert(true);
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const getCombinedMessage = (responseData: any[]) => {
        const msgDeleted = responseData
            .filter(item => item.success)
            .map(item => item.message);

        const msgNotDeleted = responseData
            .filter(item => !item.success)
            .map(item => item.message.replace(/\r\n/g, '\n')); // Replace \r\n with space

        return `
            ${msgDeleted.length > 0 ? `Deleted Records:\n${msgDeleted.join('\n')}\n\n` : ''}
            ${msgNotDeleted.length > 0 ? `Not Deleted Records:\n${msgNotDeleted.join('\n')}\n\n` : ''}
            `;
    };
    const gridOptions = {
        overlayNoRowsTemplate: '<span style="padding: 10px;">No Data Found</span>',
    };
   


    return (
        <>
            <div className="card">
                <div className="page-header">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <h5>Manage Company</h5>
                        </div>
                        <div className="text-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/adminDashboard">
                                            <FontAwesomeIcon icon={faHouse} />
                                            <span>PGDA</span>
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Manage Company</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mt-3">
                <div className="card-body">
                    <div className="d-flex justify-content-between">
                        <div>
                            <h6>Company List</h6>
                        </div>
                        <div>


{
                                enableDeleteAll ? (
                                    <>
                                        <button type="button" className="btn btn-secondary text-white" style={{ marginRight: 10 }} onClick={handleDeleteAllCancel}>
                                            <FontAwesomeIcon icon={faXmark} style={{ marginRight: 5 }} />
                                            <span>Cancel</span>
                                        </button>
                                        <button className="btn custom-btn" type="button" onClick={handleDeleteAllbtn}>
                                            <FontAwesomeIcon icon={faTrash} style={{ marginRight: 5 }} />
                                            <span>Delete All</span>
                                        </button>
                                    </>
                                ) : (
                                    <>
                                         <button className="btn custom-btn add-btn" type="button" onClick={handleAddBtn}>
                                <span>Add</span>
                                <FontAwesomeIcon icon={faPlus} />
                            </button>
                                        {/*    <button className="btn custom-btn" type="button">
                                <span>Bulk Upload</span>
                            </button> */}
                                    </>
                                )
                            }
                        </div>
                    </div>
                    <div className="mt-3">
                        <div className="ag-theme-quartz" style={{ height: 380 }}>
                            <AgGridReact rowData={companyRowData} columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                rowSelection="multiple"
                                suppressRowClickSelection={true}
                                pagination={true}
                                paginationPageSize={5}
                                paginationPageSizeSelector={[5, 10, 15, 20, 25]}
                                tooltipShowDelay={500}
                                gridOptions={gridOptions}
                                onSelectionChanged={onSelectionChanged} 
                                ref={gridRef} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade ${showToggleConfirm ? 'show' : ''} `} tabIndex={-1} id="toggleConfirmModal" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: showToggleConfirm ? 'block' : 'none' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="d-flex justify-content-center">
                                <img src={activateImg} alt="logo" className="" style={{ width: 150, height: 150 }} />
                            </div>
                            <div className="d-flex justify-content-center mt-2">
                                <p style={{ fontSize: 22, fontWeight: 700 }}>{toggleMessage}</p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button type="button" className="btn btn-secondary" onClick={handleToggleCancel} style={{ marginRight: 10, width: 183.5 }} data-bs-dismiss="modal">Cancel</button>
                                <button type="button" className="btn custom-btn" onClick={handleToggleConfirm} style={{ width: 183.5 }}>Okay</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade ${showToggleAlert ? 'show' : ''} `} tabIndex={-1} id="toggleAlertModal" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: showToggleAlert ? 'block' : 'none' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="d-flex justify-content-center">
                                <img src={activateImg} alt="logo" className="" style={{ width: 150, height: 150 }} />
                            </div>
                            <div className="d-flex justify-content-center mt-2">
                                <p style={{ fontSize: 22, fontWeight: 700, textAlign: 'center' }}>{toggleMessage}</p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button type="button" className="btn custom-btn" onClick={handleToggleAlert} style={{ width: 183.5 }}>Okay</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade ${showDeleteConfirm ? 'show' : ''} `} tabIndex={-1} id="deleteConfirmModal" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: showDeleteConfirm ? 'block' : 'none' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="d-flex justify-content-center">
                                <img src={deleteImg} alt="logo" className="" style={{ width: 150, height: 150 }} />
                            </div>
                            <div className="d-flex justify-content-center mt-2">
                                <p style={{ fontSize: 22, fontWeight: 700 }}>{deleteMessage}</p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button type="button" className="btn btn-secondary" onClick={handleDeleteCancel} style={{ marginRight: 10, width: 183.5 }} data-bs-dismiss="modal">Cancel</button>
                                <button type="button" className="btn custom-btn" onClick={handleDeleteConfirm} style={{ width: 183.5 }}>Okay</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade ${showDeleteAlert ? 'show' : ''} `} tabIndex={-1} id="deleteConfirmModal" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: showDeleteAlert ? 'block' : 'none' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="d-flex justify-content-center">
                                <img src={deleteImg} alt="logo" className="" style={{ width: 150, height: 150 }} />
                            </div>
                            <div className="d-flex justify-content-center mt-2">
                                <p style={{ fontSize: 22, fontWeight: 700 }}>{deleteMessage}</p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button type="button" className="btn custom-btn" onClick={handleDeleteAlert} style={{ width: 183.5 }}>Okay</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade ${showDeleteAllConfirm ? 'show' : ''} `} tabIndex={-1} id="deleteAllConfirmModal" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: showDeleteAllConfirm ? 'block' : 'none' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="d-flex justify-content-center">
                                <img src={deleteImg} alt="logo" className="" style={{ width: 150, height: 150 }} />
                            </div>
                            <div className="d-flex justify-content-center mt-2">
                                <p style={{ fontSize: 22, fontWeight: 700, textAlign: 'center' }}>{deleteAllMessage}</p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button type="button" className="btn btn-secondary" onClick={handleDeleteAllCancelled} style={{ marginRight: 10, width: 183.5 }} data-bs-dismiss="modal">Cancel</button>
                                <button type="button" className="btn custom-btn" onClick={handleDeleteAllConfirm} style={{ width: 183.5 }}>Okay</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade ${showDeleteAllAlert ? 'show' : ''} `} tabIndex={-1} id="deleteAllAlertModal" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: showDeleteAllAlert ? 'block' : 'none' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="d-flex justify-content-center">
                                <img src={deleteImg} alt="logo" className="" style={{ width: 150, height: 150 }} />
                            </div>
                            <div className="d-flex justify-content-center mt-2">
                                <p style={{ fontSize: 17, fontWeight: 700, textAlign: 'center', whiteSpace: 'pre-line' }}>{deleteAllMessage}</p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button type="button" className="btn custom-btn" onClick={handleDeleteAllAlert} style={{ width: 183.5 }}>Okay</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
