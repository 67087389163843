import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faFileExcel,
  faHouse,
  faPlus,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { useState, useMemo, useEffect, useRef } from "react";
import {
  getAllSubTask,
  actDeactSubTask,
  deleteSubTaskData,
} from "../../../services/Endpoints";
import { getData, deleteData, acivateDeactivate } from "../../../services/API";
import { StatusBtn } from "../../../components/common/StatusBtns";
import { DataGridActionBtns } from "../../../components/common/DataGridActionBtns";
import activateImg from "../../../assets/activate.gif";
import deleteImg from "../../../assets/Delete.gif";
import { useAuth } from "../../../providers/AuthContext";
import { ExportToExcel } from "../../../services/ExportToFile";

export interface IManageSubTaskProps {}

export interface ISubTaskTabData {
  subTaskId: number;
  projectId: number;
  subTaskCode: string;
  subTaskName: string;
  planedStartDate: string;
  planedFinishDate: string;
  budgetQty: number;
  costCode: string;
  taktCode: string;
  p6code: string;
  uomId: number;
  activityTypeId: number;
  activeFlag: number;
  deleteFlag: number;
  createdUser: number;
  createdTime: string;
  updatedUser: number;
  updatedTime: string;
  task: Task;
  activity: Activity;
  uom: Uom;
  project: Project;
  activityType: ActivityType;
}
export interface Task {
  taskId: number;
  projectId: number;
  activityId: number;
  taskCode: string;
  taskName: string;
  planedStartDate: string;
  planedFinishDate: string;
  budgetQty: number;
  uomId: number;
  costCode: string;
  taktCode: string;
  p6code: string;
  activeFlag: number;
  deleteFlag: number;
  createdUser: number;
  createdTime: string;
  updatedUser: number;
  updatedTime: string;
  activity: Activity;
  project: Project;
}
export interface Activity {
  activityId: number;
  projectId: number;
  activityCode: string;
  activityName: string;
  costCode: string;
  taktCode: string;
  p6code: string;
  activeFlag: number;
  deleteFlag: number;
  createdUser: number;
  createdTime: string;
  updatedUser: number;
  updatedTime: string;
}

export interface Uom {
  uomId: number;
  companyId: number;
  uomCode: string;
  uomName: string;
  deleteFlag: number;
  activeFlag: number;
  createdUser: number;
  createdTime: string;
  updatedUser: number;
  updatedTime: string;
  mysqlTime: string;
  company: any;
}

export interface Project {
  projectId: number;
  companyId: number;
  userId: number;
  projectCode: string;
  projectName: string;
  projectEmail: string;
  projectManager: number;
  projectEngineer: number;
  projectClient: string;
  projectContact: number;
  projectAddress: string;
  projectLocation: string;
  deleteFlag: number;
  activeFlag: number;
  createdUser: number;
  createdTime: string;
  updatedUser: number;
  updatedTime: string;
  company: any;
  projectEngineerDetail: any;
  projectManagerDetail: any;
  projectAssignMasters: any;
  selectedStaffs: any;
}

export interface ActivityType {
  activityTypeId: number;
  companyId: number;
  activityTypeCode: string;
  activityTypeName: string;
}

export function ManageSubTask(props: IManageSubTaskProps) {
  //Declarations
  const reRoute = useNavigate();
  const location = useLocation();

  const { userData, projectData } = useAuth();

  const [subTaskRowData, setSubTaskRowData] = useState<ISubTaskTabData[]>();
  const [selectedData, setSelectedData] = useState<any>({});

  const [showToggleConfirm, setShowToggleConfirm] = useState(false);
  const [showToggleAlert, setShowToggleAlert] = useState(false);
  const [toggleMessage, setToggleMessage] = useState("");

  const [showDeleteConfirm, setshowDeleteConfirm] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState("");

  const gridRef = useRef<AgGridReact<ISubTaskTabData>>(null);
  const [selectedIds, setSelectedIds] = useState("");
  const [enableDeleteAll, setEnableDeleteAll] = useState(false);
  const [showDeleteAllConfirm, setshowDeleteAllConfirm] = useState(false);
  const [showDeleteAllAlert, setShowDeleteAllAlert] = useState(false);
  const [deleteAllMessage, setDeleteAllMessage] = useState("");

  const handleAddBtn = () => {
    reRoute("/addSubTask");
  };

  const onEditClickHandler = (row: any) => {
    var id = row.subTaskId;
    reRoute("/editSubTask/" + id);
  };

  const onDeleteClickHandler = (row: any) => {
    setDeleteMessage("Are you sure to  Delete this record ?");
    setshowDeleteConfirm(true);
    setSelectedData({
      subTaskId: row.subTaskId,
      userId: userData.userId,
    });
  };

  const handleDeleteConfirm = () => {
    deleteSubTask(selectedData.subTaskId, selectedData.userId);
    closeDeleteConfirm();
  };

  const handleDeleteAlert = () => {
    fetchSubTaskData();
    closeDeleteAlert();
  };

  const closeDeleteConfirm = () => {
    setshowDeleteConfirm(false);
  };

  const closeDeleteAlert = () => {
    setShowDeleteAlert(false);
  };

  const handleDeleteCancel = () => {
    closeDeleteConfirm();
  };

  const onSwitchClickHandler = (row: any) => {
    setToggleMessage(
      `Are you sure to ${
        row.activeFlag === 0 ? "Activate" : "Deactivate"
      } this Sub Task ?`
    );
    setShowToggleConfirm(true);
    setSelectedData({
      subTaskId: row.subTaskId,
      userId: userData.userId,
    });
  };

  const handleToggleConfirm = () => {
    activateDeactivateSubTask(selectedData.subTaskId, selectedData.userId);
    closeToggleConfirm();
  };

  const handleToggleAlert = () => {
    fetchSubTaskData();
    closeToggleAlert();
  };

  const closeToggleConfirm = () => {
    setShowToggleConfirm(false);
  };

  const closeToggleAlert = () => {
    setShowToggleAlert(false);
  };

  const handleToggleCancel = () => {
    closeToggleConfirm();
  };

  // Datagrid table data
  const defaultColDef = useMemo(() => {
    return {
      filter: "agTextColumnFilter",
      floatingFilter: true,
    };
  }, []);

  const [columnDefs] = useState<ColDef[]>([
    {
      headerName: "Sl.No",
      valueGetter: "node.rowIndex + 1",
      filter: false,
      sortable: false,
      width: 100,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    {
      headerName: "Activity Name",
      field: "activity.activityName",
      filter: true,
      tooltipField: "activity.activityName",
      headerTooltip: "Activity Name",
    },
    {
      headerName: "Activity Type",
      field: "activityType.activityTypeName",
      filter: true,
      tooltipField: "activityType.activityTypeName",
      headerTooltip: "Activity Type",
    },
    {
      headerName: "Task Name",
      field: "task.taskName",
      filter: true,
      tooltipField: "task.taskName",
      headerTooltip: "Task Name",
    },
    {
      headerName: "Sub Task Code",
      field: "subTaskCode",
      filter: true,
      tooltipField: "subTaskCode",
      headerTooltip: "Sub Task Code",
    },
    {
      headerName: "Sub Task Name",
      field: "subTaskName",
      filter: true,
      tooltipField: "subTaskName",
      headerTooltip: "Sub Task Name",
    },
    {
      headerName: "Plan Start Date",
      field: "planedStartDate",
      filter: true,
      tooltipField: "planedStartDate",
      headerTooltip: "Plan Start Date",
    },
    {
      headerName: "Plan End Date",
      field: "planedFinishDate",
      filter: true,
      tooltipField: "planedFinishDate",
      headerTooltip: "Plan End Date",
    },
    {
      headerName: "Budget Qty.",
      field: "budgetQty",
      filter: true,
      tooltipField: "budgetQty",
      headerTooltip: "Budget Qty.",
    },
    {
      headerName: "UOM",
      field: "uom.uomName",
      filter: true,
      tooltipField: "uom.uomName",
      headerTooltip: "UOM",
    },
    {
      headerName: "Cost Code",
      field: "costCode",
      filter: true,
      tooltipField: "costCode",
      headerTooltip: "Cost Code",
    },
    {
      headerName: "Takt Code",
      field: "taktCode",
      filter: true,
      tooltipField: "taktCode",
      headerTooltip: "Takt Code",
    },
    {
      headerName: "P6 Code",
      field: "p6code",
      filter: true,
      tooltipField: "p6code",
      headerTooltip: "P6 Code",
    },
    {
      headerName: "Created On",
      field: "createdTime",
      filter: true,
      tooltipField: "createdTime",
      headerTooltip: "Created On",
    },
    {
      headerName: "Status",
      cellRenderer: StatusBtn,
      field: "activeFlag",
      filter: false,
      sortable: false,
      width: 150,
    },
    {
      headerName: "Actions",
      sortable: false,
      cellRenderer: DataGridActionBtns,
      cellRendererParams: {
        onViewClick: onEditClickHandler,
        onDeleteClick: onDeleteClickHandler,
        onSwitchClick: onSwitchClickHandler,
      },
      filter: false,
    },
  ]);

  useEffect(() => {
    const fetchSubTaskData = async () => {
      // Call getData API
      try {
        const responseData = await getData(
          getAllSubTask +
            `ProjectId=${projectData.projectId}&UserId=${userData.userId}`
        );
        if (responseData.success) {
          setSubTaskRowData(responseData.data);
        } else {
          setSubTaskRowData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchSubTaskData();
  }, [projectData.projectId, userData.userId]);

  const fetchSubTaskData = async () => {
    // Call getData API
    try {
      const responseData = await getData(
        getAllSubTask +
          `ProjectId=${projectData.projectId}&UserId=${userData.userId}`
      );
      if (responseData.success) {
        setSubTaskRowData(responseData.data);
      } else {
        setSubTaskRowData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onSelectionChanged = () => {
    const selectedRows: any = gridRef.current?.api.getSelectedRows();
    const selectedData = selectedRows.map((item: any) => item.subTaskId);
    if (selectedData.length > 1) {
      setEnableDeleteAll(true);
    } else {
      setEnableDeleteAll(false);
    }
    setSelectedIds(selectedData.join(","));
  };
  const handleDeleteAllbtn = () => {
    setDeleteAllMessage("Are you sure to delete all the records?");
    setshowDeleteAllConfirm(true);
  };
  const handleDeleteAllCancel = () => {
    // Deselect all selected rows
    gridRef.current?.api.deselectAll();
    setSelectedIds("");
  };
  const handleDeleteAllCancelled = () => {
    setshowDeleteAllConfirm(false);
  };
  const handleDeleteAllConfirm = () => {
    deleteSubTask(selectedIds, userData.userId);
    setshowDeleteAllConfirm(false);
  };
  const handleDeleteAllAlert = () => {
    fetchSubTaskData();
    setShowDeleteAllAlert(false);
  };

  const activateDeactivateSubTask = async (subTaskId: any, UserId: any) => {
    // Call getDataById API
    try {
      const responseData = await acivateDeactivate(
        actDeactSubTask + `SubTaskId=${subTaskId}&UserId=${UserId}`
      );
      if (responseData.success) {
        setToggleMessage(responseData.message);
        setShowToggleAlert(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const deleteSubTask = async (subTaskId: any, UserId: any) => {
    // Call delete API
    try {
      const responseData = await deleteData(
        deleteSubTaskData + `SubTaskId=${subTaskId}&UserId=${UserId}`
      );
      // if (responseData.success) {
      //     setDeleteMessage(responseData.message)
      //     setShowDeleteAlert(true);
      // }
      if (responseData.length > 0) {
        const combinedMessage: any = getCombinedMessage(responseData);
        setDeleteAllMessage(combinedMessage);
        setShowDeleteAllAlert(true);
      } else {
        if (responseData.success) {
          setDeleteMessage(responseData.message);
          setShowDeleteAlert(true);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getCombinedMessage = (responseData: any[]) => {
    const msgDeleted = responseData
      .filter((item) => item.success)
      .map((item) => item.message);

    const msgNotDeleted = responseData
      .filter((item) => !item.success)
      .map((item) => item.message.replace(/\r\n/g, "\n")); // Replace \r\n with space

    return `
            ${
              msgDeleted.length > 0
                ? `Deleted Records:\n${msgDeleted.join("\n")}\n\n`
                : ""
            }
            ${
              msgNotDeleted.length > 0
                ? `Not Deleted Records:\n${msgNotDeleted.join("\n")}\n\n`
                : ""
            }
            `;
  };
  const gridOptions = {
    overlayNoRowsTemplate: '<span style="padding: 10px;">No Data Found</span>',
  };

  const handleBulkUploadBtn = () => {
    reRoute("/subtaskBulkUpload");
  };

  const onExportToExcel = () => {
    const columns = JSON.stringify(
      columnDefs
        .filter((x) => x.headerName !== "Actions")
        .map((column) => {
          if (column.headerName === "Sl.No") {
            return {
              label: column.headerName,
              value: "slNo",
            };
          } else if (column.headerName === "Status") {
            return {
              label: column.headerName,
              value: "activeFlag",
            };
          } else if (column.headerName === "Activity Name") {
            return {
              label: column.headerName,
              value: "activityName",
            };
          } else if (column.headerName === "Activity Type") {
            return {
              label: column.headerName,
              value: "activityType",
            };
          } else if (column.headerName === "Task Name") {
            return {
              label: column.headerName,
              value: "taskName",
            };
          } else if (column.headerName === "UOM") {
            return {
              label: column.headerName,
              value: "uom",
            };
          } else {
            return {
              label: column.headerName,
              value: column.field,
            };
          }
        })
    );

    // Convert selectedIds from a comma-separated string to an array of numbers
    const selectedIdsArray = selectedIds
      ? selectedIds.split(",").map((id) => parseInt(id.trim(), 10))
      : [];

    const filteredData =
      selectedIdsArray.length > 0 && subTaskRowData !== undefined
        ? subTaskRowData.filter((row) =>
            selectedIdsArray.includes(row.subTaskId)
          )
        : subTaskRowData;

    let content = "{}";
    if (filteredData !== undefined) {
      let data = JSON.stringify(
        filteredData.map((row, index) => {
          return {
            slNo: index + 1,
            activityName: row.activity.activityName,
            activityType: row.activityType.activityTypeName,
            taskName: row.task.taskName,
            subTaskCode: row.subTaskCode,
            subTaskName: row.subTaskName,
            planedStartDate:
              row.planedStartDate !== null
                ? row.planedStartDate.split("T")[0]
                : "",
            planedFinishDate:
              row.planedFinishDate !== null
                ? row.planedFinishDate.split("T")[0]
                : "",
            budgetQty: row.budgetQty,
            uom: row.uom.uomName,
            costCode: row.costCode,
            taktCode: row.taktCode,
            p6code: row.p6code,
            activeFlag: row.activeFlag === 0 ? "Inactive" : "Active",
            createdTime: row.createdTime.split(" ")[0],
          };
        })
      );

      content = data;
    }

    ExportToExcel("Subtask List", columns, content);
  };

  return (
    <>
      <div className="card">
        <div className="page-header">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h5>
                <div className="d-flex gap-2 align-items-center">
                  {location.search.split("?")[1] === "adminDashboard" && (
                    <button
                      className="btn bg-light"
                      onClick={() => reRoute(-1)}
                    >
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </button>
                  )}
                  Manage Sub Task
                </div>
              </h5>
            </div>
            <div className="text-center">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/adminDashboard">
                      <FontAwesomeIcon icon={faHouse} />
                      <span>PGDA</span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Manage Sub Task
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <div>
              <h6>Sub Task List</h6>
            </div>
            <div className="d-flex justify-content-start gap-2">
              {enableDeleteAll ? (
                <>
                  <button
                    type="button"
                    className="btn btn-secondary text-white"
                    onClick={handleDeleteAllCancel}
                  >
                    <FontAwesomeIcon
                      icon={faXmark}
                      style={{ marginRight: 5 }}
                    />
                    <span>Cancel</span>
                  </button>
                  <button
                    className="btn custom-btn"
                    type="button"
                    onClick={handleDeleteAllbtn}
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      style={{ marginRight: 5 }}
                    />
                    <span>Delete All</span>
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="btn custom-btn add-btn"
                    type="button"
                    onClick={handleAddBtn}
                    style={{ marginRight: 0 }}
                  >
                    <FontAwesomeIcon icon={faPlus} style={{ marginRight: 5 }} />
                    <span>Add</span>
                  </button>
                  <button
                    className="btn custom-btn"
                    type="button"
                    onClick={handleBulkUploadBtn}
                  >
                    <span>Bulk Upload</span>
                  </button>
                </>
              )}
              <button
                type="button"
                className="btn custom-btn"
                onClick={onExportToExcel}
              >
                <FontAwesomeIcon
                  icon={faFileExcel}
                  style={{ marginRight: 5 }}
                />
                <span>Export Excel</span>
              </button>
            </div>
          </div>
          <div className="mt-3">
            {subTaskRowData && (
              <div className="ag-theme-quartz" style={{ height: 380 }}>
                <AgGridReact
                  rowData={subTaskRowData}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  // autoSizeStrategy={{ type: 'fitCellContents' }}
                  rowSelection="multiple"
                  suppressRowClickSelection={true}
                  pagination={true}
                  paginationPageSize={5}
                  paginationPageSizeSelector={[5, 10, 15, 20, 25]}
                  tooltipShowDelay={500}
                  gridOptions={gridOptions}
                  onSelectionChanged={onSelectionChanged}
                  ref={gridRef}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showToggleConfirm ? "show" : ""} `}
        tabIndex={-1}
        id="toggleConfirmModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showToggleConfirm ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={activateImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {toggleMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleToggleCancel}
                  style={{ marginRight: 10, width: 183.5 }}
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleToggleConfirm}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showToggleAlert ? "show" : ""} `}
        tabIndex={-1}
        id="toggleAlertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showToggleAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={activateImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center text-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {toggleMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleToggleAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showDeleteConfirm ? "show" : ""} `}
        tabIndex={-1}
        id="deleteConfirmModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showDeleteConfirm ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={deleteImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center text-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {deleteMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleDeleteCancel}
                  style={{ marginRight: 10, width: 183.5 }}
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleDeleteConfirm}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showDeleteAlert ? "show" : ""} `}
        tabIndex={-1}
        id="deleteConfirmModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showDeleteAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={deleteImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center text-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {deleteMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleDeleteAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showDeleteAllConfirm ? "show" : ""} `}
        tabIndex={-1}
        id="deleteAllConfirmModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showDeleteAllConfirm ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={deleteImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {deleteAllMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleDeleteAllCancelled}
                  style={{ marginRight: 10, width: 183.5 }}
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleDeleteAllConfirm}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showDeleteAllAlert ? "show" : ""} `}
        tabIndex={-1}
        id="deleteAllAlertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showDeleteAllAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={deleteImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center mt-2">
                <p
                  style={{
                    fontSize: 17,
                    fontWeight: 700,
                    textAlign: "center",
                    whiteSpace: "pre-line",
                  }}
                >
                  {deleteAllMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleDeleteAllAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
