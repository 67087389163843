
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faSave, faXmark, faUserTie, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { postData, getDataById } from '../../../services/API';
import { saveDesignations, getDesignationDataById } from '../../../services/Endpoints';
import { Link, useNavigate, useParams } from 'react-router-dom';
import successImg from '../../../assets/success.gif';
import FailImg from '../../../assets/Delete.gif';
import { useAuth } from '../../../providers/AuthContext';

export interface IEditDesignationsProps {
}

interface IDesignationData {
    designationId: number,
    companyId: number,
    designationCode: string,
    designationName: string,
    userId: number
}

interface IFormErrorData {
    designationCode: string,
    designationName: string,
}

export function EditDesignations() {
    // Declarations //
    const { Id } = useParams();
    const reRoute = useNavigate();
    const { userData, companyData } = useAuth();

    const [formData, setFormData] = useState<IDesignationData>({
        designationId: 0,
        companyId: companyData.companyId,
        designationCode: "",
        designationName: "",
        userId: userData.userId
    });

    const [formError, setFormError] = useState<IFormErrorData>({
        designationCode: "",
        designationName: "",
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showFailAlert, setShowFailAlert] = useState(false);
    const [alertSuccessMessage, setAlertSuccessMessage] = useState("");
    const [alertFailMessage, setAlertFailMessage] = useState("");

    // Event Handlers & Functions // 
    useEffect(() => {
        const fetchDesignationData = async () => {
            // Call getDataById API
            try {
                const responseData = await getDataById(getDesignationDataById, Id);
                if (responseData.success) {
                    var dat = responseData.data;
                    setFormData({
                        designationId: dat.designationId,
                        companyId: dat.companyId,
                        designationCode: dat.designationCode,
                        designationName: dat.designationName,
                        userId: userData.userId
                    });
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchDesignationData();
    }, [Id, userData.userId]);


    const onChangeHandler = (event: any) => {
        validatefields(event.target.name, event.target.value);
        setFormData(() => ({
            ...formData,
            [event.target.name]: event.target.value
        }));
    }

    const handleSuccessAlert = () => {
        setShowSuccessAlert(false);
        reRoute('/manageDesignations');
    }

    const handleFailAlert = () => {
        setShowFailAlert(false);
    }

    const validatefields = (fieldName: string, fieldValue: any) => {
        const testRegx = /^\s+/;
        // const textregex = /^[A-Za-z ]*$/;
        const textregex = /^[A-Za-z \-()/]*$/;
        const alphanumericRegex = /^[A-Za-z0-9 ]*$/;
        if (fieldName.includes('Code')) {
            if (fieldValue.length === 0) {
                setFormError({ ...formError, [fieldName]: '' });
                return false;
            } else if (fieldValue.trim() === "" || testRegx.test(fieldValue)) {
                setFormError({ ...formError, [fieldName]: 'Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.' });
                return true;
            } else if (fieldValue.length < 2 || fieldValue.length > 20) {
                setFormError({ ...formError, [fieldName]: 'Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.' });
                return true;
            } else if (!alphanumericRegex.test(fieldValue)) {
                setFormError({ ...formError, [fieldName]: 'Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.' });
                return true;
            } else {
                setFormError({ ...formError, [fieldName]: '' });
                return false;
            }
        } else if (fieldName.includes('Name')) {
            if (fieldValue.length === 0) {
                setFormError({ ...formError, [fieldName]: '' });
                return false;
            } else if (fieldValue.trim() === "" || testRegx.test(fieldValue)) {
                setFormError({ ...formError, [fieldName]: 'Must be between 2 to 50 characters of alphabets only, without spaces at the beginning.' });
                return true;
            } else if (!textregex.test(fieldValue)) {
                //console.log(textregex.test(fieldValue))
                setFormError({ ...formError, [fieldName]: 'Must be between 2 to 50 characters of alphabets only, without spaces at the beginning.' });
                return true;
            } else if (fieldValue.length < 2 || fieldValue.length > 50) {
                setFormError({ ...formError, [fieldName]: 'Must be between 2 to 50 characters of alphabets only, without spaces at the beginning.' });
                return true;
            } else {
                setFormError({ ...formError, [fieldName]: '' });
                return false;
            }
        }
    }

    const validateForm = () => {
        let err: Partial<IFormErrorData> = {};

        if (formData.designationCode.trim() === "") {
            err.designationCode = "Designation Code is required.";
        } else {
            if (validatefields("designationCode", formData.designationCode)) {
                err.designationCode = "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.";
            }
        }

        if (formData.designationName.trim() === "") {
            err.designationName = "Designation Name is required.";
        } else {
            if (validatefields("designationName", formData.designationName)) {
                err.designationName = "Must be between 2 to 50 characters of alphabets only, without spaces at the beginning.";
            }
        }

        setFormError(err as IFormErrorData);
        return Object.keys(err).length < 1;
    }


    const onSubmitHandler = async (event: any) => {
        event.preventDefault();
        let isValidForm = validateForm();

        if (isValidForm) {
            //setting loader state
            setIsSubmitting(true);

            //calling save api
            try {
                const responseData = await postData(saveDesignations, formData);
                if (responseData.success) {
                    //to show the alert
                    setShowSuccessAlert(true);
                    setAlertSuccessMessage(responseData.message);
                    onCancelHandler();
                    setIsSubmitting(false);
                } else {
                    setShowFailAlert(true);
                    setAlertFailMessage(responseData.message);
                    setIsSubmitting(false);
                }
            } catch (error) {
                console.error('Error posting data:', error);
                setIsSubmitting(false);
            }
        }
    }

    const onCancelHandler = () => {
        // setFormData({
        //     designationId: 0,
        //     companyId: companyData.companyId,
        //     designationCode: "",
        //     designationName: "",
        //     userId: userData.userId
        // });
        reRoute('/manageDesignations');
    }

    return (
        <>
            <div className="card">
                <div className="page-header">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <h5>
                                <Link to="/manageDesignations" className="btn bg-light" style={{ marginRight: '10px' }}>
                                    <FontAwesomeIcon icon={faArrowLeft} />
                                </Link>
                                Edit Designations
                            </h5>
                        </div>
                        <div className="text-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/adminDashboard">
                                            <FontAwesomeIcon icon={faHouse} />
                                            <span>PGDA</span>
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/manageDesignations">
                                            <FontAwesomeIcon icon={faUserTie} />
                                            <span>Manage Designations</span>
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Edit Designations</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mt-3">
                <div className="card-body">
                    <form className="g-3" onSubmit={onSubmitHandler}>
                        <div className="row">
                            <div className="col-md-6">
                                <label className="form-label">Designation Code</label>
                                <input type="text" className="form-control" name="designationCode" value={formData.designationCode} onChange={onChangeHandler} />
                                <span className="text-danger">{formError.designationCode}</span>
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Designation Name</label>
                                <input type="text" className="form-control" name="designationName" value={formData.designationName} onChange={onChangeHandler} />
                                <span className="text-danger">{formError.designationName}</span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mt-3">
                            {/* <button type="button" className="btn btn-outline-secondary" onClick={onCancelHandler} disabled={isSubmitting} style={{ marginRight: 10 }}>
                                <FontAwesomeIcon icon={faXmark} style={{ marginRight: 5 }} />
                                <span>Clear</span>
                            </button> */}
                            <button type="button" className="btn btn-secondary text-white" onClick={onCancelHandler} disabled={isSubmitting} style={{ marginRight: 10 }}>
                                <FontAwesomeIcon icon={faXmark} style={{ marginRight: 5 }} />
                                <span>Cancel</span>
                            </button>
                            <button type="submit" className="btn custom-btn" hidden={isSubmitting}>
                                <FontAwesomeIcon icon={faSave} style={{ marginRight: 5 }} />
                                <span>Submit</span>
                            </button>
                            <button type="button" className="btn custom-btn submitting-btn" hidden={!isSubmitting}>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span>Submitting</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div className={`modal fade ${showSuccessAlert ? 'show' : ''} `} tabIndex={-1} id="alertModal" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: showSuccessAlert ? 'block' : 'none' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="d-flex justify-content-center">
                                <img src={successImg} alt="logo" className="" style={{ width: 150, height: 150 }} />
                            </div>
                            <div className="d-flex justify-content-center mt-2">
                                <p style={{ fontSize: 22, fontWeight: 700, textAlign: 'center' }}>{alertSuccessMessage}</p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button type="button" className="btn custom-btn" onClick={handleSuccessAlert} style={{ width: 183.5 }}>Okay</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade ${showFailAlert ? 'show' : ''} `} tabIndex={-1} id="alertModal" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: showFailAlert ? 'block' : 'none' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="d-flex justify-content-center">
                                <img src={FailImg} alt="logo" className="" style={{ width: 150, height: 150 }} />
                            </div>
                            <div className="d-flex justify-content-center mt-2">
                                <p style={{ fontSize: 22, fontWeight: 700, textAlign: 'center' }}>{alertFailMessage}</p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button type="button" className="btn custom-btn" onClick={handleFailAlert} style={{ width: 183.5 }}>Okay</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
