import "../../styles/SideNavBar.css";
import Logo from "../../assets/GardiniaLogo.png";
import placeHolder from "../../assets/placeholder-img.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getFontAwesomeIcon } from "../../services/icons";
import { useAuth } from "../../providers/AuthContext";
import { Link } from "react-router-dom";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";

export interface ISideNavBarProps {}

export function SideNavBar() {
  const {
    menuData,
    activeSubMenu,
    currentMenuURL,
    setActiveSubMenu,
    setCurrentMenuURL,
    handleSubMenuToggle,
    handleSubMenuSelection,
    companyData,
    userType,
  } = useAuth();
  const [logoImgSRC, setLogoImgSRC] = useState("");
  const pathName = window.location.pathname.split("/")[1];

  const handleSubmenuIdUrl = () => {
    const subMenuId = menuData
      .flatMap((item: { subMenus: any }) => item.subMenus)
      .find(
        (subMenu: { menuUrl: any }) => subMenu.menuUrl === pathName
      )?.menuParentId;

    setActiveSubMenu(subMenuId === undefined ? null : subMenuId);
    setCurrentMenuURL(pathName);
  };

  useEffect(() => {
    if (userType.userTypeName === "Company Admin") {
      if (companyData.companyLogo === "" || companyData.companyLogo === null) {
        setLogoImgSRC(placeHolder);
      } else {
        setLogoImgSRC(companyData.companyLogo);
      }
    } else if (userType.userTypeName === "Project Admin") {
      if (companyData.companyLogo === "" || companyData.companyLogo === null) {
        setLogoImgSRC(placeHolder);
      } else {
        setLogoImgSRC(companyData.companyLogo);
      }
    } else if (userType.userTypeName === "Super Admin") {
      if (companyData.companyLogo === "" || companyData.companyLogo === null) {
        setLogoImgSRC(Logo);
      } else {
        setLogoImgSRC(companyData.companyLogo);
      }
    }
  }, [companyData.companyLogo, userType.userTypeName]);

  if (activeSubMenu === null) {
    handleSubmenuIdUrl();
  }

  return (
    <div className="h-100">
      <div className="sidebar-logo-area">
        <div className="sidebar-logo">
          <img src={logoImgSRC} alt="logo" className="logo-img" />
        </div>
      </div>
      <ul className="sidebar-nav">
        {menuData.map((mn: any) => (
          <li key={mn.menuId} className="sidebar-item">
            {mn.subMenus && mn.subMenus.length > 0 ? (
              <div>
                <Link
                  to=""
                  className={`sidebar-link`}
                  onClick={() => handleSubMenuToggle(mn)}
                >
                  <FontAwesomeIcon icon={getFontAwesomeIcon(mn.menuIconUrl)} />
                  <span>{mn.menuName}</span>
                  <FontAwesomeIcon
                    icon={
                      mn.menuId ===
                        mn.subMenus.find((x: any) => x.menuUrl === pathName)
                          ?.menuParentId || activeSubMenu === mn.menuId
                        ? faChevronUp
                        : faChevronDown
                    }
                    className="float-end"
                    size="sm"
                  />
                </Link>
                <ul
                  className={`sidebar-dropdown list-unstyled collapse ${
                    mn.menuId ===
                      mn.subMenus.find((x: any) => x.menuUrl === pathName)
                        ?.menuParentId || activeSubMenu === mn.menuId
                      ? "show"
                      : ""
                  }`}
                >
                  {mn.subMenus.map((smn: any) => (
                    <li
                      key={smn.menuId}
                      className="sidebar-item"
                      style={{ marginLeft: "22px" }}
                    >
                      <Link
                        to={smn.menuUrl}
                        className={`sidebar-link ${
                          pathName === smn.menuUrl ? "active" : ""
                        }`}
                        onClick={() => handleSubMenuSelection(smn)}
                      >
                        <FontAwesomeIcon
                          icon={getFontAwesomeIcon(smn.menuIconUrl)}
                        />
                        <span>{smn.menuName}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <Link
                to={mn.menuUrl}
                className={`sidebar-link ${
                  pathName === mn.menuUrl ? "active" : ""
                }`}
                onClick={() => handleSubMenuToggle(mn)}
              >
                <FontAwesomeIcon icon={getFontAwesomeIcon(mn.menuIconUrl)} />
                <span>{mn.menuName}</span>
              </Link>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}
