
import '../../styles/StatusBtn.css';
export interface IStatusBtnProps {
}

export function bulkUploadStatusBtn(props: any) {
    return (
        <div style={{ width: 20 }}>
            <label className="btn btn-sm btn-success mb-1" hidden={!props.data.success}>
                Uploaded
            </label>
            <label className="btn btn-sm btn-danger mb-1" hidden={props.data.success}>
                Failed
            </label>
        </div>
    );
}
