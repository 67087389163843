import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faGears, faSave, faXmark, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { saveMachineries } from '../../../services/Endpoints';
import { postData } from '../../../services/API';
import { Link, useNavigate } from 'react-router-dom';
import successImg from '../../../assets/success.gif';
import FailImg from '../../../assets/Delete.gif';
import { useAuth } from '../../../providers/AuthContext';
import Select from 'react-select';

export interface IAddMachineryProps {
}

interface IMachineryData {
    machineryId: number,
    companyId: number,
    machineryCode: string,
    machineryName: string,
    machineryQty: number,
    erpCode: string,
    rental: string,
    totalHours: number,
    userId: number
}

interface IFormErrorData {
    machineryCode: string,
    machineryName: string,
    rental: string,
    machineryQty: string,
    erpCode: string,
    totalHours: string
}

export function AddMachinery(props: IAddMachineryProps) {
    // Declarations //
    const reRoute = useNavigate();
    const { userData, companyData } = useAuth();

    const [formData, setFormData] = useState<IMachineryData>({
        machineryId: 0,
        companyId: companyData.companyId,
        machineryCode: "",
        machineryName: "",
        machineryQty: 0,
        erpCode: "",
        rental: "",
        totalHours: 0,
        userId: userData.userId
    });

    const [formError, setFormError] = useState<IFormErrorData>({
        machineryCode: "",
        machineryName: "",
        rental: "",
        machineryQty: "",
        erpCode: "",
        totalHours: ""
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showFailAlert, setShowFailAlert] = useState(false);
    const [alertSuccessMessage, setAlertSuccessMessage] = useState("");
    const [alertFailMessage, setAlertFailMessage] = useState("");

    // Event Handlers & Functions // 
    useEffect(() => {

    }, []);

    const onChangeHandler = (event: any) => {
        validatefields(event.target.name, event.target.value);
        setFormData(() => ({
            ...formData,
            [event.target.name]: event.target.value
        }));
    }

    const handleSuccessAlert = () => {
        setShowSuccessAlert(false);
        reRoute('/manageMachinery');
    }

    const handleFailAlert = () => {
        setShowFailAlert(false);
    }

    const validatefields = (fieldName: string, fieldValue: any) => {
        const testRegx = /^\s+/;
        const textregex = /^[A-Za-z ]*$/;
        const alphanumericRegex = /^[A-Za-z0-9 ]*$/;
        if (fieldName.includes('machineryCode')) {
            if (fieldValue.length === 0) {
                setFormError({ ...formError, [fieldName]: '' });
                return false;
            } else if (fieldValue.trim() === "" || testRegx.test(fieldValue)) {
                setFormError({ ...formError, [fieldName]: 'Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.' });
                return true;
            } else if (fieldValue.length < 2 || fieldValue.length > 20) {
                setFormError({ ...formError, [fieldName]: 'Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.' });
                return true;
            } else if (!alphanumericRegex.test(fieldValue)) {
                setFormError({ ...formError, [fieldName]: 'Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.' });
                return true;
            } else {
                setFormError({ ...formError, [fieldName]: '' });
                return false;
            }
        } else if (fieldName.includes('erpCode')) {
            if (fieldValue.length === 0) {
                setFormError({ ...formError, [fieldName]: '' });
                return false;
            } else if (fieldValue.trim() === "" || testRegx.test(fieldValue)) {
                setFormError({ ...formError, [fieldName]: 'Must be between 1 to 20 characters of alphanumeric only, without empty spaces at the beginning.' });
                return true;
            } else if (fieldValue.length < 1 || fieldValue.length > 20) {
                setFormError({ ...formError, [fieldName]: 'Must be between 1 to 20 characters of alphanumeric only, without empty spaces at the beginning.' });
                return true;
            } else if (!alphanumericRegex.test(fieldValue)) {
                setFormError({ ...formError, [fieldName]: 'Must be between 1 to 20 characters of alphanumeric only, without empty spaces at the beginning.' });
                return true;
            } else {
                setFormError({ ...formError, [fieldName]: '' });
                return false;
            }
        } else if (fieldName.includes('Name')) {
            if (fieldValue.length === 0) {
                setFormError({ ...formError, [fieldName]: '' });
                return false;
            } else if (fieldValue.trim() === "" || testRegx.test(fieldValue)) {
                setFormError({ ...formError, [fieldName]: 'Must be between 2 to 50 characters of alphabets only, without empty spaces at the beginning.' });
                return true;
            } else if (!textregex.test(fieldValue)) {
                setFormError({ ...formError, [fieldName]: 'Must be between 2 to 50 characters of alphabets only, without empty spaces at the beginning.' });
                return true;
            } else if (fieldValue.length < 2 || fieldValue.length > 50) {
                setFormError({ ...formError, [fieldName]: 'Must be between 2 to 50 characters of alphabets only, without empty spaces at the beginning.' });
                return true;
            } else {
                setFormError({ ...formError, [fieldName]: '' });
                return false;
            }
        } else if (fieldName.includes('Hours')) {
            if (fieldValue === 0) {
                setFormError({ ...formError, [fieldName]: '' });
                return false;
            } else if (fieldValue > 0) {
                if (fieldValue < 1) {
                    setFormError({ ...formError, [fieldName]: 'Value must be greater than 1.' });
                    return true;
                }
                else {
                    setFormError({ ...formError, [fieldName]: '' });
                    return false;
                }
            } else {
                setFormError({ ...formError, [fieldName]: '' });
                return false;
            }
        } else if (fieldName.includes('rental')) {
            if (fieldValue.length === 0) {
                setFormError({ ...formError, [fieldName]: 'In house/Rental is required.' });
                return true;
            } else {
                setFormError({ ...formError, [fieldName]: '' });
                return false;
            }
        } else if (fieldName.includes('machineryQty')) {
            if (fieldValue === 0) {
                setFormError({ ...formError, [fieldName]: 'Machinery Qty is required.' });
                return false;
            } else if (fieldValue > 0) {
                if (fieldValue < 1) {
                    setFormError({ ...formError, [fieldName]: 'Value must be greater than 1.' });
                    return true;
                }
                else {
                    setFormError({ ...formError, [fieldName]: '' });
                    return false;
                }
            } else {
                setFormError({ ...formError, [fieldName]: '' });
                return false;
            }
        }
    }

    const validateForm = () => {
        let err: Partial<IFormErrorData> = {};

        if (formData.machineryCode.trim() === "") {
            err.machineryCode = "Machinery Code is required.";
        } else {
            if (validatefields("machineryCode", formData.machineryCode)) {
                err.machineryCode = "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.";
            }
        }

        if (formData.machineryName.trim() === "") {
            err.machineryName = "Machinery Name is required.";
        } else {
            if (validatefields("machineryName", formData.machineryName)) {
                err.machineryName = "Must be between 2 to 50 characters of alphabets only, without empty spaces at the beginning.";
            }
        }

        if (formData.rental === "") {
            err.rental = "In house/Rental is required.";
        }

        if (formData.machineryQty === 0) {
            err.machineryQty = "Machinery Qty is required.";
        } else {
            if (validatefields("machineryQty", formData.machineryQty)) {
                err.machineryQty = "Value must be greater than 1.";
            }
        }

        // if (validatefields("erpCode", formData.erpCode)) {
        //     err.erpCode = "Must be between 1 and 20 characters, without empty spaces at the beginning.";
        // }

        if (validatefields("totalHours", formData.totalHours)) {
            err.totalHours = "Value must be greater than 1";
        }

        setFormError(err as IFormErrorData);
        return Object.keys(err).length < 1;
    }

    const onSubmitHandler = async (event: any) => {
        event.preventDefault();
        let isValidForm = validateForm();
        //console.log(formData.machineryCode)

        if (isValidForm) {
            //setting loader state
            setIsSubmitting(true);

            // calling save api
            try {
                const responseData = await postData(saveMachineries, formData);
                if (responseData.success) {
                    //to show the alert
                    setShowSuccessAlert(true);
                    setAlertSuccessMessage(responseData.message);
                    onCancelHandler();
                    setIsSubmitting(false);
                } else {
                    setShowFailAlert(true);
                    setAlertFailMessage(responseData.message);
                    setIsSubmitting(false);
                }
            } catch (error) {
                console.error('Error posting data:', error);
                setIsSubmitting(false);
            }
        }
    }

    const onCancelHandler = () => {
        setFormData({
            machineryId: 0,
            companyId: companyData.companyId,
            machineryCode: "",
            machineryName: "",
            machineryQty: 0,
            erpCode: "",
            rental: "",
            totalHours: 0,
            userId: userData.userId
        });

        setFormError({
            machineryCode: "",
            machineryName: "",
            rental: "",
            machineryQty: "",
            erpCode: "",
            totalHours: ""
        });
    }


    const machinary = [
        {
            label: "In house",
            value: "In house",
        },
        {
            label: "Rental",
            value: "Rental",
        }
    ]
    const onChangeHandler1 = (selectedOption: any) => {
        //console.log(selectedOption)
        if (selectedOption) {
            validatefields('rental', selectedOption.value);
            setFormData({
                ...formData,
                rental: selectedOption.value
            });
        } else {
            validatefields('rental', ''); // or any other default value
            setFormData({
                ...formData,
                rental: "" // Set to an empty string or any other default value
            });
        }
    }


    return (
        <>
            <div className="card">
                <div className="page-header">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <h5>
                                <Link to="/manageMachinery" className="btn bg-light" style={{ marginRight: '10px' }}>
                                    <FontAwesomeIcon icon={faArrowLeft} />
                                </Link>
                                Add Machinery
                            </h5>
                        </div>
                        <div className="text-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/adminDashboard">
                                            <FontAwesomeIcon icon={faHouse} />
                                            <span>PGDA</span>
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/manageMachinery">
                                            <FontAwesomeIcon icon={faGears} />
                                            <span>Manage Machinery</span>
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Add Machinery</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mt-3">
                <div className="card-body">
                    <form className="g-3" onSubmit={onSubmitHandler}>
                        <div className="row">
                            <div className="col-md-4">
                                <label className="form-label">Machinery Code <span className="text-danger">*</span></label>
                                <input type="text" className="form-control" name="machineryCode" value={formData.machineryCode} onChange={onChangeHandler} />
                                <span className="text-danger">{formError.machineryCode}</span>
                            </div>
                            <div className="col-md-4">
                                <label className="form-label">Machinery Name <span className="text-danger">*</span></label>
                                <input type="text" className="form-control" name="machineryName" value={formData.machineryName} onChange={onChangeHandler} />
                                <span className="text-danger">{formError.machineryName}</span>
                            </div>
                            <div className="col-md-4">
                                <label className="form-label">In house/Rental <span className="text-danger">*</span></label>
                                {/* <select name="rental" className="form-select" onChange={onChangeHandler} value={formData.rental}>
                                    <option value="">Select</option>
                                    <option value="In house">In house</option>
                                    <option value="Rental">Rental</option>
                                </select> */}
                                <Select
                                    name="rental"
                                    value={machinary.find(option => option.value === formData.rental)}
                                    onChange={onChangeHandler1}
                                    options={machinary}
                                    placeholder="Select In house/Rental"
                                />
                                <span className="text-danger">{formError.rental}</span>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-4">
                                <label className="form-label">Machinery Qty. <span className="text-danger">*</span></label>
                                {/* <select name="machineryQty" className="form-select" onChange={onChangeHandler} value={formData.machineryQty}>
                                    <option value="">Select</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                </select> */}
                                <input type="number" className="form-control" name="machineryQty" min={0} value={formData.machineryQty === 0 ? "" : formData.machineryQty} onChange={onChangeHandler} />
                                <span className="text-danger">{formError.machineryQty}</span>
                            </div>
                            <div className="col-md-4">
                                <label className="form-label">ERP Code </label>
                                <input type="text" className="form-control" name="erpCode" onChange={onChangeHandler} value={formData.erpCode} />
                                <span className="text-danger">{formError.erpCode}</span>
                            </div>
                            <div className="col-md-4">
                                <label className="form-label">Total Hours </label>
                                <input type="number" className="form-control" name="totalHours" min={0} onChange={onChangeHandler} value={formData.totalHours === 0 ? "" : formData.totalHours} />
                                <span className="text-danger">{formError.totalHours}</span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mt-3">
                            <button type="button" className="btn btn-secondary text-white" onClick={onCancelHandler} disabled={isSubmitting} style={{ marginRight: 10 }}>
                                <FontAwesomeIcon icon={faXmark} style={{ marginRight: 5 }} />
                                <span>Clear</span>
                            </button>
                            <button type="submit" className="btn custom-btn" hidden={isSubmitting}>
                                <FontAwesomeIcon icon={faSave} style={{ marginRight: 5 }} />
                                <span>Submit</span>
                            </button>
                            <button type="button" className="btn custom-btn submitting-btn" hidden={!isSubmitting}>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span>Submitting</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div className={`modal fade ${showSuccessAlert ? 'show' : ''} `} tabIndex={-1} id="alertModal" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: showSuccessAlert ? 'block' : 'none' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="d-flex justify-content-center">
                                <img src={successImg} alt="logo" className="" style={{ width: 150, height: 150 }} />
                            </div>
                            <div className="d-flex justify-content-center mt-2">
                                <p style={{ fontSize: 22, fontWeight: 700, textAlign: 'center' }}>{alertSuccessMessage}</p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button type="button" className="btn custom-btn" onClick={handleSuccessAlert} style={{ width: 183.5 }}>Okay</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade ${showFailAlert ? 'show' : ''} `} tabIndex={-1} id="alertModal" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: showFailAlert ? 'block' : 'none' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="d-flex justify-content-center">
                                <img src={FailImg} alt="logo" className="" style={{ width: 150, height: 150 }} />
                            </div>
                            <div className="d-flex justify-content-center mt-2">
                                <p style={{ fontSize: 22, fontWeight: 700, textAlign: 'center' }}>{alertFailMessage}</p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button type="button" className="btn custom-btn" onClick={handleFailAlert} style={{ width: 183.5 }}>Okay</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
