// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status-btn{
    /* width: 80px;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 600;
    margin-right:5px; */
    margin: .25rem .125rem;
    padding: .25rem .5rem;
    font-size: .875rem;
    border-radius: .2rem;
}

.active-btn {
    background-color: #00A73E;
    color:#FFFFFF;
}

.inactive-btn {
    background-color: #F05252;
    color:#FFFFFF;
}`, "",{"version":3,"sources":["webpack://./src/styles/StatusBtn.css"],"names":[],"mappings":"AAAA;IACI;;;;uBAImB;IACnB,sBAAsB;IACtB,qBAAqB;IACrB,kBAAkB;IAClB,oBAAoB;AACxB;;AAEA;IACI,yBAAyB;IACzB,aAAa;AACjB;;AAEA;IACI,yBAAyB;IACzB,aAAa;AACjB","sourcesContent":[".status-btn{\r\n    /* width: 80px;\r\n    border-radius: 8px;\r\n    font-size: 15px;\r\n    font-weight: 600;\r\n    margin-right:5px; */\r\n    margin: .25rem .125rem;\r\n    padding: .25rem .5rem;\r\n    font-size: .875rem;\r\n    border-radius: .2rem;\r\n}\r\n\r\n.active-btn {\r\n    background-color: #00A73E;\r\n    color:#FFFFFF;\r\n}\r\n\r\n.inactive-btn {\r\n    background-color: #F05252;\r\n    color:#FFFFFF;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
