import '../../styles/Footer.css';
export interface IFooterProps {
}

export function Footer(props: IFooterProps) {
    return (
        <div className="footer text-center mt-2">
            {/* Copyright © Young Decade IT Software Solution */}
            © Designed & developed by <a href="https://dimension64.com">Dimension64</a>
        </div>
    );
}
