import { faCircleCheck, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo from '../../assets/logo.png';
import successImg from '../../assets/success.gif';
import FailImg from '../../assets/Delete.gif';
import { useAuth } from '../../providers/AuthContext';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Resetpassword } from '../../services/Endpoints';
import { postData } from '../../services/API';

export interface IResetPasswordProps {
}

interface IFormData {
    password: string
    confirmPassword: string
}

interface IFormErrorData {
    password: string
    confirmPassword: string,
    passwordMatch: string
}

export function ResetPassword() {
    const reRoute = useNavigate();
    const { OTPData } = useAuth();

    const [formData, setFormData] = useState<IFormData>({
        password: "",
        confirmPassword: ""
    });

    const [formError, setFormError] = useState<IFormErrorData>({
        password: "",
        confirmPassword: "",
        passwordMatch: ""
    });

    const onChangeHandler = (event: any) => {
        validatefields(event.target.name, event.target.value);
        setFormData(() => ({
            ...formData,
            [event.target.name]: event.target.value
        }));
    }

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showFailAlert, setShowFailAlert] = useState(false);
    const [alertSuccessMessage, setAlertSuccessMessage] = useState("");
    const [alertFailMessage, setAlertFailMessage] = useState("");

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const togglePasswordVisibility = (field: string) => {
        if (field === 'password') {
            setShowPassword(!showPassword);
        } else if (field === 'confirmPassword') {
            setShowConfirmPassword(!showConfirmPassword);
        }
    };

    useEffect(() => {
        if (Object.keys(OTPData).length === 0) {
            setAlertFailMessage("please enter registred email to get OTP.");
            setShowFailAlert(true);
        }
    }, [OTPData]);

    const validatefields = (fieldName: string, fieldValue: any) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,20}$/;

        if (fieldName.toLowerCase().includes('password')) {
            if (fieldValue.length === 0) {
                setFormError({ ...formError, [fieldName]: '' });
                return false;
            } else if (fieldValue.trim() === "") {
                setFormError({ ...formError, [fieldName]: 'Empty spaces are not allowed.' });
                return true;
            } else if (!regex.test(fieldValue)) {
                setFormError({ ...formError, [fieldName]: 'Password must be 8-20 characters long and include at least one lowercase letter, one uppercase letter, one numeric character, and one special character.' });
                return true;
            } else {
                setFormError({ ...formError, [fieldName]: '' });
                return false;
            }

            // else if (fieldValue.length < 8 || fieldValue.length > 20) {
            //     setFormError({ ...formError, [fieldName]: 'password should be at least 8-20 characters long.' });
            //     return true;
            // } else if (!/[a-z]/.test(fieldValue) || !/[A-Z]/.test(fieldValue)) {
            //     setFormError({ ...formError, [fieldName]: 'Password must contain both lowercase and uppercase characters.' });
            //     return true;
            // } else if (!/\d/.test(fieldValue)) {
            //     setFormError({ ...formError, [fieldName]: 'Password must contain at least one numeric character.' });
            //     return true;
            // } else if (!/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(fieldValue)) {
            //     setFormError({ ...formError, [fieldName]: 'Password must contain at least one special character.' });
            //     return true;
            // } else {
            //     setFormError({ ...formError, [fieldName]: '' });
            //     return false;
            // }
        }
    }

    const validateForm = () => {
        let err: Partial<IFormErrorData> = {};

        if (formData.password.trim() === "") {
            err.password = "New Password is required.";
        } else {
            if (validatefields("password", formData.password)) {
                err.password = "password should be at least 8-20 characters long.";
            }
        }

        if (formData.confirmPassword.trim() === "") {
            err.confirmPassword = "Confirm new password is required.";
        } else {
            if (validatefields("confirmPassword", formData.confirmPassword)) {
                err.confirmPassword = "password should be at least 8-20 characters long.";
            }
        }

        if (formData.password !== formData.confirmPassword) {
            err.passwordMatch = "Passwords do not match.";
        }

        setFormError(err as IFormErrorData);
        return Object.keys(err).length < 1;
    }


    const onSubmitHandler = async (event: any) => {
        event.preventDefault();
        let isValidForm = validateForm();

        if (isValidForm) {
            //setting loader state
            setIsSubmitting(true);

            const pstData: any = {
                userId: OTPData.userId,
                newPassword: formData.password
            }

            //calling save api
            try {
                const responseData = await postData(Resetpassword, pstData);
                if (responseData.success) {
                    if (responseData.data !== null) {
                        // setOTPData(responseData.data);
                    }
                    //to show the alert
                    setShowSuccessAlert(true);
                    setAlertSuccessMessage(responseData.message);
                    onCancelHandler();
                    setIsSubmitting(false);
                } else {
                    setShowFailAlert(true);
                    setAlertFailMessage(responseData.message);
                    setIsSubmitting(false);
                }
            } catch (error) {
                console.error('Error posting data:', error);
                setIsSubmitting(false);
            }
        }
    }

    const onCancelHandler = () => {
        setFormData({
            password: "",
            confirmPassword: ""
        });

        setFormError({
            password: "",
            confirmPassword: "",
            passwordMatch: ""
        });
    }


    const handleSuccessAlert = () => {
        setShowSuccessAlert(false);
        reRoute('/');
    }

    const handleFailAlert = () => {
        setShowFailAlert(false);
        reRoute('/forgotPasswordEmail');
    }

    return (
        <>
            <div className="container-fluid center-card">
                <div className="d-flex justify-content-center align-items-center">
                    <div className="row">
                        <div className="col-md">
                            <div className="card p-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="d-flex justify-content-center">
                                            <div className="logo-area">
                                                <img src={Logo} alt="logo" className="logo-img" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center textDark mt-3">
                                        <span>Reset Password</span>
                                    </div>
                                    <div className="d-flex justify-content-center textGray mt-3">
                                        <span style={{ width: 400, textAlign: 'center' }}>Enter your new password</span>
                                    </div>
                                    <form onSubmit={onSubmitHandler}>
                                        <div className="row mt-3">
                                            <div className="col-md">
                                                <label className="form-label"><b>Email Address</b></label>
                                                <span className="text-success float-end"><b>Verified</b> <FontAwesomeIcon icon={faCircleCheck} /></span>
                                                <input type="text" className="form-control" name="EmailId" value={OTPData.email} disabled={true} />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md">
                                                <label className="form-label"><b>Enter New Password</b> <span className="text-danger">*</span></label>
                                                <div className="input-group">
                                                    <input type={`${showPassword ? 'text' : 'password'}`} className="form-control" name="password" value={formData.password} onChange={onChangeHandler} />
                                                    <button
                                                        className=""
                                                        type="button"
                                                        onClick={() => togglePasswordVisibility('password')}
                                                        style={{ right: '1px', border: 'none', borderRadius: '0px' }}
                                                    >
                                                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                                    </button>
                                                </div>
                                                <span className="text-danger wrap-text" style={{ maxWidth: '400px' }}>{formError.password}</span>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md">
                                                <label className="form-label"><b>Confirm New Password</b> <span className="text-danger">*</span></label>
                                                <div className="input-group">
                                                    <input type={`${showConfirmPassword ? 'text' : 'password'}`} className="form-control" name="confirmPassword" value={formData.confirmPassword} onChange={onChangeHandler} />
                                                    <button
                                                        className=""
                                                        type="button"
                                                        onClick={() => togglePasswordVisibility('confirmPassword')}
                                                        style={{ right: '1px', border: 'none', borderRadius: '0px' }}
                                                    >
                                                        <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
                                                    </button>
                                                </div>
                                                <span className="text-danger wrap-text" style={{ maxWidth: '400px' }}>{formError.confirmPassword}</span>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center mt-3">
                                            <button type="button" className="btn btn-secondary" onClick={onCancelHandler} style={{ marginRight: 5 }}>
                                                Clear
                                            </button>
                                            <button type="submit" className="btn custom-btn" >
                                                {!isSubmitting ? (<span>Reset Password</span>) :
                                                    (
                                                        <>
                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                            <span>Resetting</span>
                                                        </>
                                                    )
                                                }
                                            </button>
                                        </div>
                                    </form>
                                    <div className="d-flex justify-content-center mt-3">
                                        <Link to="/">Login here</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade ${showSuccessAlert ? 'show' : ''} `} tabIndex={-1} id="alertModal" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: showSuccessAlert ? 'block' : 'none' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="d-flex justify-content-center">
                                <img src={successImg} alt="logo" className="" style={{ width: 150, height: 150 }} />
                            </div>
                            <div className="d-flex justify-content-center mt-2">
                                <p style={{ fontSize: 22, fontWeight: 700, textAlign: 'center' }}>{alertSuccessMessage}</p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button type="button" className="btn custom-btn" onClick={handleSuccessAlert} style={{ width: 183.5 }}>Okay</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade ${showFailAlert ? 'show' : ''} `} tabIndex={-1} id="alertModal" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: showFailAlert ? 'block' : 'none' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="d-flex justify-content-center">
                                <img src={FailImg} alt="logo" className="" style={{ width: 150, height: 150 }} />
                            </div>
                            <div className="d-flex justify-content-center mt-2">
                                <p style={{ fontSize: 22, fontWeight: 700, textAlign: 'center' }}>{alertFailMessage}</p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button type="button" className="btn custom-btn" onClick={handleFailAlert} style={{ width: 183.5 }}>Okay</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

