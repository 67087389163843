// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loginContainer{
    background-color: #FFFFFF;
    padding:35px 60px 35px 60px;
    height: 100vh;
}

.login-img{
    width:100%;
    height: 100%;
    border-radius: 5px;
}

.login-page{
    box-shadow: 0px 0px 4px 0px #00000040;
}

/* .login-page-logo-area{
    background-color: #6754E2;
    border-radius: 44px;
    padding:12px;
} */

.input-icon{
    display: flex;
}`, "",{"version":3,"sources":["webpack://./src/styles/LoginPage.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,2BAA2B;IAC3B,aAAa;AACjB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,qCAAqC;AACzC;;AAEA;;;;GAIG;;AAEH;IACI,aAAa;AACjB","sourcesContent":[".loginContainer{\r\n    background-color: #FFFFFF;\r\n    padding:35px 60px 35px 60px;\r\n    height: 100vh;\r\n}\r\n\r\n.login-img{\r\n    width:100%;\r\n    height: 100%;\r\n    border-radius: 5px;\r\n}\r\n\r\n.login-page{\r\n    box-shadow: 0px 0px 4px 0px #00000040;\r\n}\r\n\r\n/* .login-page-logo-area{\r\n    background-color: #6754E2;\r\n    border-radius: 44px;\r\n    padding:12px;\r\n} */\r\n\r\n.input-icon{\r\n    display: flex;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
