import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import "../../styles/SideNavBarToggleBtn.css";
import useImg from "../../assets/useImg.png";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../providers/AuthContext";

export interface ISideNavBarToggleBtnProps {
    toggleSidebar: () => void;
}

export function SideNavBarToggleBtn({ toggleSidebar }: ISideNavBarToggleBtnProps) {
    const { logout, userData, userType } = useAuth();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLUListElement>(null);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                // Click occurred outside the dropdown menu, so we close it
                setIsDropdownOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleLogout = () => {
        // Implement your logout logic here
        logout();
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <nav className="navbar px-3 navabr-expand">
            <div className="">
                <button className="btn bg-toggle-btn" id="sidebar-toggle" type="button" onClick={toggleSidebar}>
                    <FontAwesomeIcon icon={faBars} />
                </button>
            </div>
            <div className="user">
                <div className="user-info">
                    <span className="user-name">{userData.name} </span>
                    <small>{userType.userTypeName} </small>
                </div>
                <div className="user-profile">
                    <img src={useImg} alt="user-profile-pic" className="user-img" onClick={toggleDropdown} />
                    <ul ref={dropdownRef} className={`dropdown-menu drp-dwn ${isDropdownOpen ? 'show' : ''}`}>
                        <li className="drpdwn-item" onClick={handleLogout}>
                            <FontAwesomeIcon className="btn btn-outline" icon={faRightFromBracket} size="lg" style={{ color: '#637381' }} />
                            <span className="mt-1" style={{ fontSize: '16px', fontWeight: 700, color: '#637381' }}>Logout</span>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}
