import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faDownload, faGears, faHouse, faMagnifyingGlass, faSave, faUpload, faXmark } from '@fortawesome/free-solid-svg-icons';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { useState, useMemo, } from 'react';
import { machineryBulkUpload, } from '../../../services/Endpoints';
import { postData, } from '../../../services/API';
import { useAuth } from '../../../providers/AuthContext';
import { bulkUploadStatusBtn } from '../../../components/common/bulkUploadStatusBtn';
import successImg from '../../../assets/success.gif';
import FailImg from '../../../assets/Delete.gif';

export interface IMachineryBulkUploadProps {
}

interface IBulkUploadData {
    file: File | null,
    companyId: number,
    UserId: number
}

interface IFormErrorData {
    file: string
}

interface IMachineryRowData {
    machineryCode: string,
    machineryName: string,
    machineryQty: number,
    erpCode: string,
    rental: string,
    totalHours: number,
    companyName: string,
    success: boolean,
    message: string
}

export function MachineryBulkUpload() {
    // Declarations //
    // const reRoute = useNavigate();
    const { userData, companyData } = useAuth();

    const [machineryRowData, setMachineryRowData] = useState<IMachineryRowData[]>();

    const [selectedFile, setSelectedFile] = useState<File>();
    const [selectedFileName, setSelectedFileName] = useState("");

    const [formData, setFormData] = useState<IBulkUploadData>({
        file: null,
        companyId: companyData.companyId,
        UserId: userData.userId
    });

    const [formError, setFormError] = useState<IFormErrorData>({
        file: "",
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showFailAlert, setShowFailAlert] = useState(false);
    const [alertSuccessMessage, setAlertSuccessMessage] = useState("");
    const [alertFailMessage, setAlertFailMessage] = useState("");

    // Datagrid table data
    const defaultColDef = useMemo(() => {
        return {
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        };
    }, []);

    const [columnDefs] = useState<ColDef[]>([
        { headerName: "Sl.No", valueGetter: 'node.rowIndex + 1', width: 100 },
        { headerName: "Machinery Code", field: 'machineryCode', filter: true, width: 150, tooltipField: "machineryCode", headerTooltip: "Machinery Code" },
        { headerName: "Machinery Name", field: 'machineryName', filter: true, width: 150, tooltipField: "machineryName", headerTooltip: "Machinery Name" },
        { headerName: "ERP Code", field: 'erpCode', filter: true, width: 150, tooltipField: "erpCode", headerTooltip: "ERP Code" },
        { headerName: "Machinery Qty", field: 'machineryQty', filter: true, width: 150, tooltipField: "machineryQty", headerTooltip: "Machinery Qty" },
        { headerName: "In house/Rental", field: 'rental', filter: true, width: 150, tooltipField: "rental", headerTooltip: "In house/Rental" },
        { headerName: "Total Hours", field: 'totalHours', filter: true, width: 150, tooltipField: "totalHours", headerTooltip: "Total Hours" },
        { headerName: "Company Name", field: 'companyName', filter: true, width: 150, tooltipField: "companyName", headerTooltip: "Company Name" },
        { headerName: "Status", cellRenderer: bulkUploadStatusBtn, width: 150 },
        { headerName: "Remarks", field: 'message', width: 250, tooltipField: "message", headerTooltip: "Remarks" },
    ]);

    // const onChangeHandler = (event: any) => {
    //     setFormData(() => ({
    //         ...formData,
    //         [event.target.name]: event.target.value
    //     }));
    // }

    const handleSuccessAlert = () => {
        setShowSuccessAlert(false);
        // reRoute('/manageMachinery');
    }

    const handleFailAlert = () => {
        setShowFailAlert(false);
    }

    const handleFileButtonClick = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = '.xlsx';
        fileInput.onchange = (event) => {
            const file = (event.target as HTMLInputElement).files?.[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    setFormData(prevState => ({
                        ...prevState,
                        file: file,
                    }));
                    setSelectedFile(file);
                    setSelectedFileName(file.name);
                };
                reader.readAsDataURL(file);
            }
        };
        fileInput.click();
    };

    const validateForm = () => {
        let err: Partial<IFormErrorData> = {};

        if (formData.file === null) {
            err.file = "Please select a file.";
        }

        setFormError(err as IFormErrorData);
        return Object.keys(err).length < 1;
    }

    const onSubmitHandler = async (event: any) => {
        event.preventDefault();
        let isValidForm = validateForm();

        if (isValidForm && selectedFile) {
            //setting loader state
            setIsSubmitting(true);

            const fmData = new FormData();
            fmData.append('file', selectedFile);
            fmData.append('companyId', companyData.companyId);
            fmData.append('userId', userData.userId);

            // calling save api
            try {
                const responseData = await postData(machineryBulkUpload, fmData);
                if (responseData.success) {
                    if (responseData.data != null && responseData.data.length > 0) {
                        setMachineryRowData([]);
                        setMachineryRowData(responseData.data);
                    }
                    //to show the alert
                    setShowSuccessAlert(true);
                    setAlertSuccessMessage(responseData.message);
                    onCancelHandler();
                    setIsSubmitting(false);
                } else {
                    if (responseData.data != null && responseData.data.length > 0) {
                        setMachineryRowData([]);
                        setMachineryRowData(responseData.data);
                    }
                    setShowFailAlert(true);
                    setAlertFailMessage(responseData.message);
                    onCancelHandler();
                    setIsSubmitting(false);
                }
            } catch (error) {
                console.error('Error posting data:', error);
                setIsSubmitting(false);
            }
        }
    }

    const onCancelHandler = () => {
        setFormData({
            file: null,
            companyId: companyData.companyId,
            UserId: userData.userId
        });

        setFormError({
            file: "",
        });

        setSelectedFileName("");
    }

    const onDownloadHandler = () => {
        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = 'https://youngconstrunction.blob.core.windows.net/civilproject/sample_machinery_details.xlsx';
        link.download = 'sample_machinery_details.xlsx'; // Set the file name

        // Programmatically click the anchor element to trigger download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up the anchor element
    };

    return (
        <>
            <div className="card">
                <div className="page-header">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <h5>
                                <Link to="/manageMachinery" className="btn bg-light" style={{ marginRight: '10px' }}>
                                    <FontAwesomeIcon icon={faArrowLeft} />
                                </Link>
                                Machinery Bulk Upload
                            </h5>
                        </div>
                        <div className="text-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/adminDashboard">
                                            <FontAwesomeIcon icon={faHouse} />
                                            <span>PGDA</span>
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/manageMachinery">
                                            <FontAwesomeIcon icon={faGears} />
                                            <span>Manage Machinery</span>
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Machinery Bulk Upload</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mt-3">
                <div className="card-body">
                    <form className="g-3" onSubmit={onSubmitHandler}>
                        <div className="row">
                            <div className="col-md-4">
                                <label className="form-label">Demo Excel/Excel File</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <button type="button" className="btn custom-btn" onClick={onDownloadHandler} disabled={isSubmitting} style={{ marginRight: 10 }}>
                                    <FontAwesomeIcon icon={faDownload} style={{ marginRight: 5 }} />
                                    <span>Download</span>
                                </button>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-4">
                                <label className="form-label">Choose File <span className="text-danger">*</span></label>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-6">
                                <div className="upload-area">
                                    <FontAwesomeIcon icon={faUpload} size="2xl" />
                                    {/* <span className="upload-text mt-2">Click to upload or drag and drop</span> */}
                                    {/* <span className="upload-size-text mt-2">Max. File Size: 30MB</span> */}
                                    <button type="button" className="btn custom-btn mt-2" onClick={handleFileButtonClick}>
                                        <FontAwesomeIcon icon={faMagnifyingGlass} style={{ marginRight: 5 }} />
                                        Browse File
                                    </button>
                                </div>
                                <span className="text-danger">{formError.file}</span>
                            </div>
                            <div className="col-md-6">
                                {selectedFileName && <label className="form-label">Selected File : {selectedFileName}</label>}
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mt-3">
                            <button type="button" className="btn btn-secondary text-white" onClick={onCancelHandler} disabled={isSubmitting} style={{ marginRight: 10 }}>
                                <FontAwesomeIcon icon={faXmark} style={{ marginRight: 5 }} />
                                <span>Clear</span>
                            </button>
                            <button type="submit" className="btn custom-btn" hidden={isSubmitting}>
                                <FontAwesomeIcon icon={faSave} style={{ marginRight: 5 }} />
                                <span>Submit</span>
                            </button>
                            <button type="button" className="btn custom-btn submitting-btn" hidden={!isSubmitting}>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span>Submitting</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            {machineryRowData &&
                <div className="card mt-3">
                    <div className="card-body">
                        <div className="d-flex justify-content-between">
                            <div>
                                <h6>Machinery List</h6>
                            </div>
                        </div>
                        <div className="mt-3">
                            <div className="ag-theme-quartz" style={{ height: 380 }}>
                                <AgGridReact rowData={machineryRowData} columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    // autoSizeStrategy={{ type: 'fitCellContents' }}
                                    rowSelection="multiple"
                                    suppressRowClickSelection={true}
                                    pagination={true}
                                    paginationPageSize={5}
                                    paginationPageSizeSelector={[5, 10, 15, 20, 25]}
                                    tooltipShowDelay={500} />
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className={`modal fade ${showSuccessAlert ? 'show' : ''} `} tabIndex={-1} id="alertModal" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: showSuccessAlert ? 'block' : 'none' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="d-flex justify-content-center">
                                <img src={successImg} alt="logo" className="" style={{ width: 150, height: 150 }} />
                            </div>
                            <div className="d-flex justify-content-center mt-2">
                                <p style={{ fontSize: 22, fontWeight: 700, textAlign: 'center' }}>{alertSuccessMessage}</p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button type="button" className="btn custom-btn" onClick={handleSuccessAlert} style={{ width: 183.5 }}>Okay</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade ${showFailAlert ? 'show' : ''} `} tabIndex={-1} id="alertModal" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: showFailAlert ? 'block' : 'none' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="d-flex justify-content-center">
                                <img src={FailImg} alt="logo" className="" style={{ width: 150, height: 150 }} />
                            </div>
                            <div className="d-flex justify-content-center mt-2">
                                <p style={{ fontSize: 22, fontWeight: 700, textAlign: 'center' }}>{alertFailMessage}</p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button type="button" className="btn custom-btn" onClick={handleFailAlert} style={{ width: 183.5 }}>Okay</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
