import "../../styles/ForgotPassword.css";
import { useAuth } from "../../providers/AuthContext";
import Logo from "../../assets/GardiniaLogo.png";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import successImg from "../../assets/success.gif";
import FailImg from "../../assets/Delete.gif";
import { getData } from "../../services/API";
import {
  OTPForForgotPassword,
  VerifyOTPForForgotPassword,
} from "../../services/Endpoints";

export interface IForgotPasswordOtpVerifyProps {}

export function ForgotPasswordOtpVerify() {
  const reRoute = useNavigate();
  const { OTPData, setOTPData } = useAuth();
  const [otpArray, setOtpArray] = useState<any>([]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [alertSuccessMessage, setAlertSuccessMessage] = useState("");
  const [alertFailMessage, setAlertFailMessage] = useState("");

  useEffect(() => {
    if (Object.keys(OTPData).length !== 0) {
      setOtpArray(OTPData.otp.toString().split("").map(Number));
    } else if (otpArray.length === 0) {
      setAlertFailMessage("please enter registred email to get OTP.");
      setShowFailAlert(true);
    }
  }, [OTPData, otpArray.length]);

  const handleSuccessAlert = () => {
    setShowSuccessAlert(false);
    reRoute("/forgotPasswordVerifiedEmail");
  };

  const handleFailAlert = () => {
    setShowFailAlert(false);
    reRoute("/forgotPasswordEmail");
  };

  const handleResendOtp = async () => {
    //calling save api
    try {
      const responseData = await getData(
        OTPForForgotPassword + `EmailId=${OTPData.email}`
      );
      if (responseData.success) {
        if (responseData.data !== null) {
          setOTPData(responseData.data);
        }
        //to show the alert
        // setShowSuccessAlert(true);
        // setAlertSuccessMessage(responseData.message);
        setIsSubmitting(false);
      } else {
        setShowFailAlert(true);
        setAlertFailMessage(responseData.message);
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error("Error posting data:", error);
      setIsSubmitting(false);
    }
  };

  const handleVerifyOtp = async () => {
    setIsSubmitting(true);
    //calling save api
    try {
      const responseData = await getData(
        VerifyOTPForForgotPassword +
          `UserId=${OTPData.userId}&OTP=${OTPData.otp}`
      );
      if (responseData.success) {
        if (responseData.data !== null) {
          //    console.log(responseData.data)
        }
        //to show the alert
        setShowSuccessAlert(true);
        setAlertSuccessMessage(responseData.message);
        setIsSubmitting(false);
      } else {
        setShowFailAlert(true);
        setAlertFailMessage(responseData.message);
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error("Error posting data:", error);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {otpArray.length > 0 && (
        <>
          <div className="container-fluid center-card">
            <div className="d-flex justify-content-center align-items-center">
              <div className="row">
                <div className="col-md">
                  <div className="card p-3">
                    <div className="card-body">
                      <div className="row">
                        <div className="d-flex justify-content-center">
                          <img src={Logo} alt="logo" className="logo-img" />
                        </div>
                      </div>
                      <div className="d-flex justify-content-center textDark mt-3">
                        <span>Forgot Password</span>
                      </div>
                      <div className="d-flex justify-content-center textGray mt-3">
                        <span style={{ width: 400, textAlign: "center" }}>
                          An OTP is sent to the registered email address{" "}
                          {OTPData.email}. Please enter the six digit OTP and
                          continue to verify.
                        </span>
                      </div>
                      <div className="d-flex justify-content-center mt-3 otp-container">
                        <div className="row">
                          {otpArray.map((digit: number, index: number) => (
                            <div key={index} className="col">
                              <input
                                type="number"
                                value={digit}
                                disabled
                                maxLength={1}
                                className="form-control text-center"
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="text-danger mt-3">
                        <span style={{ marginRight: 10 }}>
                          Didn't receive an OTP?
                        </span>
                        <button
                          className="btn btn-secondary btn-sm"
                          onClick={handleResendOtp}
                        >
                          Resend OTP
                        </button>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md">
                          <button
                            type="button"
                            className="btn custom-btn"
                            onClick={handleVerifyOtp}
                            style={{ width: "100%" }}
                            disabled={otpArray.length > 0 ? false : true}
                          >
                            {!isSubmitting ? (
                              <span>Verify OTP</span>
                            ) : (
                              <>
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span>Verifying</span>
                              </>
                            )}
                          </button>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center mt-3">
                        <Link to="/">Login here</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div
        className={`modal fade ${showSuccessAlert ? "show" : ""} `}
        tabIndex={-1}
        id="alertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showSuccessAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={successImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {alertSuccessMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleSuccessAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showFailAlert ? "show" : ""} `}
        tabIndex={-1}
        id="alertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showFailAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={FailImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {alertFailMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleFailAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
