// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    /* background: #FFFFFF;
    display: flex;
    justify-content: center;
    padding: 20px;
    bottom: 0; */
    /* margin:0px 20px 0px 15px; */
    background: #FFFFFF;
    text-align: center;
    padding: 10px 0;
}`, "",{"version":3,"sources":["webpack://./src/styles/Footer.css"],"names":[],"mappings":"AAAA;IACI;;;;gBAIY;IACZ,8BAA8B;IAC9B,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":[".footer {\r\n    /* background: #FFFFFF;\r\n    display: flex;\r\n    justify-content: center;\r\n    padding: 20px;\r\n    bottom: 0; */\r\n    /* margin:0px 20px 0px 15px; */\r\n    background: #FFFFFF;\r\n    text-align: center;\r\n    padding: 10px 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
