import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faSave, faXmark, faUsers, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { getLabourTypeDrpDwn, saveSubContractor } from '../../../services/Endpoints';
import { getData, postData } from '../../../services/API';
import { Link, useNavigate } from 'react-router-dom';
import successImg from '../../../assets/success.gif';
import FailImg from '../../../assets/Delete.gif';
import { useAuth } from '../../../providers/AuthContext';
import Select from 'react-select';

export interface IAddSubContractorProps {
}

interface ISubContractorData {
  subContractorId: number,
  companyId: number,
  labourTypeId: number,
  subContractorName: string,
  userId: number
}

interface IFormErrorData {
  labourTypeId: string,
  subContractorName: string,
}

export function AddSubContractor() {

  // Declarations //
  const reRoute = useNavigate();
  const { userData, companyData } = useAuth();

  const [labourTypeDrpDwnData, setLabourTypeDrpDwnData] = useState<any[]>([]);

  const [formData, setFormData] = useState<ISubContractorData>({
    subContractorId: 0,
    companyId: companyData.companyId,
    labourTypeId: 0,
    subContractorName: "",
    userId: userData.userId
  });

  const [formError, setFormError] = useState<IFormErrorData>({
    labourTypeId: "",
    subContractorName: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [alertSuccessMessage, setAlertSuccessMessage] = useState("");
  const [alertFailMessage, setAlertFailMessage] = useState("");

  // Event Handlers & Functions // 
  useEffect(() => {
    const fetchLabourTypeDrpDwn = async () => {
      // Call getData API
      try {
        const responseData = await getData(getLabourTypeDrpDwn + `CompanyId=${companyData.companyId}`);
        if (responseData.success) {
          setLabourTypeDrpDwnData(responseData.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchLabourTypeDrpDwn();
  }, [companyData.companyId]);

  const onChangeHandler = (event: any) => {
    validatefields(event.target.name, event.target.value);
    setFormData(() => ({
      ...formData,
      [event.target.name]: event.target.value
    }));
  }

  const handleSuccessAlert = () => {
    setShowSuccessAlert(false);
    reRoute('/manageSubContractor');
  }

  const handleFailAlert = () => {
    setShowFailAlert(false);
  }

  const validatefields = (fieldName: string, fieldValue: any) => {
    const testRegx = /^\s+/;
    const textregex = /^[A-Za-z ]*$/;
    if (fieldName.includes('Code')) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: '' });
        return false;
      } else if (fieldValue.trim() === "" || testRegx.test(fieldValue)) {
        setFormError({ ...formError, [fieldName]: 'Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.' });
        return true;
      } else if (fieldValue.length < 2 || fieldValue.length > 20) {
        setFormError({ ...formError, [fieldName]: 'Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.' });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: '' });
        return false;
      }
    } else if (fieldName.includes('Name')) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: '' });
        return false;
      } else if (fieldValue.trim() === "" || testRegx.test(fieldValue)) {
        setFormError({ ...formError, [fieldName]: 'Must be between 2 to 50 characters of alphabets only, without spaces at the beginning.' });
        return true;
      } else if (!textregex.test(fieldValue)) {
        setFormError({ ...formError, [fieldName]: 'Must be between 2 to 50 characters of alphabets only, without spaces at the beginning.' });
        return true;
      } else if (fieldValue.length < 2 || fieldValue.length > 50) {
        setFormError({ ...formError, [fieldName]: 'Must be between 2 to 50 characters of alphabets only, without spaces at the beginning.' });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: '' });
        return false;
      }
    } else if (fieldName.includes('labourTypeId')) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: 'Labour Type is required.' });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: '' });
        return false;
      }
    }
  }

  const validateForm = () => {
    let err: Partial<IFormErrorData> = {};

    if (formData.labourTypeId === 0 || formData.labourTypeId.toString().length === 0) {
      err.labourTypeId = "Labour Type is required.";
    }

    if (formData.subContractorName.trim() === "") {
      err.subContractorName = "Sub Contractor Name is required.";
    } else {
      if (validatefields("subContractorName", formData.subContractorName)) {
        err.subContractorName = "Must be between 2 to 50 characters of alphabets only, without spaces at the beginning.";
      }
    }

    setFormError(err as IFormErrorData);
    return Object.keys(err).length < 1;
  }

  const onSubmitHandler = async (event: any) => {
    event.preventDefault();
    let isValidForm = validateForm();

    if (isValidForm) {
      //setting loader state
      setIsSubmitting(true);

      // calling save api
      try {
        const responseData = await postData(saveSubContractor, formData);
        if (responseData.success) {
          //to show the alert
          setShowSuccessAlert(true);
          setAlertSuccessMessage(responseData.message);
          onCancelHandler();
          setIsSubmitting(false);
        } else {
          setShowFailAlert(true);
          setAlertFailMessage(responseData.message);
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error('Error posting data:', error);
        setIsSubmitting(false);
      }
    }
  }

  const onCancelHandler = () => {
    setFormData({
      subContractorId: 0,
      companyId: companyData.companyId,
      labourTypeId: 0,
      subContractorName: "",
      userId: userData.userId
    });

    setFormError({
      labourTypeId: "",
      subContractorName: "",
    });
  }


  

 const options = labourTypeDrpDwnData.map((opt) => ({
    value: opt.value,
    label: opt.text,
  }));


  const onChangeHandler1 = (selectedOption: any) => {
    //console.log(selectedOption)
   if (selectedOption) {
    validatefields('labourTypeId', selectedOption.value);
    setFormData({
      ...formData,
      labourTypeId: selectedOption.value
    });
   } else {
    validatefields('labourTypeId', ''); // or any other default value
    setFormData({
      ...formData,
      labourTypeId:0 // Set to an empty string or any other default value
    });
   }
   
  }

  return (
    <>
      <div className="card">
        <div className="page-header">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h5>
                <Link to="/manageSubContractor" className="btn bg-light" style={{ marginRight: '10px' }}>
                  <FontAwesomeIcon icon={faArrowLeft} />
                </Link>
                Add Sub Contractor
              </h5>
            </div>
            <div className="text-center">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/adminDashboard">
                      <FontAwesomeIcon icon={faHouse} />
                      <span>PGDA</span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/manageSubContractor">
                      <FontAwesomeIcon icon={faUsers} />
                      <span>Manage Sub Contractor</span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Add Sub Contractor</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-body">
          <form className="g-3" onSubmit={onSubmitHandler}>
            <div className="row">
              <div className="col-md-4">
                <label className="form-label">Labour Type <span className="text-danger">*</span></label>
                {/* <select name="labourTypeId" className="form-select" value={formData.labourTypeId} onChange={onChangeHandler} >
                  <option value="">Select</option>
                  {labourTypeDrpDwnData.map((opt,indx) => (
                    <option key={indx} value={opt.value}>{opt.text}</option>
                  ))}
                </select> */}
                <Select
                  name="labourTypeId"
                  value={options.find(option => option.value === formData.labourTypeId)}
                  onChange={onChangeHandler1}
                  options={options}
                  placeholder="Select Labour Type"
                />
                <span className="text-danger">{formError.labourTypeId}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">Sub Contractor Name <span className="text-danger">*</span></label>
                <input type="text" className="form-control" name="subContractorName" value={formData.subContractorName} onChange={onChangeHandler} />
                <span className="text-danger">{formError.subContractorName}</span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <button type="button" className="btn btn-secondary text-white" onClick={onCancelHandler} disabled={isSubmitting} style={{ marginRight: 10 }}>
                <FontAwesomeIcon icon={faXmark} style={{ marginRight: 5 }} />
                <span>Clear</span>
              </button>
              <button type="submit" className="btn custom-btn" hidden={isSubmitting}>
                <FontAwesomeIcon icon={faSave} style={{ marginRight: 5 }} />
                <span>Submit</span>
              </button>
              <button type="button" className="btn custom-btn submitting-btn" hidden={!isSubmitting}>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span>Submitting</span>
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className={`modal fade ${showSuccessAlert ? 'show' : ''} `} tabIndex={-1} id="alertModal" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: showSuccessAlert ? 'block' : 'none' }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img src={successImg} alt="logo" className="" style={{ width: 150, height: 150 }} />
              </div>
              <div className="d-flex justify-content-center mt-2">
                <p style={{ fontSize: 22, fontWeight: 700, textAlign: 'center' }}>{alertSuccessMessage}</p>
              </div>
              <div className="d-flex justify-content-center">
                <button type="button" className="btn custom-btn" onClick={handleSuccessAlert} style={{ width: 183.5 }}>Okay</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`modal fade ${showFailAlert ? 'show' : ''} `} tabIndex={-1} id="alertModal" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: showFailAlert ? 'block' : 'none' }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img src={FailImg} alt="logo" className="" style={{ width: 150, height: 150 }} />
              </div>
              <div className="d-flex justify-content-center mt-2">
                <p style={{ fontSize: 22, fontWeight: 700, textAlign: 'center' }}>{alertFailMessage}</p>
              </div>
              <div className="d-flex justify-content-center">
                <button type="button" className="btn custom-btn" onClick={handleFailAlert} style={{ width: 183.5 }}>Okay</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
