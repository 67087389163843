
import { RoutesNames } from '../../routing/Routes';
import { Header } from './Header';
import { SideNavBar } from './SideNavBar';
import { Footer } from './Footer';
import { useState } from 'react';

export interface ILayoutProps {
}

export function Layout(props: ILayoutProps) {
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    const toggleSidebar = () => {
        setIsSidebarCollapsed(!isSidebarCollapsed);
    };

    return (
        <div className="wrapper">
            {/* side nav bar */}
            <aside id="sidebar" className={`sidebar ${isSidebarCollapsed ? 'collapse' : ''}`}>
                <SideNavBar />
            </aside>
            {/* main page content */}
            <div id="content">
                <Header isSidebarCollapsed={isSidebarCollapsed} toggleSidebar={toggleSidebar} />
                <div className="main mt-3">
                    <div className="container-fluid">
                        {/* Route names for menus */}
                        <RoutesNames />
                    </div>
                </div>
                <Footer />
            </div>
            {/* footer */}
        </div>
    );
}
