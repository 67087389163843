import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faPlus } from '@fortawesome/free-solid-svg-icons';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { useState, useEffect, } from 'react';
import { appendPrjLvlDetById, deleteProjectLevelData, getAllProjectLevel, getEditProjectLevel, getProjectDrpDown, } from '../../../services/Endpoints';
import { getData, } from '../../../services/API';
import { useAuth } from '../../../providers/AuthContext';
import ProjectLevelTree from './ProjectLevelTree';
import successImg from '../../../assets/success.gif';
import FailImg from '../../../assets/Delete.gif';

export interface IManageProjectLevelProps {
}

export interface IProjectLevelData {
  projectLevelId: number,
  projectId: number,
  rootId: number,
  levelName: string,
  levelCode: string,
  userId: number,
  projectLevels?: IProjectLevelData[] | null;
  saved?: boolean;
}

interface IFormErrorData {
  projectId: string
}

export function ManageProjectLevel() {
  // Declarations //
  // const reRoute = useNavigate();
  const { userData, companyData } = useAuth();

  const [projectDrpDwnData, setProjectDrpDwnData] = useState<any[]>([]);
  const [selectedProject, setselectedProject] = useState(0);
  const [projectLevelData, setProjectLevelData] = useState<any[]>([]);
  const [isProjectSelected, setIsProjectSelected] = useState<any>();

  // const [formData, setFormData] = useState<IProjectLevelData>({
  //   projectLevelId: 0,
  //   projectId: 0,
  //   rootId: 0,
  //   levelName: "",
  //   levelCode: "",
  //   userId: userData.userId,
  //   projectLevels: [],
  // });

  // const [formError, setFormError] = useState<IFormErrorData>({
  //   projectId: ""
  // });

  // const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [alertSuccessMessage] = useState("");
  const [alertFailMessage, setAlertFailMessage] = useState("");

  useEffect(() => {
    const fetchProjectDrpDwn = async () => {
      // Call getData API
      try {
        const responseData = await getData(getProjectDrpDown + `CompanyId=${companyData.companyId}`);
        if (responseData.success) {
          setProjectDrpDwnData(responseData.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchProjectDrpDwn();
  }, [companyData.companyId]);

  const fetchProjectLevelList = async (projectId: number) => {
    // Call getData API
    try {
      // setProjectLevelData([]);
      const responseData = await getData(getAllProjectLevel + `ProjectId=${projectId}&UserId=${userData.userId}`);
      if (responseData.success) {
        if (responseData.data.nestedProjectLevels.length > 0) {
          setProjectLevelData(responseData.data.nestedProjectLevels);
        }
      }else{
        setProjectLevelData([]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchProjectLevelById = async (projectlevelId: number) => {
    try {
      if (projectlevelId > 0) {
        // setProjectLevelData([]);
        const responseData: any = await getData(getEditProjectLevel + `ProjectLevelId=${projectlevelId}`);
        if (responseData.success) {
          if (responseData.data.nestedProjectLevels.length > 0) {
            setProjectLevelData(responseData.data.nestedProjectLevels);
            //console.log(projectLevelData);
          }
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const handleProjectChange = (event: any) => {
    if (event.target.value === "Select") {
      setIsProjectSelected(false);
      setProjectLevelData([]);
      setselectedProject(0);
    } else {
      setIsProjectSelected(true);
      setselectedProject(Number(event.target.value));
      fetchProjectLevelList(Number(event.target.value));
    }
  }

  const handleAddLevel = () => {
    //validating wether project is selected or not
    let err: Partial<IFormErrorData> = {};
    // let isProjectSelected = true;
    if (selectedProject === 0) {
      err.projectId = "Project Name is required.";
      // isProjectSelected = false;
      setIsProjectSelected(false);
    }

    //showing levels only if project is selected
    if (isProjectSelected) {
      setProjectLevelData((prevState) => [
        ...prevState,
        {
          projectLevelId: 0,
          projectId: selectedProject,
          rootId: 0,
          levelName: "",
          levelCode: "",
          userId: userData.userId,
          projectLevels: [],
        }
      ]);
    }
  }

  const handleAddSublevel = async (projectLevelId: number) => {
    try {
      if (projectLevelId > 0) {
        // setProjectLevelData([]);
        const responseData: any = await getData(appendPrjLvlDetById + `ProjectLevelId=${projectLevelId}`);
        if (responseData.success) {
          if (responseData.data.nestedProjectLevels.length > 0) {
            setProjectLevelData(responseData.data.nestedProjectLevels);
            //console.log(projectLevelData);
          }
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleRemoveLevel = async (projectLevelId: number) => {
    try {
      if (projectLevelId >= 0) {
        const responseData: any = await getData(deleteProjectLevelData + `ProjectLevelId=${projectLevelId}&UserId=${userData.userId}`);
        if (responseData.success) {
          // setProjectLevelData(responseData.data.nestedProjectLevels);
          //to show the alert
          setShowFailAlert(true);
          setAlertFailMessage(responseData.message);
          // setIsSubmitting(false);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSuccessAlert = () => {
    setShowSuccessAlert(false);
    // reRoute('/manageProjects');
  }

  const handleFailAlert = () => {
    setShowFailAlert(false);
    fetchProjectLevelList(selectedProject);
  }

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h5>Manage Project Level</h5>
            </div>
            <div className="text-center">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/adminDashboard">
                      <FontAwesomeIcon icon={faHouse} />
                      <span>PGDA</span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Manage Project Level</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-body">
          <form className="g-3">
            <div className="row">
              <div className="col-md-4">
                <label className="form-label">Project Name <span className="text-danger">*</span></label>
                <select name="projectId" className="form-select" value={selectedProject} onChange={(event) => handleProjectChange(event)} >
                  <option>Select</option>
                  {projectDrpDwnData.map((opt, indx) => (
                    <option key={indx} value={opt.value}>{opt.text}</option>
                  ))}
                </select>
                {isProjectSelected === false && <span className="text-danger">Please Select Project Name</span>}
              </div>
              <div className="col mt-4">
                <button type="button" className="btn custom-btn" name="add" onClick={handleAddLevel}>
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </div>
            </div>
            <ProjectLevelTree
              levels={projectLevelData}
              // onUpdateLevel={handleUpdateLevel}
              onAddSublevel={handleAddSublevel}
              onRemoveLevel={handleRemoveLevel}
              fetchLevelByPrjId={fetchProjectLevelList}
              onFetchLevel={fetchProjectLevelById} />
          </form >
        </div >
      </div >
      <div className={`modal fade ${showSuccessAlert ? 'show' : ''} `} tabIndex={-1} id="alertModal" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: showSuccessAlert ? 'block' : 'none' }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img src={successImg} alt="logo" className="" style={{ width: 150, height: 150 }} />
              </div>
              <div className="d-flex justify-content-center text-center mt-2">
                <p style={{ fontSize: 22, fontWeight: 700 }}>{alertSuccessMessage}</p>
              </div>
              <div className="d-flex justify-content-center">
                <button type="button" className="btn custom-btn" onClick={handleSuccessAlert} style={{ width: 183.5 }}>Okay</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`modal fade ${showFailAlert ? 'show' : ''} `} tabIndex={-1} id="alertModal" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: showFailAlert ? 'block' : 'none' }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img src={FailImg} alt="logo" className="" style={{ width: 150, height: 150 }} />
              </div>
              <div className="d-flex justify-content-center text-center mt-2">
                <p style={{ fontSize: 22, fontWeight: 700 }}>{alertFailMessage}</p>
              </div>
              <div className="d-flex justify-content-center">
                <button type="button" className="btn custom-btn" onClick={handleFailAlert} style={{ width: 183.5 }}>Okay</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
