import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAuth } from "../../../providers/AuthContext";
import { getDashboardDynamicCardList } from "../../../services/Endpoints";
import { getData } from "../../../services/API";
import { Link } from "react-router-dom";

export interface IAdminDashboardProps {}

interface IDashboardCardData {
  cardName: string;
  count: number;
  navigationLink: string;
}

export function AdminDashboard() {
  const { userData, companyData, userType } = useAuth();
  const [dashboardResponse, setDashboardResponse] = useState<any>([]);

  useEffect(() => {
    const fetchDashboardDynamicCardData = async () => {
      try {
        const responseData = await getData(
          getDashboardDynamicCardList +
            `UserType=${userType.userTypeId}&CompanyId=${companyData.companyId}&UserId=${userData.userId}`
        );
        if (responseData.success) {
          setDashboardResponse(responseData.data.dashboardCards); // Assuming dashboardCards is an array
        } else {
          setDashboardResponse([]); // Set an empty array or handle error scenario
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDashboardDynamicCardData();
  }, [
    companyData.companyId,
    userData.userId,
    userType.userTypeId,
    userType.userTypeCode,
  ]);

  return (
    <div className="container mt-2">
      <div className="row justify-content-start">
        {dashboardResponse.map((card: IDashboardCardData, index: number) => (
          <div className="col-md-4 mb-2" key={index}>
            <Link
              to={{
                pathname: "/" + card.navigationLink,
                search: "adminDashboard",
              }}
              className="text-decoration-none"
            >
              <div className="dashboard-card card border-2 shadow-sm">
                <div className="dashboard-card-body card-body text-center">
                  <p
                    className="card-text"
                    style={{ fontSize: 20, fontWeight: 500 }}
                  >
                    {card.count}
                  </p>
                  <h5 className="card-title mb-0" style={{ color: "#637381" }}>
                    {card.cardName}
                  </h5>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
