import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faFileExcel,
  faHouse,
  faPlus,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { useState, useMemo, useEffect, useRef } from "react";
import {
  getAllStaff,
  actDeactStaff,
  deleteStaffData,
} from "../../../services/Endpoints";
import { getData, deleteData, acivateDeactivate } from "../../../services/API";
import { StatusBtn } from "../../../components/common/StatusBtns";
import { DataGridActionBtns } from "../../../components/common/DataGridActionBtns";
import activateImg from "../../../assets/activate.gif";
import deleteImg from "../../../assets/Delete.gif";
import { useAuth } from "../../../providers/AuthContext";
import { ExportToExcel } from "../../../services/ExportToFile";

export interface IManageStaffProps {}

interface IStaffTabData {
  staffId: number;
  companyId: number;
  userId: number;
  staffCode: string;
  staffName: string;
  designationId: number;
  erpCade: number;
  deleteFlag: number;
  activeFlag: number;
  createdUser: number;
  createdTime: string;
  updatedUser: any;
  updatedTime: string;
  designation: Designation;
  company: Company;
  user: User;
}

interface Designation {
  designationId: number;
  companyId: number;
  designationCode: string;
  designationName: string;
  deleteFlag: number;
  activeFlag: number;
  createdUser: number;
  createdTime: string;
  updatedUser: number;
  updatedTime: string;
  company: Company;
}

interface Company {
  companyId: number;
  companyName: string;
  companyEmailId: string;
  companyMobileNo: string;
  companyLogo: any;
  activeFlag: number;
  deleteFlag: number;
  createdUser: number;
  createdTime: string;
  updatedUser: any;
  updatedTime: any;
}

interface User {
  userId: number;
  userTypeId: number;
  companyId: number;
  fName: any;
  lName: any;
  name: string;
  dob: any;
  age: number;
  gender: number;
  phoneCode: number;
  mobile: any;
  otp: any;
  otpVerify: number;
  email: string;
  password: string;
  image: string;
  address: any;
  cityId: number;
  countryId: number;
  zipcode: any;
  latitude: any;
  longitude: any;
  activeFlag: number;
  deleteFlag: number;
  createdUser: number;
  createdTime: string;
  updatedUser: any;
  updatedTime: any;
}

export function ManageStaff() {
  //Declarations
  const reRoute = useNavigate();
  const location = useLocation();

  const { userData, companyData } = useAuth();

  const [staffRowData, setStaffRowData] = useState<IStaffTabData[]>();
  const [selectedData, setSelectedData] = useState<any>({});

  const [showToggleConfirm, setShowToggleConfirm] = useState(false);
  const [showToggleAlert, setShowToggleAlert] = useState(false);
  const [toggleMessage, setToggleMessage] = useState("");

  const [showDeleteConfirm, setshowDeleteConfirm] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState("");

  const gridRef = useRef<AgGridReact<IStaffTabData>>(null);
  const [selectedIds, setSelectedIds] = useState("");
  const [enableDeleteAll, setEnableDeleteAll] = useState(false);
  const [showDeleteAllConfirm, setshowDeleteAllConfirm] = useState(false);
  const [showDeleteAllAlert, setShowDeleteAllAlert] = useState(false);
  const [deleteAllMessage, setDeleteAllMessage] = useState("");

  const handleAddBtn = () => {
    reRoute("/addStaff");
  };

  const handleBulkUploadBtn = () => {
    reRoute("/staffBulkUpload");
  };

  const onEditClickHandler = (row: any) => {
    var id = row.staffId;
    reRoute("/editStaff/" + id);
  };

  const onDeleteClickHandler = (row: any) => {
    setDeleteMessage("Are you sure to  Delete this record ?");
    setshowDeleteConfirm(true);
    setSelectedData({
      staffId: row.staffId,
      userId: userData.userId,
    });
  };

  const handleDeleteConfirm = () => {
    deleteStaff(selectedData.staffId, selectedData.userId);
    closeDeleteConfirm();
  };

  const handleDeleteAlert = () => {
    fetchStaffData();
    closeDeleteAlert();
  };

  const closeDeleteConfirm = () => {
    setshowDeleteConfirm(false);
  };

  const closeDeleteAlert = () => {
    setShowDeleteAlert(false);
  };

  const handleDeleteCancel = () => {
    closeDeleteConfirm();
  };

  const onSwitchClickHandler = (row: any) => {
    setToggleMessage(
      `Are you sure to ${
        row.activeFlag === 0 ? "Activate" : "Deactivate"
      } this Staff ?`
    );
    setShowToggleConfirm(true);
    setSelectedData({
      staffId: row.staffId,
      userId: userData.userId,
    });
  };

  const handleToggleConfirm = () => {
    activateDeactivateStaff(selectedData.staffId, selectedData.userId);
    closeToggleConfirm();
  };

  const handleToggleAlert = () => {
    fetchStaffData();
    closeToggleAlert();
  };

  const closeToggleConfirm = () => {
    setShowToggleConfirm(false);
  };

  const closeToggleAlert = () => {
    setShowToggleAlert(false);
  };

  const handleToggleCancel = () => {
    closeToggleConfirm();
  };

  // Datagrid table data
  const defaultColDef = useMemo(() => {
    return {
      filter: "agTextColumnFilter",
      floatingFilter: true,
    };
  }, []);

  const [columnDefs] = useState<ColDef[]>([
    {
      headerName: "Sl.No",
      valueGetter: "node.rowIndex + 1",
      filter: false,
      sortable: false,
      width: 100,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    {
      headerName: "Staff Code",
      field: "staffCode",
      filter: true,
      width: 170,
      tooltipField: "staffCode",
      headerTooltip: "Staff Code",
    },
    {
      headerName: "Staff Name",
      field: "staffName",
      filter: true,
      width: 170,
      tooltipField: "staffName",
      headerTooltip: "Staff Name",
    },
    {
      headerName: "Staff Designation",
      field: "designation.designationName",
      filter: true,
      width: 170,
      tooltipField: "designation.designationName",
      headerTooltip: "Staff Designation",
    },
    {
      headerName: "Email",
      field: "user.email",
      filter: true,
      tooltipField: "user.email",
      headerTooltip: "Email",
    },
    {
      headerName: "Created On",
      field: "createdTime",
      filter: true,
      tooltipField: "createdTime",
      headerTooltip: "Created On",
    },
    {
      headerName: "Status",
      cellRenderer: StatusBtn,
      field: "activeFlag",
      filter: false,
      sortable: false,
      width: 150,
    },
    {
      headerName: "Actions",
      sortable: false,
      cellRenderer: DataGridActionBtns,
      cellRendererParams: {
        onViewClick: onEditClickHandler,
        onDeleteClick: onDeleteClickHandler,
        onSwitchClick: onSwitchClickHandler,
      },
      width: 150,
      filter: false,
    },
  ]);

  useEffect(() => {
    const fetchStaffData = async () => {
      // Call getData API
      try {
        const responseData = await getData(
          getAllStaff +
            `CompanyId=${companyData.companyId}&UserId=${userData.userId}`
        );
        if (responseData.success) {
          setStaffRowData(responseData.data);
        } else {
          setStaffRowData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchStaffData();
  }, [companyData.companyId, userData.userId]);

  const fetchStaffData = async () => {
    // Call getData API
    try {
      const responseData = await getData(
        getAllStaff +
          `CompanyId=${companyData.companyId}&UserId=${userData.userId}`
      );
      if (responseData.success) {
        setStaffRowData(responseData.data);
      } else {
        setStaffRowData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onSelectionChanged = () => {
    const selectedRows: any = gridRef.current?.api.getSelectedRows();
    const selectedData = selectedRows.map((item: any) => item.staffId);
    if (selectedData.length > 1) {
      setEnableDeleteAll(true);
    } else {
      setEnableDeleteAll(false);
    }
    setSelectedIds(selectedData.join(","));
  };
  const handleDeleteAllbtn = () => {
    setDeleteAllMessage("Are you sure to delete all the records?");
    setshowDeleteAllConfirm(true);
  };
  const handleDeleteAllCancel = () => {
    // Deselect all selected rows
    gridRef.current?.api.deselectAll();
    setSelectedIds("");
  };
  const handleDeleteAllCancelled = () => {
    setshowDeleteAllConfirm(false);
  };
  const handleDeleteAllConfirm = () => {
    deleteStaff(selectedIds, userData.userId);
    setshowDeleteAllConfirm(false);
  };
  const handleDeleteAllAlert = () => {
    fetchStaffData();
    setShowDeleteAllAlert(false);
  };

  const activateDeactivateStaff = async (staffId: any, UserId: any) => {
    // Call getDataById API
    try {
      const responseData = await acivateDeactivate(
        actDeactStaff + `StaffId=${staffId}&UserId=${UserId}`
      );
      if (responseData.success) {
        setToggleMessage(responseData.message);
        setShowToggleAlert(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const deleteStaff = async (staffId: any, UserId: any) => {
    // Call delete API
    try {
      const responseData = await deleteData(
        deleteStaffData + `StaffId=${staffId}&UserId=${UserId}`
      );
      // if (responseData.success) {
      //   setDeleteMessage(responseData.message)
      //   setShowDeleteAlert(true);
      // }
      if (responseData.length > 0) {
        const combinedMessage: any = getCombinedMessage(responseData);
        setDeleteAllMessage(combinedMessage);
        setShowDeleteAllAlert(true);
      } else {
        if (responseData.success) {
          setDeleteMessage(responseData.message);
          setShowDeleteAlert(true);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getCombinedMessage = (responseData: any[]) => {
    const msgDeleted = responseData
      .filter((item) => item.success)
      .map((item) => item.message);

    const msgNotDeleted = responseData
      .filter((item) => !item.success)
      .map((item) => item.message.replace(/\r\n/g, "\n")); // Replace \r\n with space

    return `
        ${
          msgDeleted.length > 0
            ? `Deleted Records:\n${msgDeleted.join("\n")}\n\n`
            : ""
        }
        ${
          msgNotDeleted.length > 0
            ? `Not Deleted Records:\n${msgNotDeleted.join("\n")}\n\n`
            : ""
        }
        `;
  };

  const gridOptions = {
    overlayNoRowsTemplate: '<span style="padding: 10px;">No Data Found</span>',
  };

  const onExportToExcel = () => {
    const columns = JSON.stringify(
      columnDefs
        .filter((x) => x.headerName !== "Actions")
        .map((column) => {
          if (column.headerName === "Sl.No") {
            return {
              label: column.headerName,
              value: "slNo",
            };
          } else if (column.headerName === "Status") {
            return {
              label: column.headerName,
              value: "activeFlag",
            };
          } else if (column.headerName === "Email") {
            return {
              label: column.headerName,
              value: "email",
            };
          } else if (column.headerName === "Staff Designation") {
            return {
              label: column.headerName,
              value: "designation",
            };
          } else {
            return {
              label: column.headerName,
              value: column.field,
            };
          }
        })
    );

    // Convert selectedIds from a comma-separated string to an array of numbers
    const selectedIdsArray = selectedIds
      ? selectedIds.split(",").map((id) => parseInt(id.trim(), 10))
      : [];

    // Filter staffRowData based on selectedIdsArray if it has values
    const filteredStaffData =
      selectedIdsArray.length > 0 && staffRowData !== undefined
        ? staffRowData.filter((row) => selectedIdsArray.includes(row.staffId))
        : staffRowData;

    let content = "{}";
    if (filteredStaffData !== undefined) {
      let data = JSON.stringify(
        filteredStaffData.map((row, index) => {
          return {
            slNo: index + 1,
            staffCode: row.staffCode,
            staffName: row.staffName,
            designation: row.designation.designationName,
            email: row.user.email,
            activeFlag: row.activeFlag === 0 ? "Inactive" : "Active",
            createdTime: row.createdTime.split(" ")[0],
          };
        })
      );

      content = data;
    }

    ExportToExcel("Staff List", columns, content);
  };

  return (
    <>
      <div className="card">
        <div className="page-header">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h5>
                <div className="d-flex gap-2 align-items-center">
                  {location.search.split("?")[1] === "adminDashboard" && (
                    <button
                      className="btn bg-light"
                      onClick={() => reRoute(-1)}
                    >
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </button>
                  )}
                  Manage Staff
                </div>
              </h5>
            </div>
            <div className="text-center">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/adminDashboard">
                      <FontAwesomeIcon icon={faHouse} />
                      <span>PGDA</span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Manage Staff
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <div>
              <h6>Staff List</h6>
            </div>
            <div className="d-flex justify-content-start gap-2">
              {enableDeleteAll ? (
                <>
                  <button
                    type="button"
                    className="btn btn-secondary text-white"
                    onClick={handleDeleteAllCancel}
                  >
                    <FontAwesomeIcon
                      icon={faXmark}
                      style={{ marginRight: 5 }}
                    />
                    <span>Cancel</span>
                  </button>
                  <button
                    className="btn custom-btn"
                    type="button"
                    onClick={handleDeleteAllbtn}
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      style={{ marginRight: 5 }}
                    />
                    <span>Delete All</span>
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="btn custom-btn add-btn"
                    type="button"
                    onClick={handleAddBtn}
                    style={{ marginRight: 0 }}
                  >
                    <FontAwesomeIcon icon={faPlus} style={{ marginRight: 5 }} />
                    <span>Add</span>
                  </button>
                  <button
                    className="btn custom-btn"
                    type="button"
                    onClick={handleBulkUploadBtn}
                  >
                    <span>Bulk Upload</span>
                  </button>
                </>
              )}
              <button
                type="button"
                className="btn custom-btn"
                onClick={onExportToExcel}
              >
                <FontAwesomeIcon
                  icon={faFileExcel}
                  style={{ marginRight: 5 }}
                />
                <span>Export Excel</span>
              </button>
            </div>
          </div>
          <div className="mt-3">
            <div className="ag-theme-quartz" style={{ height: 380 }}>
              <AgGridReact
                rowData={staffRowData}
                columnDefs={columnDefs}
                // autoSizeStrategy={{ type: 'fitCellContents' }}
                defaultColDef={defaultColDef}
                rowSelection="multiple"
                domLayout={"autoHeight"}
                suppressRowClickSelection={true}
                pagination={true}
                paginationPageSize={5}
                paginationPageSizeSelector={[5, 10, 15, 20, 25]}
                tooltipShowDelay={500}
                gridOptions={gridOptions}
                onSelectionChanged={onSelectionChanged}
                ref={gridRef}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showToggleConfirm ? "show" : ""} `}
        tabIndex={-1}
        id="toggleConfirmModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showToggleConfirm ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={activateImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center text-center mt-2">
                <p
                  style={{
                    fontSize: 22,
                    fontWeight: 700,
                    maxWidth: 400,
                    wordWrap: "break-word",
                  }}
                >
                  {toggleMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleToggleCancel}
                  style={{ marginRight: 10, width: 183.5 }}
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleToggleConfirm}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showToggleAlert ? "show" : ""} `}
        tabIndex={-1}
        id="toggleAlertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showToggleAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={activateImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center text-center mt-2">
                <p
                  style={{
                    fontSize: 22,
                    fontWeight: 700,
                    maxWidth: 400,
                    wordWrap: "break-word",
                  }}
                >
                  {toggleMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleToggleAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showDeleteConfirm ? "show" : ""} `}
        tabIndex={-1}
        id="deleteConfirmModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showDeleteConfirm ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={deleteImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center text-center mt-2">
                <p
                  style={{
                    fontSize: 22,
                    fontWeight: 700,
                    maxWidth: 400,
                    wordWrap: "break-word",
                  }}
                >
                  {deleteMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleDeleteCancel}
                  style={{ marginRight: 10, width: 183.5 }}
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleDeleteConfirm}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showDeleteAlert ? "show" : ""} `}
        tabIndex={-1}
        id="deleteConfirmModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showDeleteAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={deleteImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center text-center mt-2">
                <p
                  style={{
                    fontSize: 22,
                    fontWeight: 700,
                    maxWidth: 400,
                    wordWrap: "break-word",
                  }}
                >
                  {deleteMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleDeleteAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showDeleteAllConfirm ? "show" : ""} `}
        tabIndex={-1}
        id="deleteAllConfirmModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showDeleteAllConfirm ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={deleteImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {deleteAllMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleDeleteAllCancelled}
                  style={{ marginRight: 10, width: 183.5 }}
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleDeleteAllConfirm}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showDeleteAllAlert ? "show" : ""} `}
        tabIndex={-1}
        id="deleteAllAlertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showDeleteAllAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={deleteImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center mt-2">
                <p
                  style={{
                    fontSize: 17,
                    fontWeight: 700,
                    textAlign: "center",
                    whiteSpace: "pre-line",
                  }}
                >
                  {deleteAllMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleDeleteAllAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
