import '../../styles/LoginPage.css'
import LoginImg from '../../assets/LoginImg.png';
import Logo from '../../assets/GardiniaLogo.png';
import successImg from '../../assets/success.gif';
import FailImg from '../../assets/Delete.gif';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getData } from '../../services/API';
import { userLogin } from '../../services/Endpoints';
import { useAuth } from '../../providers/AuthContext';

export interface ILoginProps {
}

interface ILoginFormData {
    emailId: string,
    password: string
}

interface IFormErrorData {
    emailId: string,
    password: string
}

export function Login(props: ILoginProps) {
    // Declarations //
    const reRoute = useNavigate();
    const { login } = useAuth();

    const [formData, setFormData] = useState<ILoginFormData>({
        emailId: "",
        password: ""
    });

    const [formError, setFormError] = useState<IFormErrorData>({
        emailId: "",
        password: ""
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showFailAlert, setShowFailAlert] = useState(false);
    const [alertSuccessMessage, setAlertSuccessMessage] = useState("");
    const [alertFailMessage, setAlertFailMessage] = useState("");

    // Event Handlers & Functions // 
    useEffect(() => {

    }, []);

    const onChangeHandler = (event: any) => {
        setFormData(() => ({
            ...formData,
            [event.target.name]: event.target.value
        }));
    }

    const handleSuccessAlert = () => {
        setShowSuccessAlert(false);
        reRoute('/*');
    }

    const handleFailAlert = () => {
        setShowFailAlert(false);
    }

    const validateForm = () => {
        let err: Partial<IFormErrorData> = {};

        if (formData.emailId === "") {
            err.emailId = "Email Address is required.";
        }

        if (formData.password === "") {
            err.password = "Password is required.";
        }

        setFormError(err as IFormErrorData);
        return Object.keys(err).length < 1;
    }

    const onSubmitHandler = async (event: any) => {
        event.preventDefault();
        let isValidForm = validateForm();

        if (isValidForm) {
            //setting loader state
            setIsSubmitting(true);

            const emailId = formData.emailId;
            const password = formData.password;

            // calling save api
            try {
                const responseData = await getData(userLogin + `EmailId=${emailId}&Password=${password}&LoginType=WebApp`);
                if (responseData.success) {
                    login(responseData);
                    //to show the alert
                    setShowSuccessAlert(true);
                    setAlertSuccessMessage(responseData.message);
                    onCancelHandler();
                    setIsSubmitting(false);
                } else {
                    setShowFailAlert(true);
                    setAlertFailMessage(responseData.message);
                    setIsSubmitting(false);
                }
            } catch (error) {
                console.error('Error posting data:', error);
                setIsSubmitting(false);
            }
        }
    }
    const onCancelHandler = () => {
        setFormData({
            emailId: "",
            password: ""
        });

        setFormError({
            emailId: "",
            password: ""
        });
    }

    return (
        <div className="loginContainer">
            <div className="px-2">
                <div className="row gx-4">
                    <div className="col">
                        <div className="border" style={{ height: '90vh', borderRadius: '5px' }}>
                            <img src={LoginImg} alt="logo" className="login-img" />
                        </div>
                    </div>
                    <div className="col">
                        <div className="p-4 border login-page" style={{ height: '90vh', borderRadius: '5px' }}>
                            <div className="d-flex justify-content-center">
                                {/* <div className="login-page-logo-area">
                                    <img src={Logo} alt="logo" className="logo-img" />
                                </div> */}
                                <div className="">
                                    <img src={Logo} alt="logo" className="logo-img" />
                                </div>
                            </div>
                            {/* <div className="d-flex justify-content-center mt-3" style={{ fontSize: '14px', fontWeight: 700 }}>
                                <span>Welcome To Young Construction Portal</span>
                            </div> */}
                            <div className="d-flex justify-content-center mt-2" style={{ fontSize: '13px', fontWeight: 500, color: '#454545' }}>
                                <span>Sign in to continue to Productivity Data Gathering App.</span>
                            </div>
                            <div className="mt-4">
                                <form onSubmit={onSubmitHandler}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label className="form-label" style={{ fontWeight: 600 }}>Email Address</label>
                                            <input type="text" className="form-control" name="emailId" onChange={onChangeHandler} placeholder="e.g ramesh@youngconstruction.ae" />
                                            <span className="text-danger">{formError.emailId}</span>
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            <label className="form-label" style={{ fontWeight: 600 }}>Password</label>
                                            <input type="password" className="form-control" name="password" onChange={onChangeHandler} placeholder=""></input>
                                            {/* <span className="input-icon"><FontAwesomeIcon icon={faEye}/></span> */}
                                            <span className="text-danger">{formError.password}</span>
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="gridCheck1" />
                                                <label className="form-check-label" style={{ fontWeight: 600 }}>
                                                    Remember Me
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            <button type="submit" className="btn custom-btn w-100" hidden={isSubmitting}>
                                                Login
                                            </button>
                                            <button type="submit" className="btn custom-btn w-100" hidden={!isSubmitting}>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                <span>Logging in</span>
                                            </button>
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            {/* <button type="button" className="btn btn-outline-dark w-100">
                                                Forgot Password ?
                                            </button> */}
                                            <Link to="/forgotPasswordEmail" className="btn btn-outline-dark w-100">
                                                Forgot Password ?
                                            </Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade ${showSuccessAlert ? 'show' : ''} `} tabIndex={-1} id="alertModal" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: showSuccessAlert ? 'block' : 'none' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="d-flex justify-content-center">
                                <img src={successImg} alt="logo" className="" style={{ width: 150, height: 150 }} />
                            </div>
                            <div className="d-flex justify-content-center mt-2">
                                <p style={{ fontSize: 22, fontWeight: 700 }}>{alertSuccessMessage}</p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button type="button" className="btn custom-btn" onClick={handleSuccessAlert} style={{ width: 183.5 }}>Okay</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade ${showFailAlert ? 'show' : ''} `} tabIndex={-1} id="alertModal" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: showFailAlert ? 'block' : 'none' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="d-flex justify-content-center">
                                <img src={FailImg} alt="logo" className="" style={{ width: 150, height: 150 }} />
                            </div>
                            <div className="d-flex justify-content-center mt-2">
                                <p style={{ fontSize: 22, fontWeight: 700 }}>{alertFailMessage}</p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button type="button" className="btn custom-btn" onClick={handleFailAlert} style={{ width: 183.5 }}>Okay</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
