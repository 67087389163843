// getFontAwesomeIcon.js
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faList,
  faUserTie,
  faUserGear,
  faUsersGear,
  faUsers,
  faRectangleList,
  faBuildingFlag,
  faLayerGroup,
  faPersonDigging,
  faBuilding,
  faChartLine,
  faFileShield,
  faClipboard,
  faChartSimple,
  faGears,
  faWeightScale,
  faUserInjured,
  faBorderNone,
  faTrowelBricks,
  faCloud,
} from "@fortawesome/free-solid-svg-icons";

export const getFontAwesomeIcon = (iconName: string): IconProp => {
  switch (iconName) {
    case "faList":
      return faList;
    case "faUserTie":
      return faUserTie;
    case "faGears":
      return faGears;
    case "faWeightScale":
      return faWeightScale;
    case "faTrowelBricks":
      return faTrowelBricks;
    case "faUserInjured":
      return faUserInjured;
    case "faBorderNone":
      return faBorderNone;
    case "faUsersGear":
      return faUsersGear;
    case "faUsers":
      return faUsers;
    case "faRectangleList":
      return faRectangleList;
    case "faUserGear":
      return faUserGear;
    case "faBuildingFlag":
      return faBuildingFlag;
    case "faLayerGroup":
      return faLayerGroup;
    case "faPersonDigging":
      return faPersonDigging;
    case "faBuilding":
      return faBuilding;
    case "faChartLine":
      return faChartLine;
    case "faFileShield":
      return faFileShield;
    case "faClipboard":
      return faClipboard;
    case "faChartSimple":
      return faChartSimple;
      case "faCloud":
        return faCloud;

      default:
        console.error(`Unknown icon name: ${iconName}`);
        return faList; // return a default icon like faList
  }
};
