import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/GardiniaLogo.png";
import "../../styles/ForgotPassword.css";
import { useState } from "react";
import { getData } from "../../services/API";
import { OTPForForgotPassword } from "../../services/Endpoints";
import { useAuth } from "../../providers/AuthContext";
import successImg from "../../assets/success.gif";
import FailImg from "../../assets/Delete.gif";

export interface IForgotPasswordEmailProps {}

interface IFormData {
  EmailId: string;
}

interface IFormErrorData {
  EmailId: string;
}

export function ForgotPasswordEmail() {
  // Declarations //
  const reRoute = useNavigate();
  const { setOTPData } = useAuth();

  const [formData, setFormData] = useState<IFormData>({
    EmailId: "",
  });

  const [formError, setFormError] = useState<IFormErrorData>({
    EmailId: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [alertSuccessMessage, setAlertSuccessMessage] = useState("");
  const [alertFailMessage, setAlertFailMessage] = useState("");

  const onChangeHandler = (event: any) => {
    validatefields(event.target.name, event.target.value);
    setFormData(() => ({
      ...formData,
      [event.target.name]: event.target.value,
    }));
  };

  const validatefields = (fieldName: string, fieldValue: any) => {
    if (fieldName.includes("Email")) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      } else if (fieldValue.length < 2 || fieldValue.length > 50) {
        setFormError({
          ...formError,
          [fieldName]: "Must be between 2 and 50 characters",
        });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    }
  };

  const validateForm = () => {
    let err: Partial<IFormErrorData> = {};

    if (formData.EmailId === "") {
      err.EmailId = "Email Address is required.";
    } else if (!validateEmail(formData.EmailId)) {
      err.EmailId = "Invalid email format.";
    } else {
      if (validatefields("EmailId", formData.EmailId)) {
        err.EmailId = "Must be between 2 and 50 characters.";
      }
    }

    setFormError(err as IFormErrorData);
    return Object.keys(err).length < 1;
  };

  const validateEmail = (email: string) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const onSubmitHandler = async (event: any) => {
    event.preventDefault();
    let isValidForm = validateForm();

    if (isValidForm) {
      //setting loader state
      setIsSubmitting(true);

      //calling save api
      try {
        const responseData = await getData(
          OTPForForgotPassword + `EmailId=${formData.EmailId}`
        );
        if (responseData.success) {
          if (responseData.data != null) {
            setOTPData(responseData.data);
          }
          //to show the alert
          setShowSuccessAlert(true);
          setAlertSuccessMessage(responseData.message);
          onCancelHandler();
          setIsSubmitting(false);
        } else {
          setShowFailAlert(true);
          setAlertFailMessage(responseData.message);
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error("Error posting data:", error);
        setIsSubmitting(false);
      }
    }
  };

  const onCancelHandler = () => {
    setFormData({
      EmailId: "",
    });

    setFormError({
      EmailId: "",
    });
  };

  const handleSuccessAlert = () => {
    setShowSuccessAlert(false);
    reRoute("/forgotPasswordOtp");
  };

  const handleFailAlert = () => {
    setShowFailAlert(false);
  };

  return (
    <>
      <div className="container-fluid center-card">
        <div className="d-flex justify-content-center align-items-center">
          <div className="row">
            <div className="col-md">
              <div className="card p-3">
                <div className="card-body">
                  <div className="row">
                    <div className="d-flex justify-content-center">
                      <img src={Logo} alt="logo" className="logo-img" />
                    </div>
                  </div>
                  <div className="d-flex justify-content-center textDark mt-3">
                    <span>Forgot Password</span>
                  </div>
                  <div className="d-flex justify-content-center textGray mt-3">
                    <span style={{ width: 400, textAlign: "center" }}>
                      A six-digit one-time password(OTP) will be sent to the
                      email address
                    </span>
                  </div>
                  <form className="mt-3" onSubmit={onSubmitHandler}>
                    <div className="row">
                      <div className="col-md">
                        <label className="form-label">
                          <b>Enter Registered Email Address</b>{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="EmailId"
                          value={formData.EmailId}
                          onChange={onChangeHandler}
                        />
                        <span className="text-danger">{formError.EmailId}</span>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md">
                        <button
                          type="submit"
                          className="btn custom-btn"
                          style={{ width: "100%" }}
                        >
                          {!isSubmitting ? (
                            <span>Send OTP</span>
                          ) : (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span>Sending</span>
                            </>
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                  <div className="d-flex justify-content-center mt-3">
                    <Link to="/">Login here</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showSuccessAlert ? "show" : ""} `}
        tabIndex={-1}
        id="alertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showSuccessAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={successImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {alertSuccessMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleSuccessAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showFailAlert ? "show" : ""} `}
        tabIndex={-1}
        id="alertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showFailAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={FailImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {alertFailMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleFailAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
