import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/GardiniaLogo.png";
import successImg from "../../assets/success.gif";
import FailImg from "../../assets/Delete.gif";
import { useAuth } from "../../providers/AuthContext";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

export interface IForgotPasswordVerifiedEmailProps {}

export function ForgotPasswordVerifiedEmail() {
  const reRoute = useNavigate();
  const { OTPData } = useAuth();

  // const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [alertSuccessMessage] = useState("");
  const [alertFailMessage, setAlertFailMessage] = useState("");

  useEffect(() => {
    if (Object.keys(OTPData).length === 0) {
      setAlertFailMessage("please enter registred email to get OTP.");
      setShowFailAlert(true);
    }
  }, [OTPData]);

  const handleSuccessAlert = () => {
    setShowSuccessAlert(false);
  };

  const handleFailAlert = () => {
    setShowFailAlert(false);
    reRoute("/forgotPasswordEmail");
  };

  return (
    <>
      <div className="container-fluid center-card">
        <div className="d-flex justify-content-center align-items-center">
          <div className="row">
            <div className="col-md">
              <div className="card p-3">
                <div className="card-body">
                  <div className="row">
                    <div className="d-flex justify-content-center">
                      <img src={Logo} alt="logo" className="logo-img" />
                    </div>
                  </div>
                  <div className="d-flex justify-content-center textDark mt-3">
                    <span>Forgot Password</span>
                  </div>
                  <div className="d-flex justify-content-center textGray mt-3">
                    <span style={{ width: 400, textAlign: "center" }}>
                      Click on Reset Password button to set new password
                    </span>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md">
                      <label className="form-label">
                        <b>Email Address</b>
                      </label>
                      <span className="text-success float-end">
                        <b>Verified</b> <FontAwesomeIcon icon={faCircleCheck} />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        name="EmailId"
                        value={OTPData.email}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md">
                      <Link
                        to="/resetPassword"
                        className="btn custom-btn"
                        style={{ width: "100%" }}
                      >
                        Reset Password
                      </Link>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center mt-3">
                    <Link to="/">Login here</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showSuccessAlert ? "show" : ""} `}
        tabIndex={-1}
        id="alertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showSuccessAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={successImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {alertSuccessMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleSuccessAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showFailAlert ? "show" : ""} `}
        tabIndex={-1}
        id="alertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showFailAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={FailImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {alertFailMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleFailAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
