import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";

export interface IDataGridActionBtnsProps {}

export function DataGridActionBtns(props: any) {
  // const [enabled, setEnabled] = useState(false)

  const onViewClick = () => {
    props.onViewClick(props.data);
  };

  const onDeleteClick = () => {
    props.onDeleteClick(props.data);
  };

  const onSwitchClick = () => {
    props.onSwitchClick(props.data);
  };

  return (
    <div className="d-flex">
      <div>
        <button
          className="btn btn-sm btn-success mb-1"
          onClick={onViewClick}
          style={{ marginRight: 5 }}
        >
          <FontAwesomeIcon icon={faPenToSquare} />
        </button>
      </div>
      <div>
        <button
          className="btn btn-sm btn-danger mb-1"
          onClick={onDeleteClick}
          style={{ marginRight: 5 }}
        >
          <FontAwesomeIcon icon={faTrashCan} />
        </button>
      </div>
      <div className="form-check form-switch d-flex" style={{ width: 10 }}>
        <input
          className={`form-check-input align-self-center ${
            props.data.activeFlag === 0 ? "inactive-toggle" : "active-toggle"
          }`}
          type="checkbox"
          checked={props.data.activeFlag === 0 ? false : true}
          onChange={onSwitchClick}
          style={{ width: 40, height: 24, marginBottom: 1, cursor: "pointer" }}
        />
      </div>
    </div>
  );
}
