import * as React from 'react';

export interface IProjectAdminDashboardProps {
}

export function ProjectAdminDashboard() {
    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card text-center">
                        <div className="card-body">
                            <h5 className="card-title">Dashboard</h5>
                            <p className="card-text">The dashboard page is under development.</p>
                            <p className="card-text">Stay tuned for updates!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
