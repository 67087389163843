import { SideNavBarToggleBtn } from './SideNavbarToggleBtn';
import '../../styles/Header.css';

export interface IHeaderProps {
    isSidebarCollapsed: boolean;
    toggleSidebar: () => void;
}

export function Header({ toggleSidebar }: IHeaderProps) {
    return (
        <div className="header">
            <SideNavBarToggleBtn toggleSidebar={toggleSidebar}/>
        </div>
    );
}
