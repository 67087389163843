import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faWeightScale, faSave, faXmark, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { saveUom } from '../../../services/Endpoints';
import { postData } from '../../../services/API';
import { Link, useNavigate } from 'react-router-dom';
import successImg from '../../../assets/success.gif';
import FailImg from '../../../assets/Delete.gif';
import { useAuth } from '../../../providers/AuthContext';

export interface IAddUomProps {
}

interface IUomData {
    uomId: number,
    companyId: number,
    uomCode: string,
    uomName: string,
    userId: number
}

interface IFormErrorData {
    uomCode: string,
    uomName: string
}

export function AddUom() {
    // Declarations //
    const reRoute = useNavigate();
    const { userData, companyData } = useAuth();

    const [formData, setFormData] = useState<IUomData>({
        uomId: 0,
        companyId: companyData.companyId,
        uomCode: "",
        uomName: "",
        userId: userData.userId
    });

    const [formError, setFormError] = useState<IFormErrorData>({
        uomCode: "",
        uomName: ""
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showFailAlert, setShowFailAlert] = useState(false);
    const [alertSuccessMessage, setAlertSuccessMessage] = useState("");
    const [alertFailMessage, setAlertFailMessage] = useState("");

    // Event Handlers & Functions // 
    useEffect(() => {

    }, []);

    const onChangeHandler = (event: any) => {
        validatefields(event.target.name, event.target.value);
        setFormData(() => ({
            ...formData,
            [event.target.name]: event.target.value
        }));
    }

    const validatefields = (fieldName: string, fieldValue: any) => {
        const testRegx = /^\s+/;
        const textregex = /^[A-Za-z ]*$/;
        const alphanumericRegex = /^[A-Za-z0-9 ]*$/;
        if (fieldName.includes('Code')) {
            if (fieldValue.length === 0) {
                setFormError({ ...formError, [fieldName]: '' });
                return false;
            } else if (fieldValue.trim() === "" || testRegx.test(fieldValue)) {
                setFormError({ ...formError, [fieldName]: 'Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.' });
                return true;
            }else if (!alphanumericRegex.test(fieldValue)) {
                setFormError({ ...formError, [fieldName]: 'Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.' });
                return true;
            } else if (fieldValue.length < 2 || fieldValue.length > 20) {
                setFormError({ ...formError, [fieldName]: 'Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.' });
                return true;
            } else {
                setFormError({ ...formError, [fieldName]: '' });
                return false;
            }
        } else if (fieldName.includes('Name')) {
            if (fieldValue.length === 0) {
                setFormError({ ...formError, [fieldName]: '' });
                return false;
            } else if (fieldValue.trim() === "" || testRegx.test(fieldValue)) {
                setFormError({ ...formError, [fieldName]: 'Must be between 2 to 50 characters of alphabets only, without empty spaces at the beginning.' });
                return true;
            } else if (!textregex.test(fieldValue)) {
                setFormError({ ...formError, [fieldName]: 'Must be between 2 to 50 characters of alphabets only, without empty spaces at the beginning.' });
                return true;
            }else if (fieldValue.length < 2 || fieldValue.length > 50) {
                setFormError({ ...formError, [fieldName]: 'Must be between 2 to 50 characters of alphabets only, without empty spaces at the beginning.' });
                return true;
            } else {
                setFormError({ ...formError, [fieldName]: '' });
                return false;
            }
        }
    }

    const validateForm = () => {
        let err: Partial<IFormErrorData> = {};

        if (formData.uomCode.trim() === "") {
            err.uomCode = "UOM Code is required.";
        } else {
            if (validatefields("uomCode", formData.uomCode)) {
                err.uomCode = "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.";
            }
        }

        if (formData.uomName.trim() === "") {
            err.uomName = "UOM Name is required.";
        } else {
            if (validatefields("uomName", formData.uomName)) {
                err.uomName = "Must be between 2 to 50 characters of alphabets only, without empty spaces at the beginning.";
            }
        }

        setFormError(err as IFormErrorData);
        return Object.keys(err).length < 1;
    }

    const handleSuccessAlert = () => {
        setShowSuccessAlert(false);
        reRoute('/manageUom');
    }

    const handleFailAlert = () => {
        setShowFailAlert(false);
    }

    const onSubmitHandler = async (event: any) => {
        event.preventDefault();
        let isValidForm = validateForm();

        if (isValidForm) {
            //setting loader state
            setIsSubmitting(true);

            // calling save api
            try {
                const responseData = await postData(saveUom, formData);
                if (responseData.success) {
                    //to show the alert
                    setShowSuccessAlert(true);
                    setAlertSuccessMessage(responseData.message);
                    onCancelHandler();
                    setIsSubmitting(false);
                } else {
                    setShowFailAlert(true);
                    setAlertFailMessage(responseData.message);
                    setIsSubmitting(false);
                }
            } catch (error) {
                console.error('Error posting data:', error);
                setIsSubmitting(false);
            }
        }
    }

    const onCancelHandler = () => {
        setFormData({
            uomId: 0,
            companyId: companyData.companyId,
            uomCode: "",
            uomName: "",
            userId: userData.userId
        });

        setFormError({
            uomCode: "",
            uomName: ""
        });
    }

    return (
        <>
            <div className="card">
                <div className="page-header">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <h5>
                                <Link to="/manageUom" className="btn bg-light" style={{ marginRight: '10px' }}>
                                    <FontAwesomeIcon icon={faArrowLeft} />
                                </Link>
                                Add UOM
                            </h5>
                        </div>
                        <div className="text-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/adminDashboard">
                                            <FontAwesomeIcon icon={faHouse} />
                                            <span>PGDA</span>
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/manageUom">
                                            <FontAwesomeIcon icon={faWeightScale} />
                                            <span>Manage UOM</span>
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Add UOM</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mt-3">
                <div className="card-body">
                    <form className="g-3" onSubmit={onSubmitHandler}>
                        <div className="row">
                            <div className="col-md-4">
                                <label className="form-label">UOM Code <span className="text-danger">*</span></label>
                                <input type="text" className="form-control" name="uomCode" value={formData.uomCode} onChange={onChangeHandler} />
                                <span className="text-danger">{formError.uomCode}</span>
                            </div>
                            <div className="col-md-4">
                                <label className="form-label">UOM Name <span className="text-danger">*</span></label>
                                <input type="text" className="form-control" name="uomName" value={formData.uomName} onChange={onChangeHandler} />
                                <span className="text-danger">{formError.uomName}</span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mt-3">
                            <button type="button" className="btn btn-secondary text-white" onClick={onCancelHandler} disabled={isSubmitting} style={{ marginRight: 10 }}>
                                <FontAwesomeIcon icon={faXmark} style={{ marginRight: 5 }} />
                                <span>Clear</span>
                            </button>
                            <button type="submit" className="btn custom-btn" hidden={isSubmitting}>
                                <FontAwesomeIcon icon={faSave} style={{ marginRight: 5 }} />
                                <span>Submit</span>
                            </button>
                            <button type="button" className="btn custom-btn submitting-btn" hidden={!isSubmitting}>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span>Submitting</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div className={`modal fade ${showSuccessAlert ? 'show' : ''} `} tabIndex={-1} id="alertModal" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: showSuccessAlert ? 'block' : 'none' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="d-flex justify-content-center">
                                <img src={successImg} alt="logo" className="" style={{ width: 150, height: 150 }} />
                            </div>
                            <div className="d-flex justify-content-center mt-2">
                                <p style={{ fontSize: 22, fontWeight: 700, textAlign: 'center' }}>{alertSuccessMessage}</p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button type="button" className="btn custom-btn" onClick={handleSuccessAlert} style={{ width: 183.5 }}>Okay</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade ${showFailAlert ? 'show' : ''} `} tabIndex={-1} id="alertModal" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: showFailAlert ? 'block' : 'none' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="d-flex justify-content-center">
                                <img src={FailImg} alt="logo" className="" style={{ width: 150, height: 150 }} />
                            </div>
                            <div className="d-flex justify-content-center mt-2">
                                <p style={{ fontSize: 22, fontWeight: 700, textAlign: 'center' }}>{alertFailMessage}</p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button type="button" className="btn custom-btn" onClick={handleFailAlert} style={{ width: 183.5 }}>Okay</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
