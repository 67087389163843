import React from "react";
// import "../../styles/PrivacyPolicy.css";
import Logo from "../../assets/MSNT.png";

const transmissions = [
  "Encryption: Track Pilot utilizes encryption protocols, such as SSL  (Secure Sockets Layer) or TLS (Transport Layer Security), to  encrypt data transmitted between users devices and the Track  Pilot servers. This encryption helps protect sensitive information  from unauthorized access during transmission.",
  "Secure Data Transfer Protocols: Track Pilot follows industry best  practices by utilizing secure data transfer protocols for  transmitting information. These protocols ensure that data is  transferred securely and cannot be intercepted or tampered with  during transmission.",
  "Authentication and Access Controls: Track Pilot implements robust  authentication mechanisms to verify the identity of users  accessing the platform. This helps prevent unauthorized access to  sensitive information. Access controls are also implemented to  ensure that only authorized individuals within the institution can  access specific data.",
  "Firewalls and Intrusion Detection Systems: Track Pilot employs  firewalls and intrusion detection systems to monitor and protect  its network infrastructure from unauthorized access attempts and  malicious activities. These security measures help safeguard  information transmissions and prevent unauthorized access to the  system.",
  "Regular Security Audits and Updates: Track Pilot conducts regular  security audits and assessments to identify and address any  vulnerabilities in its systems. This includes applying security  updates and patches to ensure that the platforms infrastructure  remains up to date and protected against emerging threats.",
  "Data Protection Policies: Track Pilot has comprehensive data  protection policies in place to guide the handling and  transmission of sensitive information. These policies outline  security measures and protocols to ensure the secure transmission  of data within the platform.",
  "Location Access: The primary purpose of collecting GPS coordinates  when an inspector scans a QR code is to accurately record the  location where the material inspection or interaction occurred.  This data is essential for traceability, quality control, and  operational management and it is retained only for as long as  necessary to fulfill the purposes outlined in the privacy policy  and as required by applicable laws or regulations.",
];

export function PrivacyPolicy() {
  return (
    <div
      className="container text-center border"
      style={{ marginTop: 30, borderRadius: "5px" }}
    >
      <div style={{ marginTop: 30, fontSize: 20 }}>
        <div className="d-flex justify-content-center gap-2">
          <img
            src={Logo}
            alt="logo"
            style={{
              width: 50,
              height: 50,
              objectFit: "contain",
              borderRadius: "4px",
            }}
          />
          <h1 style={{ fontWeight: 700 }}>Track Pilot</h1>
        </div>
      </div>
      <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
        <h4>PRIVACY POLICY</h4>
        <div style={{ margin: "auto", maxWidth: "65vw", textAlign: "justify" }}>
          <p>
            With Track Pilot, educational institutions and students have access
            to a wide range of powerful features that enhance the learning
            experience. Institutions can leverage comprehensive digital learning
            solutions, including virtual classrooms, online assignments,
            interactive content, and collaboration tools. These features enable
            teachers to create engaging and interactive lessons, track student
            progress, and provide personalized feedback. Students, on the other
            hand, can benefit from a user-friendly platform that allows them to
            access educational resources, participate in discussions, submit
            assignments, and communicate with teachers and peers. Track Pilot
            empowers institutions and students alike, facilitating seamless
            learning and fostering a dynamic educational environment.
          </p>
        </div>

        <h4>WHAT INFORMATION DO WE COLLECT?</h4>

        <div style={{ margin: "auto", maxWidth: "65vw", textAlign: "justify" }}>
          <p>
            When you visit our website, you may provide us with two types of
            information: personal information you knowingly choose to disclose,
            which is collected on an individual basis, and website usage
            information collected on an aggregate basis as you and others browse
            our website. Track Pilot Powered by Dimension64 does not ask or
            request its users to share any sensitive personal data or
            information, including financial information, physical,
            physiological, and/or mental health condition, sexual orientation,
            medical history, biometric information, etc. Track Pilot Powered by
            Dimension64 collects only general personal information of the user
            in order to provide access to certain information, materials, and
            services.
            <br />
            Financial information such as bank account or credit card or debit
            card or other payment instrument details are collected by Payment
            Gateways through which the subscription payments are processed.
            Track Pilot Powered by Dimension64 does not store or retain any such
            financial information.
          </p>
        </div>

        <h4>HOW DO WE SECURE INFORMATION TRANSMISSIONS?</h4>

        <div style={{ margin: "auto", maxWidth: "65vw", textAlign: "justify" }}>
          <p>
            Track Pilot employs various measures to ensure the secure
            transmission of information. Here are some ways in which Track Pilot
            secures information transmissions:
          </p>
          <ul>
            {transmissions.map((transmission, index) => (
              <li key={index} style={{ listStyleType: "disc" }}>
                {transmission}
              </li>
            ))}
          </ul>
          <p>
            It's important to note that while Track Pilot takes measures to
            secure information transmissions, users also play a role in
            maintaining security. Users should be encouraged to use strong
            passwords, keep their login credentials confidential, and follow
            best practices for online security. By implementing these security
            measures, Track Pilot aims to provide a secure environment for the
            transmission of information, protecting the confidentiality and
            integrity of user data throughout the platform.
          </p>
        </div>
      </div>
    </div>
  );
}
