// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-area {
    background-color: #6754E2;
    border-radius: 44px;
    padding: 12px;
}

.center-card {
    height: 100vh;
    display: grid;
    align-self: center;
}

.textDark {
    font-size: 16px;
    font-weight: 700;
}

.textGray {
    font-size: 14px;
    font-weight: 500;
    color: gray;
}

.otp-container {
    width: 450px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/ForgotPassword.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".logo-area {\r\n    background-color: #6754E2;\r\n    border-radius: 44px;\r\n    padding: 12px;\r\n}\r\n\r\n.center-card {\r\n    height: 100vh;\r\n    display: grid;\r\n    align-self: center;\r\n}\r\n\r\n.textDark {\r\n    font-size: 16px;\r\n    font-weight: 700;\r\n}\r\n\r\n.textGray {\r\n    font-size: 14px;\r\n    font-weight: 500;\r\n    color: gray;\r\n}\r\n\r\n.otp-container {\r\n    width: 450px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
