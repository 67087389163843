import { useEffect, useState } from "react";

export interface ITaskLevelTreeProps {
  levels: TaskMappingLevels[];
  rowStyle?: React.CSSProperties;
  onFetchLevels: (projectId: number, projectLevelId: number) => void;
  onPrjLvlId: (prjLvlId: number) => void;
}

interface TaskMappingLevels {
  projectLevelId: number;
  projectId: number;
  rootId: number;
  levelName: string;
  levelCode: string;
  projectLevels: any[];
  isDisable: boolean;
  srNo: number;
  selected: boolean;
}

export function TaskLevelTree(props: ITaskLevelTreeProps) {
  const [projectLevels, setProjectLevels] = useState<TaskMappingLevels[]>(
    props.levels
  );
  const [selectedProjectLevelId, setSelectedProjectLevelId] = useState(0);

  // const stRw = { marginLeft: 10 };

  useEffect(() => {
    setProjectLevels(props.levels);
  }, [props.levels]);

  const handleRadioChange = (item: any) => {
    if (item.projectlevelId !== selectedProjectLevelId) {
      setSelectedProjectLevelId(item.projectLevelId);
      props.onFetchLevels(item.projectId, item.projectLevelId);
      props.onPrjLvlId(item.projectLevelId);
    }
  };

  return (
    <>
      {projectLevels.map((item, index) => (
        <div className="row mt-2" key={index} style={props.rowStyle}>
          <div className="col-md">
            <div
              className="form-check form-check-inline"
              onClick={() => handleRadioChange(item)}
            >
              <input
                className="form-check-input"
                type="radio"
                name="projectLevels"
                id={`levelName${String(item.projectLevelId)}`}
                checked={item.selected}
                value={item.projectLevelId}
                readOnly
              />
              <label className="form-check-label">{item.levelName}</label>
            </div>
            {item.projectLevels && (
              <TaskLevelTree
                levels={item.projectLevels}
                onFetchLevels={props.onFetchLevels}
                onPrjLvlId={props.onPrjLvlId}
                rowStyle={{ marginLeft: 10 }}
              />
            )}
          </div>
        </div>
      ))}
    </>
  );
}
