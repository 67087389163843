import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faSave,
  faXmark,
  faTrowelBricks,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { getEditActivity, saveActivity } from "../../../services/Endpoints";
import { getDataById, postData } from "../../../services/API";
import { Link, useNavigate, useParams } from "react-router-dom";
import successImg from "../../../assets/success.gif";
import FailImg from "../../../assets/Delete.gif";
import { useAuth } from "../../../providers/AuthContext";

export interface IEditActivityProps {}

interface IActivityData {
  activityId: number;
  // companyId: number,
  projectId: number;
  activityCode: string;
  activityName: string;
  costCode: string;
  taktCode: string;
  p6code: string;
  userId: number;
}

interface IFormErrorData {
  activityCode: string;
  activityName: string;
  costCode: string;
  taktCode: string;
  p6code: string;
}
export function EditActivity() {
  // Declarations //
  const { Id } = useParams();
  const reRoute = useNavigate();
  const { userData, projectData } = useAuth();

  const [formData, setFormData] = useState<IActivityData>({
    activityId: 0,
    // companyId: companyData.compnayId,
    projectId: projectData.projectId,
    activityCode: "",
    activityName: "",
    costCode: "",
    taktCode: "",
    p6code: "",
    userId: userData.userId,
  });

  const [formError, setFormError] = useState<IFormErrorData>({
    activityCode: "",
    activityName: "",
    costCode: "",
    taktCode: "",
    p6code: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [alertSuccessMessage, setAlertSuccessMessage] = useState("");
  const [alertFailMessage, setAlertFailMessage] = useState("");

  // Event Handlers & Functions //
  useEffect(() => {
    const fetchActivityData = async () => {
      // Call getDataById API
      try {
        const responseData = await getDataById(getEditActivity, Id);
        if (responseData.success) {
          var dat = responseData.data;
          setFormData({
            activityId: dat.activityId,
            // companyId: dat.companyId,
            projectId: dat.projectId,
            activityCode: dat.activityCode,
            activityName: dat.activityName,
            costCode: dat.costCode === null ? "" : dat.costCode,
            taktCode: dat.taktCode === null ? "" : dat.taktCode,
            p6code: dat.p6code === null ? "" : dat.p6code,
            userId: userData.userId,
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchActivityData();
  }, [Id, userData.userId]);

  const onChangeHandler = (event: any) => {
    validatefields(event.target.name, event.target.value);
    setFormData(() => ({
      ...formData,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSuccessAlert = () => {
    setShowSuccessAlert(false);
    reRoute("/manageActivity");
  };

  const handleFailAlert = () => {
    setShowFailAlert(false);
  };

  const validatefields = (fieldName: string, fieldValue: any) => {
    const testRegx = /^\s+/;
    const alphanumericRegex = /^[A-Za-z0-9 ]*$/;
    if (fieldName.includes("activityCode")) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      } else if (fieldValue.trim() === "" || testRegx.test(fieldValue)) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
        });
        return true;
      } else if (!alphanumericRegex.test(fieldValue)) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
        });
        return true;
      } else if (fieldValue.length < 2 || fieldValue.length > 20) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
        });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("activityName")) {
      if (fieldValue.length === 0) {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      } else if (fieldValue.trim() === "" || testRegx.test(fieldValue)) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 to 200 characters, without empty spaces at the beginning.",
        });
        return true;
      }
      // else if (!alphanumericRegex.test(fieldValue)) {
      //     setFormError({ ...formError, [fieldName]: 'Must be between 2 to 50 characters, without empty spaces at the beginning.' });
      //     return true;
      // }
      else if (fieldValue.length < 2 || fieldValue.length > 200) {
        setFormError({
          ...formError,
          [fieldName]:
            "Must be between 2 to 200 characters, without empty spaces at the beginning.",
        });
        return true;
      } else {
        setFormError({ ...formError, [fieldName]: "" });
        return false;
      }
    } else if (fieldName.includes("costCode")) {
      if (fieldValue !== null) {
        if (fieldValue.length === 0) {
          setFormError({ ...formError, [fieldName]: "" });
          return false;
        } else if (fieldValue.trim() === "" || testRegx.test(fieldValue)) {
          setFormError({
            ...formError,
            [fieldName]:
              "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
          });
          return true;
        } else if (!alphanumericRegex.test(fieldValue)) {
          setFormError({
            ...formError,
            [fieldName]:
              "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
          });
          return true;
        } else if (fieldValue.length < 2 || fieldValue.length > 20) {
          setFormError({
            ...formError,
            [fieldName]:
              "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
          });
          return true;
        } else {
          setFormError({ ...formError, [fieldName]: "" });
          return false;
        }
      }
    } else if (fieldName.includes("taktCode")) {
      if (fieldValue !== null) {
        if (fieldValue !== null && fieldValue.length === 0) {
          setFormError({ ...formError, [fieldName]: "" });
          return false;
        } else if (fieldValue.trim() === "" || testRegx.test(fieldValue)) {
          setFormError({
            ...formError,
            [fieldName]:
              "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
          });
          return true;
        } else if (!alphanumericRegex.test(fieldValue)) {
          setFormError({
            ...formError,
            [fieldName]:
              "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
          });
          return true;
        } else if (fieldValue.length < 2 || fieldValue.length > 20) {
          setFormError({
            ...formError,
            [fieldName]:
              "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
          });
          return true;
        } else {
          setFormError({ ...formError, [fieldName]: "" });
          return false;
        }
      }
    } else if (fieldName.includes("p6code")) {
      if (fieldValue !== null) {
        if (fieldValue !== null && fieldValue.length === 0) {
          setFormError({ ...formError, [fieldName]: "" });
          return false;
        } else if (fieldValue.trim() === "" || testRegx.test(fieldValue)) {
          setFormError({
            ...formError,
            [fieldName]:
              "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
          });
          return true;
        } else if (!alphanumericRegex.test(fieldValue)) {
          setFormError({
            ...formError,
            [fieldName]:
              "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
          });
          return true;
        } else if (fieldValue.length < 2 || fieldValue.length > 20) {
          setFormError({
            ...formError,
            [fieldName]:
              "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.",
          });
          return true;
        } else {
          setFormError({ ...formError, [fieldName]: "" });
          return false;
        }
      }
    }
  };

  const validateForm = () => {
    let err: Partial<IFormErrorData> = {};

    if (formData.activityName.trim() === "") {
      err.activityName = "Activity Name is required.";
    } else {
      if (validatefields("activityName", formData.activityName)) {
        err.activityName =
          "Must be between 2 to 50 characters, without empty spaces at the beginning.";
      }
    }

    if (formData.activityCode.trim() === "") {
      err.activityCode = "Activity Code is required.";
    } else {
      if (validatefields("activityCode", formData.activityCode)) {
        err.activityCode =
          "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.";
      }
    }

    if (validatefields("costCode", formData.costCode)) {
      err.costCode =
        "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.";
    }

    if (validatefields("taktCode", formData.taktCode)) {
      err.taktCode =
        "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.";
    }

    if (validatefields("p6code", formData.p6code)) {
      err.p6code =
        "Must be between 2 to 20 characters of alphanumeric only, without empty spaces at the beginning.";
    }

    setFormError(err as IFormErrorData);
    return Object.keys(err).length < 1;
  };

  const onSubmitHandler = async (event: any) => {
    event.preventDefault();
    let isValidForm = validateForm();

    if (isValidForm) {
      //setting loader state
      setIsSubmitting(true);

      // calling save api
      try {
        const responseData = await postData(saveActivity, formData);
        if (responseData.success) {
          //to show the alert
          setShowSuccessAlert(true);
          setAlertSuccessMessage(responseData.message);
          onCancelHandler();
          setIsSubmitting(false);
        } else {
          setShowFailAlert(true);
          setAlertFailMessage(responseData.message);
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error("Error posting data:", error);
      }
    }
  };

  const onCancelHandler = () => {
    // setFormData({
    //     activityId: 0,
    //     companyId: companyData.companyId,
    //     projectId: projectData.projectId,
    //     activityCode: "",
    //     activityName: "",
    //     costCode: "",
    //     taktCode: "",
    //     p6code: "",
    //     userId: userData.userId
    // });

    // setFormError({
    //     activityCode: "",
    //     activityName: "",
    //     costCode: "",
    //     taktCode: "",
    //     p6code: "",
    // });
    reRoute("/manageActivity");
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h5>
                <Link
                  to="/manageActivity"
                  className="btn bg-light"
                  style={{ marginRight: "10px" }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </Link>
                Edit Activity
              </h5>
            </div>
            <div className="text-center">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/adminDashboard">
                      <FontAwesomeIcon icon={faHouse} />
                      <span>PGDA</span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/manageActivity">
                      <FontAwesomeIcon icon={faTrowelBricks} />
                      <span>Manage Activity</span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Edit Activity
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-body">
          <form className="g-3" onSubmit={onSubmitHandler}>
            <div className="row">
              <div className="col-md-4">
                <label className="form-label">
                  Activity Code <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="activityCode"
                  value={formData.activityCode}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.activityCode}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">
                  Activity Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="activityName"
                  value={formData.activityName}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.activityName}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">Cost Code</label>
                <input
                  type="text"
                  className="form-control"
                  name="costCode"
                  value={formData.costCode}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.costCode}</span>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <label className="form-label">Takt Code</label>
                <input
                  type="text"
                  className="form-control"
                  name="taktCode"
                  value={formData.taktCode}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.taktCode}</span>
              </div>
              <div className="col-md-4">
                <label className="form-label">P6 Code</label>
                <input
                  type="text"
                  className="form-control"
                  name="p6code"
                  value={formData.p6code}
                  onChange={onChangeHandler}
                />
                <span className="text-danger">{formError.p6code}</span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <button
                type="button"
                className="btn btn-secondary text-white"
                onClick={onCancelHandler}
                disabled={isSubmitting}
                style={{ marginRight: 10 }}
              >
                <FontAwesomeIcon icon={faXmark} style={{ marginRight: 5 }} />
                <span>Cancel</span>
              </button>
              <button
                type="submit"
                className="btn custom-btn"
                hidden={isSubmitting}
              >
                <FontAwesomeIcon icon={faSave} style={{ marginRight: 5 }} />
                <span>Submit</span>
              </button>
              <button
                type="button"
                className="btn custom-btn submitting-btn"
                hidden={!isSubmitting}
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span>Submitting</span>
              </button>
            </div>
          </form>
        </div>
      </div>
      <div
        className={`modal fade ${showSuccessAlert ? "show" : ""} `}
        tabIndex={-1}
        id="alertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showSuccessAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={successImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center text-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {alertSuccessMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleSuccessAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showFailAlert ? "show" : ""} `}
        tabIndex={-1}
        id="alertModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showFailAlert ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img
                  src={FailImg}
                  alt="logo"
                  className=""
                  style={{ width: 150, height: 150 }}
                />
              </div>
              <div className="d-flex justify-content-center text-center mt-2">
                <p
                  style={{ fontSize: 22, fontWeight: 700, textAlign: "center" }}
                >
                  {alertFailMessage}
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={handleFailAlert}
                  style={{ width: 183.5 }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
