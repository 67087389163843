import { faFloppyDisk, faPenToSquare, faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useAuth } from '../../../providers/AuthContext';
import successImg from '../../../assets/success.gif';
import FailImg from '../../../assets/Delete.gif';
import { postData } from "../../../services/API";
import { saveProjectLevel } from "../../../services/Endpoints";


interface ProjectLevel {
    projectLevelId: number;
    rootId: number;
    levelName: string;
    levelCode: string;
    projectLevels: ProjectLevel[] | null;
    isDisable: boolean,
    saved: boolean;
}

interface Props {
    levels: ProjectLevel[];
    rowStyle?: React.CSSProperties;
    // onUpdateLevel: (level: ProjectLevel, index: number) => void;
    onAddSublevel: (projectLevelId: number) => void;
    onRemoveLevel: (projectLevelId: number) => void;
    fetchLevelByPrjId: (projectId: number) => void;
    onFetchLevel: (projectlevelId: number) => void;
}

// interface DynamicFieldUpdate {
//     levelName: string;
//     levelCode: string;
// }

const ProjectLevelTree: React.FC<Props> = ({ levels, rowStyle, onAddSublevel, onRemoveLevel, fetchLevelByPrjId, onFetchLevel }) => {
    // Declarations //
    // const reRoute = useNavigate();
    const { userData } = useAuth();

    const [projectLevels, setProjectLevels] = useState<ProjectLevel[]>(levels);
    const [selectedPrjId, setSelectedPrjId] = useState<number>(0);


    // const [isSubmitting, setIsSubmitting] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showFailAlert, setShowFailAlert] = useState(false);
    const [alertSuccessMessage, setAlertSuccessMessage] = useState("");
    const [alertFailMessage, setAlertFailMessage] = useState("");

    // const stRw = { marginLeft: 10 };

    useEffect(() => {
        setProjectLevels(levels);

    }, [levels]);

    const handleChange = (prjLvlId: number, field: 'levelName' | 'levelCode', value: string, lvls: ProjectLevel[] = projectLevels): ProjectLevel[] => {
        // console.log(lvls);
        return lvls.map(dt => {
            if (dt.projectLevelId === prjLvlId) {
                return { ...dt, [field]: value };
            } else if (dt.projectLevels) {
                return { ...dt, projectLevels: handleChange(prjLvlId, field, value, dt.projectLevels) };
            }
            return dt;
        });

        // const updatedLevels = [...levels];
        // const updatedLevel = { ...updatedLevels[index] }; // Create a copy of the level object
        // updatedLevel[field] = value; // Update the specified field
        // updatedLevel.saved = false; // Mark the level as unsaved
        // updatedLevels[index] = updatedLevel; // Update the level in the array
        // onUpdateLevel(updatedLevel, index); // Call the onUpdateLevel function with the updated level
    };


    const handleSave = async (index: number, item: any,) => {
        if (item.levelName !== "" || item.levelCode !== "") {
            try {
                const formData = {
                    projectLevelId: item.projectLevelId,
                    projectId: item.projectId,
                    rootId: item.rootId,
                    levelName: item.levelName,
                    levelCode: item.levelCode,
                    userId: userData.userId
                };

                // API call to save level data
                const responseData = await postData(saveProjectLevel, formData);
                if (responseData.success) {
                    setSelectedPrjId(item.projectId);
                    //to show the alert
                    setShowSuccessAlert(true);
                    setAlertSuccessMessage(responseData.message);
                    // setIsSubmitting(false);
                } else {
                    setShowFailAlert(true);
                    setAlertFailMessage(responseData.message);
                    // setIsSubmitting(false);
                }

                // If successful, mark the level as saved
                // const updatedLevels = [...levels];
                // updatedLevels[index] = {
                //     ...updatedLevels[index],
                //     saved: true,
                // };
                // onUpdateLevel(updatedLevels[index], index);
            } catch (error) {
                console.error("Error saving level:", error);
            }
        }else{
            setShowFailAlert(true);
            setAlertFailMessage("please enter level name and level code.")
        }
    };

    const handleSuccessAlert = () => {
        setShowSuccessAlert(false);
        fetchLevelByPrjId(selectedPrjId);
        // reRoute('/manageProjects');
    }

    const handleFailAlert = () => {
        setShowFailAlert(false);
    }

    return (
        <>
            {
                projectLevels.map((item, index) => (
                    <div key={index} className="row mt-3" style={rowStyle}>
                        <div className="col-md-4">
                            <input
                                type="text"
                                className="form-control"
                                name={`levelName${String(item.projectLevelId)}`}
                                value={item.levelName}
                                placeholder="Level Name"
                                // onChange={(e) => handleChange(index, "levelName", e.target.value, item)}
                                onChange={e => setProjectLevels(handleChange(item.projectLevelId, 'levelName', e.target.value))}
                                disabled={item.isDisable}
                            />
                        </div>
                        <div className="col-md-4">
                            <input
                                type="text"
                                className="form-control"
                                name={`levelCode${String(item.projectLevelId)}`}
                                value={item.levelCode}
                                placeholder="Level Code"
                                // onChange={(e) => handleChange(index, "levelCode", e.target.value, item)}
                                onChange={e => setProjectLevels(handleChange(item.projectLevelId, 'levelCode', e.target.value))}
                                disabled={item.isDisable}
                            />
                        </div>
                        <div className="col">
                            {item.projectLevelId === 0 && (
                                <button type="button" name={`save${String(item.projectLevelId)}`} className="btn btn-success" style={{ marginRight: 5 }} onClick={() => handleSave(index, item)}>
                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                </button>
                            )}
                            {item.projectLevelId > 0 && item.isDisable === false && (
                                <button type="button" name={`save${String(item.projectLevelId)}`} className="btn btn-success" style={{ marginRight: 5 }} onClick={() => handleSave(index, item)}>
                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                </button>
                            )}
                            {item.projectLevelId > 0 && item.isDisable === true && (
                                <button type="button" name={`add${String(item.projectLevelId)}`} className="btn custom-btn" style={{ marginRight: 5 }} onClick={() => onAddSublevel(item.projectLevelId)}>
                                    <FontAwesomeIcon icon={faPlus} />
                                </button>
                            )}
                            {item.projectLevelId > 0 && item.isDisable === true && (
                                <button
                                    type="button"
                                    name={`edit-${String(item.rootId)}-${String(
                                        item.projectLevelId
                                    )}`}
                                    className="btn btn-success"
                                    style={{ marginRight: 5 }}
                                    onClick={() => onFetchLevel(item.projectLevelId)}
                                >
                                    {/* <FontAwesomeIcon icon={faPencil} /> */}
                                    <FontAwesomeIcon icon={faPenToSquare} />
                                </button>
                            )}
                            <button type="button" name={`remove${String(item.projectLevelId)}`} className="btn btn-danger" style={{ marginRight: 5 }} onClick={() => onRemoveLevel(item.projectLevelId)}>
                                {/* <FontAwesomeIcon icon={faXmark} /> */}
                                <FontAwesomeIcon icon={faTrashCan} />
                            </button>
                        </div>
                        {item.projectLevels && (
                            <ProjectLevelTree
                                levels={item.projectLevels}
                                rowStyle={{ marginLeft: 10 }}
                                // onUpdateLevel={(updatedLevel, updatedIndex) => onUpdateLevel(updatedLevel, index)}
                                onAddSublevel={onAddSublevel}
                                onRemoveLevel={onRemoveLevel}
                                fetchLevelByPrjId={fetchLevelByPrjId}
                                onFetchLevel={onFetchLevel}
                            />
                        )}
                    </div>
                ))
            }

            <div className={`modal fade ${showSuccessAlert ? 'show' : ''} `} tabIndex={-1} id="alertModal" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: showSuccessAlert ? 'block' : 'none' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="d-flex justify-content-center">
                                <img src={successImg} alt="logo" className="" style={{ width: 150, height: 150 }} />
                            </div>
                            <div className="d-flex justify-content-center text-center mt-2">
                                <p style={{ fontSize: 22, fontWeight: 700 }}>{alertSuccessMessage}</p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button type="button" className="btn custom-btn" onClick={handleSuccessAlert} style={{ width: 183.5 }}>Okay</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade ${showFailAlert ? 'show' : ''} `} tabIndex={-1} id="alertModal" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: showFailAlert ? 'block' : 'none' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="d-flex justify-content-center">
                                <img src={FailImg} alt="logo" className="" style={{ width: 150, height: 150 }} />
                            </div>
                            <div className="d-flex justify-content-center text-center mt-2">
                                <p style={{ fontSize: 22, fontWeight: 700 }}>{alertFailMessage}</p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button type="button" className="btn custom-btn" onClick={handleFailAlert} style={{ width: 183.5 }}>Okay</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default ProjectLevelTree;
// function fetchProjectDrpDwn() {
//     throw new Error("Function not implemented.");
// }

