// AuthContext.tsx
import React, {
  ReactNode,
  createContext,
  useContext,
  useState,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";

interface AuthContextType {
  isAuthenticated: boolean;
  login: (resp: any) => void;
  logout: () => void;
  menuData: any;
  tokenData: any;
  companyData: any;
  userType: any;
  userData: any;
  userOtherData: any;
  projectData: any;
  OTPData: any;
  setOTPData: any;
  currentMenuURL: any;
  activeSubMenu: any;
  setCurrentMenuURL: any;
  setActiveSubMenu: any;
  handleSubMenuToggle: (resp: any) => void;
  handleSubMenuSelection: (resp: any) => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
    return localStorage.getItem("isAuthenticated") === "true";
  });
  const [menuData, setMenuData] = useState<any>(
    JSON.parse(localStorage.getItem("menuData") || "[]")
  );
  const [tokenData, setTokenData] = useState<any>(
    JSON.parse(localStorage.getItem("tokenData") || "{}")
  );
  const [companyData, setCompanyData] = useState<any>(
    JSON.parse(localStorage.getItem("companyData") || "{}")
  );
  const [userType, setUserType] = useState<any>(
    JSON.parse(localStorage.getItem("userType") || "{}")
  );
  const [userData, setUserData] = useState<any>(
    JSON.parse(localStorage.getItem("userData") || "{}")
  );
  const [userOtherData] = useState<any>(
    JSON.parse(localStorage.getItem("userOtherData") || "[]")
  );
  const [projectData, setProjectData] = useState<any>(
    JSON.parse(localStorage.getItem("projectData") || "{}")
  );
  const [OTPData, setOTPData] = useState<any>(
    JSON.parse(localStorage.getItem("OTPData") || "{}")
  );
  const [currentMenuURL, setCurrentMenuURL] = useState<any>(
    localStorage.getItem("currentMenuURL")
  );
  const [activeSubMenu, setActiveSubMenu] = useState<number | null>(
    JSON.parse(localStorage.getItem("activeSubMenu") || "null")
  );

  useEffect(() => {
    localStorage.setItem("isAuthenticated", isAuthenticated.toString());
    localStorage.setItem("menuData", JSON.stringify(menuData));
    localStorage.setItem("tokenData", JSON.stringify(tokenData));
    localStorage.setItem("companyData", JSON.stringify(companyData));
    localStorage.setItem("userType", JSON.stringify(userType));
    localStorage.setItem("userData", JSON.stringify(userData));
    localStorage.setItem("userOtherData", JSON.stringify(userOtherData));
    localStorage.setItem("projectData", JSON.stringify(projectData));
    localStorage.setItem("OTPData", JSON.stringify(OTPData));
    localStorage.setItem("currentMenuURL", currentMenuURL);
    localStorage.setItem("activeSubMenu", JSON.stringify(activeSubMenu));
  }, [
    isAuthenticated,
    menuData,
    tokenData,
    companyData,
    userType,
    userData,
    userOtherData,
    projectData,
    OTPData,
    currentMenuURL,
    activeSubMenu,
  ]);

  const login = (resp: any) => {
    setMenuData(resp.data.menuList);
    setTokenData(resp.data.token);
    setCompanyData(resp.data.company);
    setUserType(resp.data.userType);
    setUserData({
      userId: resp.data.userId,
      fName: resp.data.fName,
      lName: resp.data.lName,
      name: resp.data.name,
      dob: resp.data.dob,
      age: resp.data.age,
      gender: resp.data.gender,
      phoneCode: resp.data.phoneCode,
      mobile: resp.data.mobile,
      email: resp.data.email,
      address: resp.data.address,
      latitude: resp.data.latitude,
      longitude: resp.data.longitude,
      zipcode: resp.data.zipcode,
      image: resp.data.image,
      activeFlag: resp.data.activeFlag,
    });
    setIsAuthenticated(true);
    setProjectData(resp.data.project);
    navigate("/adminDashboard");
  };

  const logout = () => {
    setIsAuthenticated(false);
    setActiveSubMenu(null);
    setCurrentMenuURL(null);
    localStorage.clear();
    navigate("/");
  };

  const handleSubMenuToggle = (obj: any) => {
    if (activeSubMenu === obj.menuId) {
      setActiveSubMenu(null);
    } else {
      setActiveSubMenu(obj.menuId);
    }
    setCurrentMenuURL(obj.menuUrl);
  };

  const handleSubMenuSelection = (obj: any) => {
    setCurrentMenuURL(obj.menuUrl);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        login,
        logout,
        menuData,
        tokenData,
        companyData,
        userType,
        userData,
        userOtherData,
        projectData,
        setOTPData,
        OTPData,
        currentMenuURL,
        activeSubMenu,
        setCurrentMenuURL,
        setActiveSubMenu,
        handleSubMenuToggle,
        handleSubMenuSelection,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
