// List of All Endpoints  //

// Authentication //
export const userLogin = "User/UserLogin?";
export const OTPForForgotPassword = "User/GenerateOTPForForgotPassword?";
export const VerifyOTPForForgotPassword = "User/VerifyOTPForForgotPassword?";
export const Resetpassword = "User/ResetPassword?";



// Manage Designations //
export const getAllDesignations = "Designation/GetDesignationList?";
export const saveDesignations = "Designation/SaveDesignationDetails";
export const getDesignationDataById = "Designation/GetDesignationDetailById?DesignationId=";
export const deleteDesignation = "Designation/DeleteDesignationDetailById?";
export const activateDeactivateDesignation = "Designation/ActivateDeactivateDesignationDetailById?";
export const designationBulkUpload = "Designation/bulkupload";

// Manage Machinery //
export const getAllMachineries = "Machinery/GetMachineryList?";
export const saveMachineries = "Machinery/SaveMachineryDetails";
export const machineryBulkUpload = "Machinery/bulkupload";
export const getMachineryDataById = "Machinery/GetMachineryDetailById?MachineryId=";
export const deleteMachinery = "Machinery/DeleteMachineryDetailById?";
export const activateDeactivateMachinery = "Machinery/ActivateDeactivateMachineryDetailById?";

// Manage UOM //
export const getAllUom = "UOM/GetUOMList?";
export const saveUom = "UOM/SaveUOMDetails";
export const getUomDataById = "UOM/GetUOMDetailById?UOMId=";
export const deleteUom = "UOM/DeleteUOMDetailById?";
export const activateDeactivateUOM = "UOM/ActivateDeactivateUOMDetailById?";
export const uomBulkUpload = "UOM/bulkupload";

// Manage Materials //
export const getAllMaterials = "Material/GetMaterialList?";
export const getAllUomDrpDwn = "Material/GetUOMDropdownList?";
export const saveMaterial = "Material/SaveMaterialDetails";
export const materialBulkUpload = "Material/bulkupload";
export const getMaterialDataById = "Material/GetMaterialDetailById?MaterialId=";
export const delMaterial = "Material/DeleteMaterialDetailById?";
export const adMaterial = "Material/ActivateDeactivateMaterialDetailById?";

// Manage Injury //
export const getAllInjury = "Injury/GetInjuryList?";
export const saveInjury = "Injury/SaveInjuryDetails";
export const getInjuryDataById = "Injury/GetInjuryDetailById?InjuryId=";
export const deleteInjuryData = "Injury/DeleteInjuryDetailById?";
export const actDeactInjury = "Injury/ActivateDeactivateInjuryDetailById?";

// Manage Shift //
export const getAllShift = "Shift/GetShiftList?";
export const saveShift = "Shift/SaveShiftDetails";
export const getShiftDataById = "Shift/GetShiftDetailById?ShiftId=";
export const deleteShiftData = "Shift/DeleteShiftDetailById?";
export const actDeactShift = "Shift/ActivateDeactivateShiftDetailById?";

// Manage Company //
export const getAllCompany = "Company/GetCompanyList?";
export const saveCompany = "Company/SaveCompanyDetails";
export const getCompanyDataById = "Company/GetCompanyDetailById?CompanyId=";
export const deleteCompanyData = "Company/DeleteCompanyDetailById?";
export const actDeactCompany = "Company/ActivateDeactivateCompanyDetailById?";
export const deleteCompanyLogo="Company/DeleteCompanyLogo?"

// Manage Company //
export const getAllLabourType = "LabourType/GetLabourTypeList?";
export const saveLabourType = "LabourType/SaveLabourTypeDetails";
export const getLabourTypeDataById = "LabourType/GetLabourTypeDetailById?LabourTypeId=";
export const deleteLabourTypeData = "LabourType/DeleteLabourTypeDetailById?";
export const actDeactLabourType = "LabourType/ActivateDeactivateLabourTypeDetailById?";


// Manage Sub Contractor //
export const getAllSubContractor = "SubContractor/GetSubContractorList?";
export const getLabourTypeDrpDwn = "SubContractor/GetLabourTypeDropdownList?";
export const saveSubContractor = "SubContractor/SaveSubContractorDetails";
export const subContractorBulkUpload = "SubContractor/bulkupload";
export const getSubContractorDataById = "SubContractor/GetSubContractorDetailById?SubContractorId=";
export const deleteSubContractorData = "SubContractor/DeleteSubContractorDetailById?";
export const actDeactSubContractor = "SubContractor/ActivateDeactivateSubContractorDetailById?";

// Manage Staff //
export const getAllStaff = "Staff/GetStaffList?";
export const getDesignationDrpDwn = "Staff/GetDesignationDropdownList?";
export const saveStaff = "Staff/SaveStaffDetails";
export const staffBulkUpload = "Staff/bulkupload";
export const getStaffDataById = "Staff/GetStaffDetailById?StaffId=";
export const deleteStaffData = "Staff/DeleteStaffDetailById?";
export const actDeactStaff = "Staff/ActivateDeactivateStaffDetailById?";
export const deleteStaffProfilePic="staff/DeleteStaffProfilePic?"

// Manage Project //
export const getAllProject = "Project/GetProjectList?";
export const getManagerDrpDwn = "Project/GetProjectManagerDropdownList?";
export const getEngineerDrpDwn = "Project/GetProjectEngineerDropdownList?";
export const getStaffDrpDwn = "Project/GetStaffDropdownList?";
export const saveProject = "Project/SaveProjectDetails";
export const ProjectBulkUpload = "Project/bulkupload";
export const getProjectDataById = "Project/GetProjectDetailById?ProjectId=";
export const deleteProjectData = "Project/DeleteProjectDetailById?";
export const actDeactProject = "Project/ActivateDeactivateProjectDetailById?";
export const projectBulkUpload = "Project/bulkupload";

// Manage Manpower //
export const getAllManpower = "Manpower/GetManpowerList?";
export const getEngineerInchargeDrpDwn = "Manpower/GetEngineerInchargeDropdownList?";
export const getForemanInchargeDrpDwn = "Manpower/GetFormanInchargeDropdownList?";
export const getChargehandInchargeDrpDwn = "Manpower/GetChargehandInchargeDropdownList?";
export const getGangLeaderInchargeDrpDwn = "Manpower/GetGangLeaderInchargeDropdownList?";
export const getLabourSupplyDrpDwn = "Manpower/GetLabourSupplyDropdownList?";
export const getProjectDrpDwn = "Manpower/GetProjectDropdownList?";
export const getDesignationDropdownList = "Manpower/GetDesignationDropdownList?";
export const saveManpower = "Manpower/SaveManpowerDetails";
export const ManpowerBulkUpload = "Manpower/bulkupload";
export const getManpowerDataById = "Manpower/GetManpowerDetailById?ManpowerId=";
export const deleteManpowerData = "Manpower/DeleteManpowerDetailById?";
export const actDeactManpower = "Manpower/ActivateDeactivateManpowerDetailById?";

// Manage Weather //
export const getAllWeather = "Weather/GetWeatherList?";
export const saveWeather = "Weather/SaveWeatherDetails";
export const getWeatherDataById = "Weather/GetWeatherDetailById?WeatherId=";
export const deleteWeatherData = "Weather/DeleteWeatherDetailById?";
export const actDeactWeather = "Weather/ActivateDeactivateWeatherDetailById?";


// Manage Project Level //
export const getProjectDrpDown = "ProjectLevel/GetProjectDropdownList?";
export const getAllProjectLevel = "ProjectLevel/GetProjectLevelList?";
export const getEditProjectLevel = "ProjectLevel/GetProjectLevelDetailById?";
export const appendPrjLvlDetById = "ProjectLevel/AppendProjectLevelDetailById?";
export const saveProjectLevel = "ProjectLevel/SaveProjectLevelDetails";
export const deleteProjectLevelData = "ProjectLevel/DeleteProjectLevelDetailById?";

//Manage Activity //
export const getAllActivity = "Activity/GetActivityList?";
export const getEditActivity = "Activity/GetActivityDetailById?ActivityId=";
export const saveActivity = "Activity/SaveActivityDetails";
export const deleteActivityData = "Activity/DeleteActivityDetailById?";
export const actDeactActivity = "Activity/ActivateDeactivateActivityDetailById?";
export const activityBulkUpload = "Activity/bulkupload";

//Manage SubTask //
export const getAllSubTask = "SubTask/GetSubTaskList?";
export const getActivityDrpDwn = "SubTask/GetActivityDropdownList?";
export const getTaskDrpDwnForSubTask = "SubTask/GetTaskDropdownList?";
export const getSubTaskUomDrpDwn = "SubTask/GetUOMDropdownList?";
export const getEditSubTask = "SubTask/GetSubTaskDetailById?SubTaskId=";
export const saveSubTask = "SubTask/SaveSubTaskDetails";
export const deleteSubTaskData = "SubTask/DeleteSubTaskDetailById?";
export const actDeactSubTask = "SubTask/ActivateDeactivateSubTaskDetailById?";
export const subTaskBulkUpload = "SubTask/bulkupload";
export const getActivityTypeDrpDwn = "SubTask/GetActivityTypeDropdownList?";

//Manage Task //
export const getAllTask = "Task/GetTaskList?";
export const getTaskActivityDrpDwn = "Task/GetActivityDropdownList?";
export const getSubTaskDrpDwn = "Task/GetSubTaskDropdownList?";
export const getTaskUomDrpDwn = "Task/GetUOMDropdownList?";
export const getEditTask = "Task/GetTaskDetailById?TaskId=";
export const saveTask = "Task/SaveTaskDetails";
export const deleteTaskData = "Task/DeleteTaskDetailById?";
export const actDeactTask = "Task/ActivateDeactivateTaskDetailById?";
export const taskBulkUpload = "Task/bulkupload";

//Manage Task Level Mapping //
export const getAllTaskLevelList = "TaskLevelMapping/GetTaskLevelMappingList?";
export const getTaskDrpDwn = "TaskLevelMapping/GetTaskDropdownList?";
export const getProjectLevelData = "TaskLevelMapping/GetProjectLevelList?";
export const saveTaskLevelMapping = "TaskLevelMapping/SaveTaskLevelMappingDetails";
export const getEditTaskLevelMapping = "TaskLevelMapping/GetTaskLevelMappingDetailById?TaskLevelId=";
export const deleteTaskLevelMapping = "TaskLevelMapping/DeleteTaskLevelMappingDetailById?";
export const actDeactTaskLevelMapping = "TaskLevelMapping/ActivateDeactivateTaskLevelMappingDetailById?";
export const tasklevelMappingBulkUpload = "TaskLevelMapping/bulkupload";

//Company Admin Dashboard //
export const getDashboardDynamicCardList = "Dashboard/GetDashboardDynamicCardList?";

//Reports //
export  const getProjectDropdownList = "Report/GetProjectDropdownList?";
export const getDailyActivityReport = "Report/GetDailyActivityReport?";

// Manage Activity Type //
export const getAllActivityType = "ActivityType/GetActivityTypeList?";
export const saveActivityType = "ActivityType/SaveActivityTypeDetails";
export const getActivityTypeDataById = "ActivityType/GetActivityTypeDetailById?ActivityTypeId=";
export const deleteActivityTypeData = "ActivityType/DeleteActivityTypeDetailById?";
export const actDeactActivityType = "ActivityType/ActivateDeactivateActivityTypeDetailById?";